<template>
  <div class="orderNaviation">
    <div class="eachNav">
      <ul class="Navul">
        <!-- <li @click="changeactiveNav(item.value)" v-for="(item, index) in NavList" :key="index">
              {{item.name}}
          </li> -->
        <li v-for="(item, index) in NavList" :key="index" @click="changeactiveNav(item.value)">
          <el-popover
            placement="left"
            title=""
            trigger="hover"
            class="lipover"
            width="40px"
          >
            <a class="hrefNav" :href="`#${item.id}`">
              {{ item.name }}
            </a>
            <a v-if="item.value !== activeNav" slot="reference" :href="`#${item.id}`">
              <i :class="item.icon" style="font-size:14px;color:#606266" />
            </a>
            <a v-if="item.value === activeNav" slot="reference" :href="`#${item.id}`">
              <i :class="item.icon" style="font-size:14px;color:#fff;background-color:#409EFF" />
            </a>
          </el-popover>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  name: 'OrderNaviation',
  data() {
    return {
      activeNav: 1,
      NavList: [
        {
          value: 1,
          name: '订单信息',
          id: 'orderHeader',
          icon: 'el-icon-document'
        },
        {
          value: 2,
          name: '订单详情',
          id: 'orderMaterialList',
          icon: 'el-icon-search'
        },
        {
          value: 3,
          name: '销售凭证',
          id: 'orderSaleList',
          icon: 'el-icon-wallet'
        },
        {
          value: 4,
          name: '交货凭证',
          id: 'orderDeliveryList',
          icon: 'el-icon-box'
        }
      ]
    }
  }, // data ends
  methods: {
    // 切换定位
    changeactiveNav(val) {
      this.activeNav = val
      // 处理页面定位
      this.SrocllToNav()
    },
    // 获取定位坐标
    getNavCoordinate() {
      let y = 0
      switch (this.activeNav) {
        case 1:
          y = document.getElementById('orderHeader').offsetTop
          break
        case 2:
          y = document.getElementById('orderMaterialList').offsetTop
          break
        case 3:
          y = document.getElementById('orderSaleList').offsetTop
          break
        case 4:
          y = document.getElementById('orderDeliveryList').offsetTop
          break
        default: return false
      }
      return y
    },
    // 跳转定位
    SrocllToNav() {
      window.scrollTo(100, this.getNavCoordinate())
    }
  }
}
</script>
<style>
.el-popover {
  min-width: 40px;
}
.eachNav {
  position: absolute;
  right: 0px;
  top:37%;
}
.hrefNav {
  text-align: center;
  color: #409EFF;
  font-size: 10px;
  text-decoration:none;
}
.Navul {
  list-style: none;
  background-color:#fff;
  padding: 0; margin: 0;
  box-shadow: 0px 3px 8px rgba(0,0,0,.1);
  border-radius: 4px 0 0 4px;
  overflow: hidden;
}
.Navul li a{
  text-align: center;
  color: #409EFF;
  font-size:12px;
  font-weight: bold;
  text-decoration:none;
  background-color: #fff;
  display: inline-block;
}
.Navul li a i{ border: 0px;padding: 10px 10px;}
</style>
