<template>
  <div class="orderDetailsHeader">
    <!-- <PageHeader :border="false" title="订单信息" >
            <el-button @click="$router.push('orderList')" type="primary" size="mini" round>返回</el-button>
        </PageHeader>  v-loading="orderheaderLoading"-->
    <div v-loading="orderheaderLoading" class="p-lr-20 p-tb-20">
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span class="cardTitle">订单信息</span>
        </div>
        <div class="orderHeader flex j-between b_line m-bottom-10">
          <div class="hederleft flex j-between weight" style="flex:1">
            <div>订单编号：<span class="p-left-10">{{ orderHeadInfo.OrderID }}</span></div>
            <div>订单创建时间：<span class="p-left-10">{{ orderHeadInfo.WriteTime }}</span></div>
            <div>订单来源：<span class="p-left-10">{{ orderHeadInfo.OrderSourceString }}</span></div>
            <div>状态：<span class="p-left-10">{{ orderHeadInfo.OrderStatusString }}</span></div>
          </div>
          <div class="headerright p-lr-10">
            <el-button class="ModelBttton1-white-24" plain @click="openprintorder">打印订单</el-button>
          </div>
        </div>
        <div class="steps">
          <el-steps style="width:80%;maigin:0 auto" :active="orderStep" process-status="finish" align-center>
            <el-step title="提交订单" icon="iconfont icon-wancheng">
              <template slot="description">
                <el-popover
                  v-model="orderStep1"
                  placement="top"
                  width="300"
                  trigger="manual"
                >
                  <el-table v-loading="submitLogListLoading" :data="submitLogList" :border="false">
                    <el-table-column property="Operater" label="操作人" />
                    <el-table-column property="OperateDate" label="操作时间" />
                    <el-table-column property="Remark" label="操作内容" />
                  </el-table>
                  <el-button slot="reference" style="height:20px;line-height:20px" type="primary" :disabled="orderStep < 0" size="mini" round @click="view_detail(0)">查看详情</el-button>
                </el-popover>
              </template>
            </el-step>
            <el-step title="供应商处理" icon="iconfont icon-chart14">
              <template slot="description">
                <el-popover
                  v-model="orderStep2"
                  placement="top"
                  width="300"
                  trigger="manual"
                >
                  <el-table v-loading="submitLogListLoading" :data="submitLogList" :border="false">
                    <el-table-column property="Operater" label="操作人" />
                    <el-table-column property="OperateDate" label="操作时间" />
                    <el-table-column property="Remark" label="操作内容" />
                  </el-table>
                  <el-button slot="reference" style="height:20px;line-height:20px" :disabled="orderStep < 1" type="primary" size="mini" round @click="view_detail(1)">查看详情</el-button>
                </el-popover>
              </template>
            </el-step>
            <el-step title="货物运输" icon="iconfont icon-chart12">
              <template slot="description">
                <el-popover
                  v-model="orderStep3"
                  placement="top"
                  width="300"
                  trigger="manual"
                >
                  <el-table v-loading="submitLogListLoading" :data="submitLogList" :border="false">
                    <el-table-column property="ExpressName" label="物流名称" />
                    <el-table-column property="ExpressCode" label="物料单号" />
                    <el-table-column property="OperateDate" label="发货时间" />
                  </el-table>
                  <el-button slot="reference" style="height:20px;line-height:20px" type="primary" size="mini" :disabled="orderStep < 2" round @click="view_detail(2)">查看详情</el-button>
                </el-popover>
              </template>
            </el-step>
            <el-step title="预入库单处理" icon="iconfont icon-chart34">
              <template slot="description">
                <el-popover
                  v-model="orderStep4"
                  placement="top"
                  width="300"
                  trigger="manual"
                >
                  <el-table v-loading="submitLogListLoading" :data="submitLogList" :border="false">
                    <el-table-column property="Operater" label="操作人" />
                    <el-table-column property="OperateDate" label="操作时间" />
                    <el-table-column property="Remark" label="操作内容" />
                  </el-table>
                  <el-button slot="reference" style="height:20px;line-height:20px" type="primary" size="mini" :disabled="orderStep < 3" round @click="view_detail(3)">查看详情</el-button>
                </el-popover>
              </template>
            </el-step>
            <el-step title="确认收货" icon="el-icon-wind-power">
              <template slot="description">
                <el-popover
                  v-model="orderStep5"
                  placement="top"
                  width="300"
                  trigger="manual"
                >
                  <el-table v-loading="submitLogListLoading" :data="submitLogList" :border="false">
                    <el-table-column property="Operater" label="操作人" />
                    <el-table-column property="OperateDate" label="操作时间" />
                    <el-table-column property="Remark" label="操作内容" />
                  </el-table>
                  <el-button slot="reference" style="height:20px;line-height:20px" type="primary" size="mini" :disabled="orderStep < 4" round @click="view_detail(4)">查看详情</el-button>
                </el-popover>
              </template>
            </el-step>
          </el-steps>
        </div>
        <!-- 买家信息 -->
        <div class="header_div flex j-between m-top-20" style="height:120px">
          <div class="left flex a-center j-center weight f12">
            买 家 信 息
          </div>
          <div class="mid">
            <div class="item min-border-bottom flex j-between a-center p-lr-10   f12" style="flex:1">
              <div class="eachitem-eachword">创建人：<span class="p-left-10">{{ orderHeadInfo.CreateUserName }}</span></div>
              <div class="eachitem-eachword">办公电话：<span class="p-left-10">{{ orderHeadInfo.OfficeTel }}</span></div>
              <div class="eachitem-eachword">移动电话：<span class="p-left-10">{{ orderHeadInfo.Mobile }}</span></div>
              <div class="eachitem-eachword">部门科室：<span class="p-left-10">{{ orderHeadInfo.SimsSectionName }}</span>
              </div>
            </div>
            <div class="item min-border-bottom flex j-between a-center p-lr-10   f12" style="flex:1">
              <div class="eachitem-eachword">终端:<span class="p-left-10">{{ orderHeadInfo.CompanyName }}</span></div>
              <div class="eachitem-eachword">买方：<span class="p-left-10">{{ orderHeadInfo.AgentCompanyName }}</span></div>
              <div class="eachitem-eachword">期望到货时间：<span class="p-left-10">{{ orderHeadInfo.ArrivalDate }}</span></div>
              <div class="eachitem-eachword">发票信息：<span class="p-left-10">
                {{ orderHeadInfo.InvoiceType === 2 ? '月结' : orderHeadInfo.InvoiceType === 1 ? '随货' : '未设置' }}
              </span></div>
            </div>
            <div class="item min-border-bottom flex j-between a-center p-lr-10   f12" style="flex:1">
              <div class="eachitem-eachword2 flex"><span style="width:80px">收货地址:</span><span class="p-left-10 el-1">
                {{ orderHeadInfo.SendAddress }}
              </span>
              </div>
              <div class="eachitem-eachword2 p-lr-10">送货联系人:<span class="p-left-10">
                {{ orderHeadInfo.ContactName }}
              </span>
              </div>
              <div class="eachitem-eachword2 flex"><span style="width:80px">寄票地址:</span><span class="p-left-10 el-1">
                {{ orderHeadInfo.CollectTicketSendAddress }}
              </span>
              </div>
            </div>
            <div class="item min-border-bottom flex j-between a-center p-lr-10   f12" style="flex:1">
              <div class="eachitem-eachword2">订单备注:<span class="p-left-10">{{ orderHeadInfo.Remark }} <el-link class="m-left-10" type="primary" :underline="false" @click="setRemake">编辑</el-link></span></div>
            </div>
          </div>
          <div class="right" />
        </div>

      </el-card>
    </div>
    <!-- 修改订单备注 -->
    <el-dialog class="DepartmentBox" title="修改订单备注" :visible.sync="updateOrderRemarkVisibel" width="40%" :close-on-click-modal="false">
      <el-form label-position="right" label-width="20%" :model="orderHeadInfo" size="mini" class="demo-form-inline">
        <el-input
          v-model="orderHeadInfoRemark"
          type="textarea"
          :rows="4"
          placeholder="请输入订单备注"
        />
      </el-form>
      <div class="caozuo p-tb-20">
        <el-button @click="updateOrderRemarkVisibel = false">取 消</el-button>
        <el-button type="primary" @click="comfirmUpateOrderRemar">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 打印订单 -->
    <el-dialog class="DepartmentBox" title="打印订单预览" center :visible.sync="printorderVisible" width="60%" :close-on-click-modal="false">
      <div id="printorderDom" class="pritInfo p-lr-10 p-bottom-20">
        <div class="f14 right" style="text-align: right;">文件编号：{{ pritInfo.fileNo }}</div>
        <div class="f14 flex left b_line_s p-bottom-10">订单编号：{{ pritInfo.OrderID }}</div>
        <div class="header weight f16 flex j-center m-top-10">{{ pritInfo.companyName }}</div>
        <div class="mid_info f12 flex j-between m-top-10 b_line p-bottom-10">
          <div style="min-width:300px">创建人：{{ pritInfo.CreateUserName }}</div>
        </div>
        <div class="mid_info f12 flex j-between m-top-10 b_line p-bottom-10">
          <div style="min-width:300px">办公电话：{{ pritInfo.OfficeTel }}</div>
          <div style="min-width:300px">移动电话：{{ pritInfo.Mobile }}</div>
        </div>
        <div class="mid_info f12 flex j-between m-top-10 b_line p-bottom-10">
          <div style="min-width:300px">部门科室：{{ pritInfo.SimsSectionName }}</div>
        </div>
        <div class="mid_info f12 flex j-between m-top-10 b_line p-bottom-10">
          <div style="min-width:300px">终端：{{ pritInfo.CompanyName }}</div>
          <div style="min-width:300px">买方：{{ pritInfo.AgentCompanyName }}</div>
        </div>
        <div class="mid_info f12 flex j-between m-top-10 b_line p-bottom-10">
          <div style="min-width:300px">期望到货时间：{{ pritInfo.ArrivalDate }}</div>
        </div>
        <div class="mid_info f12 flex j-between m-top-10 b_line p-bottom-10">
          <div style="min-width:300px">发票信息：{{ pritInfo.InvoiceType === 2 ? '月结' : pritInfo.InvoiceType === 1 ? '随货' : '未设置' }}</div>
          <div style="min-width:300px">收货地址：{{ pritInfo.SendAddress }}</div>
        </div>
        <div class="mid_info f12 flex j-between m-top-10 b_line p-bottom-10">
          <div style="min-width:300px">送货联系人:{{ pritInfo.ContactName }}</div>
        </div>
        <div class="mid_info f12 flex j-between m-top-10 b_line p-bottom-10">
          <div style="min-width:300px">寄票地址: {{ pritInfo.CollectTicketSendAddress }}</div>
        </div>
        <div class="empty" />
        <div class="mid_info f12 flex j-between m-top-10 p-bottom-10">
          <div style="min-width:300px">订单备注: {{ pritInfo.Remark }}</div>
        </div>
        <div class="m-tb-10" style="width: 100%">
          <el-table
            :header-cell-style="{background:'rgb(247,248,252)'}"
            :data="pritInfo.tableList"
            :span-method="colspan"
            border
            style="width: 100%"
          >
            <el-table-column prop="hh" label="货号" show-overflow-tooltip />
            <el-table-column prop="cpm" label="产品名" show-overflow-tooltip />
            <el-table-column prop="gg" label="规格" show-overflow-tooltip />
            <el-table-column prop="sl" label="数量" show-overflow-tooltip />
            <el-table-column prop="xydj" label="协议单价" show-overflow-tooltip />
            <el-table-column prop="zhdj" label="折后单价" show-overflow-tooltip />
            <el-table-column prop="yjze" label="原价总额" show-overflow-tooltip />
            <el-table-column prop="yfze" label="应付总额" show-overflow-tooltip />
          </el-table>
        </div>
        <div class="empty" />
        <div class="mid_info f12 flex j-between m-top-10">
          <div style="min-width:300px;color:red">PS：请在签名后将订单回传，并在付款后将付款凭证传真至本公司，我们将在收到凭证后进行发货，谢谢配合</div>
        </div>
        <div class="empty" />
        <div class="empty" />
        <div class="mid_info f12 flex j-between m-top-20">
          <div style="min-width:300px">订货单位负责签字（盖章）：</div>
        </div>
        <div class="empty" />
        <div class="empty" />
        <div class="dealdivider m-tb-20" style="height:1px;border-top:1px solid #dcdfe6" />
        <div class="empty" />
        <div class="mid_info f12 flex j-between m-tb-20">
          <div style="min-width:300px;font-size:15px;b_line_s" class="bigfontsize">以下由上海润达医疗科技股份有限公司填写：</div>
        </div>
        <div class="mid_info f12 flex j-between m-top-10 b_line p-bottom-10">
          <div style="min-width:300px">发货日期：</div>
          <div style="min-width:300px">发票编号：</div>
        </div>
        <div class="mid_info f12 flex j-between m-top-10 b_line p-bottom-10">
          <div style="min-width:300px">运输单位：</div>
          <div style="min-width:300px">运单号：</div>
        </div>
        <div class="mid_info f12 flex j-between m-top-10 b_line p-bottom-10">
          <div style="min-width:300px">联系电话：</div>
        </div>
        <div class="mid_info f12 flex j-between m-top-10 b_line p-bottom-10">
          <div style="min-width:300px">商务负责：</div>
        </div>
        <div class="mid_info f12 flex j-between m-top-10 b_line p-bottom-10">
          <div style="min-width:300px">商务负责：</div>
          <div style="min-width:300px">销售负责审核：</div>
        </div>
        <div class="mid_info f12 flex j-between m-top-10">
          <div style="min-width:500px">附件：
            <el-checkbox-group v-model="checkList" class="nowrap" style="display:inline">
              <el-checkbox label="增票" />
              <el-checkbox label="软件" />
              <el-checkbox label="冰袋" />
            </el-checkbox-group>
          </div>
        </div>
      </div>
      <div slot="footer" class="caozuo">
        <el-button @click="printorderVisible = false">取 消</el-button>
        <el-button type="primary" @click="printorder">确认打印</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
// import $userRequest from '@/request/contactsapi.js'
import printJS from 'print-js'
import $orderReq from '@/request/orderApi.js'
import $commonJs from '@/minxin/commonJs.js'
export default {
  name: 'PurchaseOrderDetails',
  props: {
    // eslint-disable-next-line vue/prop-name-casing
    OrderID: {
      type: String,
      default: ''
    },
    // eslint-disable-next-line vue/prop-name-casing
    Ishandler: {
      type: String,
      default: '-1'
    }
  },
  data() {
    return {
      orderHeadInfoRemark: '',
      orderheaderLoading: false,
      orderStep: 0,
      orderStepTemp: '',
      orderStep1: false,
      orderStep2: false,
      orderStep3: false,
      orderStep4: false,
      orderStep5: false,
      orderHeadInfo: {
        OrderID: 'SDF233432',
        WriteTime: '2021-03-09', // 创建时间
        OrderSourceString: '换个方式', // 订单来源
        OrderStatusString: '已发货', // 订单状态
        CompanyName: '终fsdfsdfs反倒是就啊', // 终端
        AgentCompanyName: '买方', // 买方
        qwdhsj: '2021-03-06', // 期望到货时间
        InvoiceType: '发票信息', // 发票信息
        address: '上海市宝山区杨行镇氰化氢 1232号 423栋 308', // 收货地址
        invoiceAddress: '上海市宝山区杨行镇氰化氢 1232号 423栋 308',
        Remark: '订单备注',
        IsMyFavoriteOrder: 2, // 是否收藏
        SimsSectionName: '', // 部门科室
        Mobile: '',
        OfficeTel: ''
      }, // 头部信息
      orderaddress: {}, // 订单地址
      orderjpaddress: {}, // 订单寄票地址
      // 修改订单备注
      updateOrderRemarkVisibel: false,
      // 提交日志
      submitLogListLoading: false,
      submitLogList: [
        {
          operator: 'fdsf',
          time: '2021-02-03',
          content: 'dfafdsafd'
        }
      ],
      // 交货单日志
      deliveryLogListLoading: false,
      deliveryLogList: [
        {
          operator: 'fdsf',
          time: '2021-02-03',
          content: 'dfafdsafd'
        }
      ],
      // 仓库发货日志
      sendLogListLoading: false,
      sendLogList: [
        {
          operator: 'fdsf',
          time: '2021-02-03',
          content: 'dfafdsafd'
        }
      ],
      // 物流运输
      transportLogListLoading: false,
      transportLogList: [
        {
          transNo: 'fsdafs', // 物流单号
          transTime: '2021-03-06 11:12:00',
          contactUser: 'monica'
        }
      ],
      // 确认收货
      reciveLogListLoading: false,
      reciveLogList: [
        {
          reciveNo: 'fsdafs', // 物流单号
          reciveTime: '2021-03-06 11:12:00',
          contactUser: 'monica'
        }
      ],
      // 打印订单   =========================================begin =============================
      printorderVisible: false,
      pritInfo: {
        fileNo: '销售出库单', // 文件编号
        OrderID: 'fdsaf', // 订单编号
        companyName: '上海润达医疗科技股份有限公司', // 公司名称
        dhdw: '订货单位：', // 订货单位：
        dhr: '订货人：', // 订货人：
        lxdg: '联系电话：', // 联系电话：
        shdx: '收货地址：', // 收货地址：
        shr: '收货人：', // 收货人：
        shdh: '收货电话：', // 收货电话：
        spdx: '收票地址：', // 收票地址：
        spr: '收票电话：', // 收票电话：
        sendcompany: '终端：', // 终端：
        remark: '订单备注：', // 订单备注：
        dhrq: '订货日期：', // 订货日期：
        tableList: [
          {
            hh: 'fsad', // 货号
            cpm: 'fsd', // 产品名
            gg: 'fsd', // 规格
            sl: 10, // 数量
            xydj: 10, // 协议单价
            zhdj: 5, // 折后单价
            yjze: 100, // 原价总额
            yfze: 50 // 应付总额
          },
          {
            hh: 'fsad', // 货号
            cpm: 'fsd', // 产品名
            gg: 'fsd', // 规格
            sl: 10, // 数量
            xydj: 10, // 协议单价
            zhdj: 5, // 折后单价
            yjze: 100, // 原价总额
            yfze: 50 // 应付总额
          },
          {
            hh: '', // 货号
            cpm: '', // 产品名
            gg: '', // 规格
            sl: 12, // 数量
            xydj: 21, // 协议单价
            zhdj: '合计（元）', // 折后单价
            yjze: 200, // 原价总额
            yfze: 100 // 应付总额
          }
        ]
      },
      checkList: []
      // 打印订单   =========================================end =============================
    }
  },
  watch: {
    orderStepTemp: {
      handler(a) {
        if (a === 0) {
          this.orderStep2 = false
          this.orderStep3 = false
          this.orderStep4 = false
          this.orderStep5 = false
          return this.orderStep1 = !this.orderStep1
        }
        if (a === 1) {
          this.orderStep1 = false
          this.orderStep3 = false
          this.orderStep4 = false
          this.orderStep5 = false
          return this.orderStep2 = !this.orderStep2
        }
        if (a === 2) {
          this.orderStep1 = false
          this.orderStep2 = false
          this.orderStep4 = false
          this.orderStep5 = false
          return this.orderStep3 = !this.orderStep3
        }
        if (a === 3) {
          this.orderStep1 = false
          this.orderStep2 = false
          this.orderStep3 = false
          this.orderStep5 = false
          return this.orderStep4 = !this.orderStep4
        }
        if (a === 4) {
          this.orderStep1 = false
          this.orderStep2 = false
          this.orderStep3 = false
          this.orderStep4 = false
          return this.orderStep5 = !this.orderStep5
        }
        return this.orderStep1 = false
      },
      deep: true
    }
  }, // data ends
  mounted() {
    this.getOrderHeader()
    this.GetPurchaseNodeLogNow(-1)
  },
  methods: {
    // 查看详情
    view_detail(type) {
      this.orderStepTemp = ''
      this.orderStepTemp = type
      this.GetPurchaseNodeLogNow(type, true)
    },
    // 获取订单状态
    GetPurchaseNodeLogNow(type, isFlag) {
      this.submitLogListLoading = true
      this.$api.GetPurchaseNodeLogNow({
        PurchareOrderId: this.OrderID,
        SeleType: type
      }).then(res => {
        console.log(res)
        if (res.RetCode !== '0') {
          this.orderStep = -1
          this.submitLogListLoading = false
          // this.orderStep1 = -1
          return this.$message.error(res.RetMsg)
        }
        this.submitLogListLoading = false
        if (!isFlag) {
          this.orderStep = res.Message.PurchareOrderType
        }
        console.log(' this.orderStep', this.orderStep)
        this.submitLogList = res.Message.orderLogs
      }).catch(() => this.submitLogListLoading = false)
    },
    // 获取订单表头信息
    getOrderHeader() {
      const _this = this
      _this.orderheaderLoading = true
      _this.$api.QueryPurchaseOrderInfoByID({ orderPurchaseSapID: _this.OrderID }).then((res) => {
        _this.orderheaderLoading = false
        console.log(res)
        if (res.RetCode === '0') {
          _this.orderHeadInfo = res.Message
        } else {
          _this.$message('获取订单信息出错：' + res.RetMsg)
        }
      })
    },
    // 获取人员信息
    getUserInfo(_CreateUserID, _this) {
      $orderReq.GetUserInfoList({ UserId: _CreateUserID }).then((res) => {
        if (res.RetCode === '0') {
          // 调用人员信息
        } else {
          _this.$message('获取订单信息出错：' + res.RetMsg)
        }
      })
    },
    // 打开打印订单
    // 打开打印订单
    openprintorder() {
      const _this = this
      // 处理打印数据
      this.pritInfo = {
        fileNo: $commonJs.guid(), // 文件编号
        OrderID: _this.OrderID, // 订单编号
        CreateUserName: _this.orderHeadInfo.CreateUserName, // 公司名称
        OfficeTel: _this.orderHeadInfo.OfficeTel, // 订货单位：
        Mobile: _this.orderHeadInfo.Mobile, // 订货人：
        SimsSectionName: _this.orderHeadInfo.SimsSectionName, // 联系电话：
        CompanyName: _this.orderHeadInfo.CompanyName, // 收货地址：
        AgentCompanyName: _this.orderHeadInfo.AgentCompanyName, // 收货人：
        ArrivalDate: _this.orderHeadInfo.ArrivalDate, // 收货电话：
        InvoiceType: _this.orderHeadInfo.InvoiceType, // 收票地址：
        SendAddress: _this.orderHeadInfo.SendAddress, // 收票电话：
        ContactName: _this.orderHeadInfo.ContactName, // 终端：
        CollectTicketSendAddress: _this.orderHeadInfo.CollectTicketSendAddress, // 订单备注：
        Remark: _this.orderHeadInfo.Remark, // 订货日期：
        tableList: [
          // {
          //   hh: 'fsad', // 货号
          //   cpm: 'fsd', // 产品名
          //   gg: 'fsd', // 规格
          //   sl: 10, // 数量
          //   xydj: 10, // 协议单价
          //   zhdj: 5, // 折后单价
          //   yjze: 100, // 原价总额
          //   yfze: 50 // 应付总额
          // }
        ]
      }
      let _yjze = 0
      let _yfze = 0
      const _arr = _this.$parent.$refs.orderMaterialList.materialList
      _arr.forEach(element => {
        _yjze = _yjze + element.LatestAmount
        _yfze = _yfze + element.LatestAmount
        this.pritInfo.tableList.push(
          {
            hh: element.InvCode,
            cpm: element.MaterialName,
            gg: element.Specification,
            sl: element.Num,
            xydj: element.LatestAmount,
            zhdj: element.LatestAmount,
            yjze: element.LatestAmount,
            yfze: element.LatestAmount
          }
        )
      })
      // 处理最后一行
      this.pritInfo.tableList.push({
        hh: '',
        cpm: '',
        gg: '',
        sl: '',
        xydj: '',
        zhdj: '合计（元）',
        yjze: _yjze,
        yfze: _yfze
      })
      this.printorderVisible = true
    },
    colspan({ row, column, rowIndex, columnIndex }) {
      if (rowIndex === this.pritInfo.tableList.length - 1) {
        switch (columnIndex) {
          case 0:
            return [1, 5]
          case 5:
            return [1, 1]
          case 6:
            return [1, 1]
          case 7:
            return [1, 1]
          default: return [0, 0]
        }
      }
    },
    // 打开编辑备注弹窗
    setRemake() {
      this.orderHeadInfoRemark = ''
      this.orderHeadInfoRemark = this.orderHeadInfo.Remark
      this.updateOrderRemarkVisibel = true
    },
    // 打印订单
    printorder() {
      const html = document.querySelector('#' + 'printorderDom').innerHTML
      console.log(html)
      // 新建一个 DOM
      const div = document.createElement('div')
      const printDOMID = 'printDOMElement'
      div.id = printDOMID
      div.innerHTML = html
      document.querySelector('body').appendChild(div)
      const dom_ = document.getElementById('printDOMElement')
      const tr_arr = document.getElementsByClassName('mid_info')
      const header_arr = document.getElementsByClassName('header')
      const empty = document.getElementsByClassName('empty')
      const bigfontsize = document.getElementsByClassName('bigfontsize')
      const right = document.getElementsByClassName('right')
      // divider
      const dealdivider = document.getElementsByClassName('dealdivider')
      dom_.style.padding = '10px'
      for (const i in tr_arr) {
        console.log(tr_arr[i].style)
        if (tr_arr[i].style) {
          tr_arr[i].style.display = 'flex'
          tr_arr[i].style.justifyContent = 'space-between'
          tr_arr[i].style.marginTop = '10px'
        }
      }
      // 处理间距
      for (const i in empty) {
        console.log(empty[i].style)
        if (empty[i].style) {
          empty[i].style.marginTop = '20px'
        }
      }
      // 处理居中
      for (const i in header_arr) {
        if (header_arr[i].style) {
          header_arr[i].style.width = '100%'
          header_arr[i].style.textAlign = 'center'
          header_arr[i].style.weight = '100'
        }
      }
      // 处理字符大小
      for (const i in bigfontsize) {
        if (bigfontsize[i].style) {
          bigfontsize[i].style.fontSize = '20px'
        }
      }
      // 靠右
      for (const i in right) {
        if (right[i].style) {
          right[i].style.textAlign = 'right'
        }
      }
      // 处理分割线
      for (const i in dealdivider) {
        if (dealdivider[i].style) {
          dealdivider[i].style.borderTop = '1px solid #dcdfe6'
          dealdivider[i].style.height = '1px'
          dealdivider[i].style.width = '100%'
        }
      }
      printJS({
        printable: 'printDOMElement',
        type: 'html',
        scanStyles: false,
        style: '.el-table__header-wrapper table { border-collapse:collapse !important;width:100% !important;} .el-table__header-wrapper table thead tr th { border-left:1px solid black;border-top:1px solid black; border-right:1px solid black;} .el-table__body-wrapper table tr td { text-align: center; } .el-table__body-wrapper table {border-collapse:collapse;width:100% !important;} .el-table__body-wrapper table tbody tr td { border:1px solid black;}' // 表格样式
      })
      div.remove()
    },
    // 修改订单备注
    comfirmUpateOrderRemar() {
      if (!this.orderHeadInfoRemark) return this.$message.error('请填写内容')
      this.updateOrderRemarkVisibel = false
      this.orderHeadInfo.Remark = this.orderHeadInfoRemark
      this.$api.SavePurchaseOrderRemark({
        OrderID: this.OrderID,
        Remark: this.orderHeadInfo.Remark
      }).then(res => {
        if (res.RetCode === '0') {
          return this.$message.success('修改备注成功')
        }
        return this.$message.error(res.RetMsg)
      })
    }
  }
}
</script>
<style scoped lang='scss'>
@import '@/style/index.scss';
@import '@/style/search.scss';
@import '@/style/main.scss';
@import '@/style/order.scss';
@import '@/style/selectUser.scss';
@import '@/style/purchase.scss';
</style>
<style>
.steps .el-button--mini {
  padding: 0px 15px;
}
.el-step__title {
  font-size: 12px;
}
.el-dialog--center .el-dialog__body {
  padding: 25px 25px 0px;
}
.cardTitle .el-button--mini {
  padding: 7px 15px;
}
.el-card__header {
  background-color: #f1f9ff;
}
</style>
