import PageHeader from '@/components/PageHeader.vue'
import printJS from 'print-js'
import $orderReq from '@/request/orderApi.js'
import $comjs from '@/minxin/commonJs.js'
const salesOrder = {
  name: 'SalesOrder',
  components: {
    PageHeader
  },
  props: {
    OrderID: {
      type: String,
      default: ''
    },
    Ishandler: {
      type: String,
      default: '-1'
    }
  },
  data() {
    return {
      fullscreenLoading: false,
      saOrderloading: false,
      orderList: [
        {
          IDX: 342,
          OrderID: 'fdada',
          SAPPR: 'ffsda', // 采购申请
          CreateUserName: 'fdsff',
          WriteTime: '2021-03-26',
          SNAME: 'rewr', // 销售负责人
          ListOrderSapDetailsInfo: [
            // {
            //   IDX: 234,
            //   OrderSapID: 'fdsa',
            //   OrderDetailsID: 1,
            //   InvCode: '4324',
            //   ManuPartNum: 'fdsfas',
            //   InvName: 'fdsaf',
            //   Specification: 'fsdf',
            //   BrandName: 'fsdfa',
            //   LotNo: 'df',
            //   HasStock: 'fd',
            //   OrderNum: 100,
            //   DeliveredNum: 21 // 已交货数量
            // }
          ]
        }
      ],
      // 打印销售单
      pritInfo: {
        companyName: '上海润达医疗科技股份有限公司',
        printType: '销售订单',
        tableList: [
          // {
          //   xsdh: 'fdsafsd',
          //   invcode: 'fsdafsd',
          //   ychh: 'fdsafsdf',
          //   invname: 'fdsfsdaf',
          //   brand: 'fdsafsda',
          //   gg: 'fdsfdssad',
          //   dw: 'fdsfdssad',
          //   sl: 32131,
          //   ph: 'fdsfdssad',
          //   xq: 'fdsfdssad',
          //   lsh: 'fdsfdssad'
          // },
          // {
          //   xsdh: 'fdsaffdsfsd',
          //   invcode: '范德萨发',
          //   ychh: 'f',
          //   invname: '富士达',
          //   brand: '范德萨',
          //   gg: '范德萨',
          //   dw: '范德萨',
          //   sl: 32131,
          //   ph: '发散',
          //   xq: 'fa ssad',
          //   lsh: 'fs'
          // }
        ],
        tableList1: [
          // {
          //   companyType: '售达方',
          //   companyName: 'fafsd',
          //   colspan1: '',
          //   colspan2: '',
          //   showtype1: '订单编号',
          //   orderid: 'fafsdf',
          //   colspan3: '',
          //   showtype2: '订单日期',
          //   colspan4: '',
          //   ordertime: '2021-03-03',
          //   colspan5: ''
          // },
          // {
          //   companyType: '送达方',
          //   companyName: '华斯股份',
          //   colspan1: '',
          //   colspan2: '',
          //   showtype1: '部门科室',
          //   orderid: '检验科',
          //   colspan3: '',
          //   showtype2: '',
          //   colspan4: '',
          //   ordertime: '',
          //   colspan5: ''
          // }
        ],
        tableList2: [
          // {
          //   typename: '收货地址',
          //   value: '上海是黄浦区范德萨发抖擞放声大哭发303514 monica 6542165',
          //   colspan1: '',
          //   colspan2: '',
          //   colspan3: '',
          //   colspan4: '',
          //   colspan5: '',
          //   colspan6: '',
          //   colspan7: '',
          //   colspan8: '',
          //   colspan9: ''
          // },
          // {
          //   typename: '订单备注',
          //   value: '富士达发送电风扇大发的范德萨发散a 范德萨发散富士达范德萨a 6542165',
          //   colspan1: '',
          //   colspan2: '',
          //   colspan3: '',
          //   colspan4: '',
          //   colspan5: '',
          //   colspan6: '',
          //   colspan7: '',
          //   colspan8: '',
          //   colspan9: ''
          // },
          // {
          //   typename: '通用备注',
          //   value: '富士达发送电风范德萨发扇大发的范德萨发散a 范德萨发散富士达范德萨a 6542165',
          //   colspan1: '',
          //   colspan2: '',
          //   colspan3: '',
          //   colspan4: '',
          //   colspan5: '',
          //   colspan6: '',
          //   colspan7: '',
          //   colspan8: '',
          //   colspan9: ''
          // }
        ]
      },
      printSaleOrderVisible: false,
      // 选中的销售凭证明细
      checkedsaOrder: [],
      checkedsaOrderidx: [],
      checkedSalesItem: null,
      checkedSalesIndex: -1,
      // 创建交货单 begin ==========================begin==========================
      createDeliveryVisbel: false,
      createDeliveryList: [
        {
          InvCode: 'fdsaf',
          InvName: 'fdsa',
          Specification: '规格', // 规格
          BrandName: '品牌', // 品牌
          MRCStockUnitN: '单位', // 单位
          cctj: '存储条件', // 存储条件
          ph: '批号', // 批号
          xq: '效期', // 效期
          ssck: '所属仓库', // 所属仓库
          sybh: '剩余备货', // 剩余备货
          kc: '库存', // 库存
          sl: '效期', // 数量
          yjhsl: '已交货数量', // 已交货数量
          dj: '单价', // 单价
          xj: '小计' // 小计
        }
      ],
      createDeliveryloading: false,
      createDeliveryReason: '',
      // 创建交货单 end ==========================end==========================
      // 修改库存begin ==========================begin
      updatestockVisbel: false,
      stockList: [
        // {
        //   StockID: 1,
        //   WriteTime: '2021-03-06', // 入库时间,
        //   LotNo: 'fdfds', // 批号,
        //   LotNum: 'fdfsad', // 批次号,
        //   ExpiredDate: '2021-03-06', // 有效期截至,
        //   StockNum: 2, // 库存量,
        //   FrozenNum: 10, // 冻结
        //   StockCode: 'fdsafs', // 所属仓库
        //   ProductDate: '2021-03-03' // 生产日期
        // }
      ],
      stockListloading: false,
      materialCurrentRow: {
        index: -1,
        row: null
      },
      // 修改库存end ==========================end
      // 批量修改原数量 begin ==========================begin
      updateNumVisbel: false,
      orderUpdataNumList: [
        // {
        //   orderdetailsId: 1,
        //   invCode: 'fdsa',
        //   invname: 'fdaf',
        //   oldNum: 12,
        //   yjnum: 11, // 已交货数量
        //   newNum: 13 // 新数量
        // },
        // {
        //   orderdetailsId: 2,
        //   invCode: 'fdsfdsafda',
        //   invname: 'fdsa',
        //   oldNum: 12,
        //   yjnum: 11, // 已交货数量
        //   newNum: 13 // 新数量
        // }
      ],
      updateNumReason: '', // 修改理由
      updateNumitem: {
        OrderSapID: '',
        WriteTime: ''
      },
      updateNumDetail: null
      // 批量修改原数量 end ==========================end
    }
  }, // data ends
  mounted() {
    // 获取销售订单列表
    this.getSaleList()
  },
  methods: {
    // ======================================================修改销售单订单数量 begin =============================================
    openUpdateNum(item, detail) {
      const _this = this
      // 处理修改框数据
      _this.updateNumitem = item
      _this.orderUpdataNumList = [{
        IDX: detail.IDX,
        InvCode: detail.InvCode,
        MaterialName: detail.InvName,
        Num: detail.OrderQty,
        NumSend: detail.DeliveredNum,
        newNum: 0,
        PartID: detail.PartID
      }]
      _this.updateNumReason = null
      // _this.orderUpdataNumList = _this.materialCheckedList
      _this.updateNumVisbel = true
    },
    confirmUpdateNum() {
      const _this = this
      // 判断是否填写 备注
      if (!_this.updateNumReason || _this.updateNumReason.length < 1) {
        _this.$message('请输入修改备注')
        return false
      }
      let isgoing = true
      // 校验输入的新数量不能小于已发数量
      _this.orderUpdataNumList.forEach((ele) => {
        if (ele.newNum <= 0) {
          _this.$message('物料 ' + ele.InvCode + ' 的新数量要大于0！')
          isgoing = false
          return false
        }
        if (ele.Num <= ele.newNum) {
          _this.$message('物料 ' + ele.InvCode + ' 的新数量不能大于等于原数量！')
          isgoing = false
          return false
        }
        if (ele.newNum < ele.NumSend) {
          _this.$message('物料 ' + ele.InvCode + ' 的新数量不能小于已发数量！')
          isgoing = false
          return false
        }
      })
      if (isgoing) {
        _this.$confirm('确认修改订单所填写的原数量?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          _this.UpdateNum()
        }).catch(() => {
        })
      }
    },
    UpdateNum() {
      const _this = this
      _this.$parent.fullscreenLoading = true
      const _DataJson = []
      _this.orderUpdataNumList.forEach((ele) => {
        _DataJson.push({
          OrderSapID: _this.updateNumitem.OrderSapID,
          OrderSapDetailsID: ele.IDX,
          NewNum: ele.newNum
        })
      })
      $orderReq.UpdateOrderSapDetailsNumBatch({
        OrderID: _this.updateNumitem.OrderID,
        Remark: _this.updateNumReason,
        DetailsList: JSON.stringify(_DataJson),
        OrderGuid: localStorage.getItem('OrderGuid')
      }).then((res) => {
        _this.$parent.fullscreenLoading = false
        if (res.RetCode === '0') {
          _this.$parent.$refs.orderHeader.getOrderHeader()
          _this.$parent.$refs.orderMaterialList.getOrderMaterialList()
          _this.$parent.$refs.orderDeliveryList.getDeliveryList()
          _this.getSaleList()
          _this.$message('操作成功')
          localStorage.setItem('OrderGuid', res.OrderGuid)
        } else {
          _this.$message('修改销售明细原数量出错：' + res.RetMsg)
        }
      })
      this.updateNumVisbel = false
    },
    // ======================================================修改销售单订单数量 end =============================================
    // ======================================================创建交货单 begin =============================================
    // 合并列
    dealspan_SalesForDelivery({ row, column, rowIndex, columnIndex }) {
      // const _this = this
      console.log()
      // 定义合并列的索引
      const spancloarr = [1, 2, 3, 4, 5, 6, 11, 13, 14, 15]
      if (spancloarr.indexOf(columnIndex) > -1) {
        if (row.isberowspaned) {
          return {
            rowspan: 0,
            colspan: 0
          }
        } else {
          if (row.spancolnum > 1) {
            return {
              rowspan: row.spancolnum,
              colspan: 1
            }
          }
        }
      }
    },
    dealspanData(_data, _this) {
      // 定义分组idx 数组
      const _idxarr = []
      // this.$set(ele, 'isdisable', true)
      let spancolnum = 0
      _data.forEach((ele) => {
        if (_idxarr.indexOf(ele.PartID) < 0) {
          spancolnum = 0
          _idxarr.push(ele.PartID)
          _data.forEach((item) => {
            if (item.PartID === ele.PartID) {
              spancolnum = parseInt(spancolnum) + 1
            }
          })
          _this.$set(ele, 'spancolnum', spancolnum)
          _this.$set(ele, 'isberowspaned', false)
          // ele.isberowspaned = false
        } else {
          _this.$set(ele, 'isberowspaned', true)
          // ele.isberowspaned = true
        }
      })
      console.log(_data)
    },
    // 选择批号
    // 校验可填的交货单数量
    checkedNum(_row) {
      const _this = this
      if ($comjs.checkedNum(_row.num) < 0) {
        _this.$message.error('请输入数字！')
        _row.num = 0
        return false
      } else {
        if (_row.num <= 0) {
          _this.$message.error('请输入大于0 的数字！')
          _row.num = 0
          return false
        }
        if (_row.LotNum && _row.LotNum.length > 0) {
          // 校验输入的数据是否大于库存
          if (_row.StockNumV2 < _row.num) {
            if (_row.StockNumV2 < (_row.num - _row.NumSend) && _row.StockNumV2 < _row.CurrentSendNum) {
              _row.CurrentSendNum = _row.StockNumV2
              _this.$message('请填写小于当前库存的数量')
              return false
            }
          }
        }
        if ((_row.num - _row.NumSend) < _row.CurrentSendNum) {
          _row.CurrentSendNum = _row.num - _row.NumSend
          _this.$message.error('请填写小于当前未发数据的数量')
          return false
        }
      }
    },
    UseStock(_row) {
      const _this = this
      _this.stockListloading = true
      _this.materialCurrentRow.row.ExpiredDate = _row.ExpiredDate
      _this.materialCurrentRow.row.Location = _row.StockCode
      _this.materialCurrentRow.row.LotNum = _row.LotNum
      _this.materialCurrentRow.row.LotNo = _row.LotNo
      _this.materialCurrentRow.row.stockid = _row.StockID
      _this.materialCurrentRow.row.StockNumV2 = _row.StockNum
      if (_this.materialCurrentRow.row.num - _this.materialCurrentRow.row.NumSend > _row.StockNum) {
        _this.materialCurrentRow.row.CurrentSendNum = _row.StockNum
      } else {
        _this.materialCurrentRow.row.CurrentSendNum = _this.materialCurrentRow.row.num - _this.materialCurrentRow.row.NumSend
      }
      _this.stockListloading = false
      _this.updatestockVisbel = false
    },
    // 确认修改库存
    confirmUseStock(_row) {
      const _this = this
      _this.$confirm('确认选择库存吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        _this.UseStock(_row)
      }).catch(() => {
      })
    },
    // 打开库存框
    openupdatestock(_index, _materialRow) {
      this.materialCurrentRow.index = _index
      this.materialCurrentRow.row = _materialRow
      this.getStockBymateralrefid()
      this.updatestockVisbel = true
      // 获取库存
    },
    getStockBymateralrefid() {
      const _this = this
      _this.stockListloading = true
      _this.stockList = []
      // 2171061 _this.materialCurrentRow.row.MaterialRefCompanyID
      $orderReq.GetMaterialStockList({ MaterialRefCompanyID: _this.materialCurrentRow.row.MaterialRefCompanyID }).then(res => {
        _this.stockListloading = false
        if (res.RetCode === '0') {
          _this.stockList = res.Message
        } else {
          _this.updatestockVisbel = false
          _this.$message.error('出现错误：' + res.RetMsg)
        }
      })
    },
    // 删除拆分行
    DoPartDelet(index) {
      this.createDeliveryList.splice(index, 1)
      this.dealspanData(this.createDeliveryList, this)
    },
    // 生成交货单拆分
    DoPart(_row, index) {
      const _copyRow = {}
      // 复制当前行
      Object.assign(_copyRow, _row)
      _copyRow.isOrign = 0
      _copyRow.LotNum = null
      _copyRow.ExpiredDate = null
      _copyRow.Location = null
      _copyRow.StockNumV2 = null
      _copyRow.CurrentSendNum = 0
      _copyRow.stockid = 0
      this.createDeliveryList.splice(index + 1, 0, _copyRow)
      this.dealspanData(this.createDeliveryList, this)
    },
    // 验证生成交货单
    confirmCreateDelivery() {
      const _this = this
      let isgoing = true
      // 校验是否选择批号
      _this.createDeliveryList.forEach(ele => {
        if (ele.stockid < 1) {
          isgoing = false
          _this.$message.error('当前存在未选择批号的数据，不能生成交货单！')
          return false
        }
      })
      if (!isgoing) {
        return false
      }
      // 校验拆分行数量相加是否等于未发数量
      const arrNum = []
      let sumNum = 0
      _this.createDeliveryList.forEach(ele => {
        if (arrNum.indexOf(ele.PartID) < 0) {
          arrNum.push(ele.PartID)
          sumNum = 0
          _this.createDeliveryList.forEach(item => {
            if (item.PartID === ele.PartID) {
              sumNum = sumNum + parseFloat(item.CurrentSendNum)
            }
          })
          if ((ele.num - ele.NumSend) < sumNum) {
            isgoing = false
            _this.$message.error('物料 ' + ele.InvCode + ' 所填写的发货数量大于了可交货数量')
            return false
          }
        }
      })
      if (!isgoing) {
        return false
      }
      _this.$confirm('确认生成交货单吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const _data = []
        // 处理数据
        _this.createDeliveryList.forEach((ele) => {
          _data.push(
            {
              OrderDetailsID: ele.IDX,
              PartID: ele.PartID,
              StockID: ele.stockid,
              Num: ele.CurrentSendNum,
              DelFlag: ele.DelFlag
            }
          )
        })
        // {"PartID":111,"MaterialStockSellID":222,"Num":33}
        _this.CreateDelivery(_data)
      }).catch(() => {
      })
    },
    openCretateDelivery() {
      // 判断是否有选中值
      const _this = this
      const _ordrinfo = _this.$parent.$refs.orderHeader.orderHeadInfo
      if (!_this.checkedsaOrder || _this.checkedsaOrder.length < 1) {
        _this.$message('请至少选择一条销售凭证明细！')
        return false
      }
      let isgoing = true
      _this.checkedsaOrder.forEach(ele => {
        if (ele.num <= ele.NumSend) {
          isgoing = false
          _this.$message('物料 ' + ele.InvCode + '已不能再生成交货单！')
          return false
        }
      })
      if (isgoing) {
        // 处理创建交货单的数据
        _this.saOrderloading = true
        _this.createDeliveryList = []
        _this.checkedsaOrder.forEach(element => {
          // 销售订单明细数量 大于 已交货数量才可生成交货单
          if (element.DeliveredNum < element.OrderQty) {
            _this.createDeliveryList.push(
              {
                IDX: element.OrderDetailsID,
                InvCode: element.InvCode,
                InvName: element.InvName,
                Specification: element.Specification, // 规格
                BrandName: element.BrandName, // 品牌
                MRCStockUnitN: element.StockUnit, // 单位
                MaterialStorageConditionName: element.StorageConditionName, // 存储条件
                LotNum: null, // 批号
                ExpiredDate: null, // 效期
                Location: null, // 所属仓库
                StockNumV2: null, // 库存
                num: element.OrderQty, // 数量
                NumSend: element.DeliveredNum, // 已交货数量
                ContactPrice: element.LatestAmount, // 单价
                PartID: element.PartID,
                stockid: 0,
                DelFlag: 0,
                isOrign: 1,
                MaterialRefCompanyID: element.MaterialRefCompanyID,
                CurrentSendNum: 0 // 当前行的发货数量
              }
            )
          }
        })
        _this.createDeliveryReason = _ordrinfo.Remark
        _this.createDeliveryVisbel = true
        _this.saOrderloading = false
      }
    },
    // 生成交货单
    CreateDelivery(_data) {
      const _this = this
      _this.$parent.fullscreenLoading = true
      $orderReq.CreateOrderDelivery({ OrderID: _this.OrderID, Remark: _this.createDeliveryReason, OrderDetailsList: JSON.stringify(_data), OrderGuid: localStorage.getItem('OrderGuid') }).then((res) => {
        _this.$parent.fullscreenLoading = false
        if (res.RetCode === '0') {
          _this.$parent.$refs.orderHeader.getOrderHeader()
          _this.$parent.$refs.orderMaterialList.getOrderMaterialList()
          _this.$parent.$refs.orderDeliveryList.getDeliveryList()
          _this.getSaleList()
          _this.$message('操作成功')
          localStorage.setItem('OrderGuid', res.OrderGuid)
          _this.createDeliveryVisbel = false
        } else {
          _this.$message('生成交货单出错' + res.RetMsg)
        }
      })
      this.createDeliveryVisbel = false
    },
    // ======================================================创建交货单 end =============================================
    saOrderListhandleSelectionChange(val, item, index) {
      if (val) {
        if (this.checkedSalesItem !== null && this.checkedSalesItem.IDX !== item.IDX) {
          // 去掉之前所选中的数据
          this.orderList[this.checkedSalesIndex].ListOrderSapDetailsInfo.forEach(ele => {
            this.$refs.salesdetails[this.checkedSalesIndex].toggleRowSelection(ele, false)
          })
          // this.$message.error('当前有选中的其他销售明细，请先取消')
          // return false
        }
        this.checkedSalesItem = item
        this.checkedSalesIndex = index
      }
      this.checkedsaOrder = val
      this.checkedsaOrderidx = []
      if (val) {
        val.forEach(element => {
          this.checkedsaOrderidx.push(element.IDX)
        })
      }
      console.log(this.checkedsaOrder)
    },
    confirmDeleteSales() {
      const _this = this
      if (!_this.checkedsaOrder || _this.checkedsaOrder.length < 1) {
        _this.$message.error('请至少选择一条销售凭证明细！')
        return false
      }
      _this.$confirm('确认删除选中的明细吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        _this.DeleteOrderSapDetailsBatch()
      }).catch(() => {
      })
    },
    // 删除销售订单
    DeleteOrderSapDetailsBatch() {
      const _this = this
      _this.saOrderloading = true
      $orderReq.DeleteOrderSapDetailsBatch({ OrderID: _this.OrderID, IdxList: _this.checkedsaOrderidx.join(','), OrderSapID: _this.checkedsaOrder[0].OrderSapID, OrderGuid: localStorage.getItem('OrderGuid') }).then((res) => {
        _this.saOrderloading = false
        if (res.RetCode === '0') {
          _this.getSaleList()
          _this.$parent.$refs.orderMaterialList.getOrderMaterialList()
          _this.$parent.$refs.orderDeliveryList.getDeliveryList()
          localStorage.setItem('OrderGuid', res.OrderGuid)
          _this.$message('操作成功')
        } else {
          _this.$message('删除订单销售凭证明细出错：' + res.RetMsg)
        }
      })
    },
    // 获取销售订单列表
    getSaleList() {
      const _this = this
      _this.saOrderloading = true
      _this.orderList = []
      $orderReq.GetSalesOrderList({ OrderId: _this.OrderID }).then((res) => {
        _this.saOrderloading = false
        if (res.RetCode === '0') {
          _this.orderList = res.Message
        } else {
          _this.$message('获取订单销售凭证出错：' + res.RetMsg)
        }
      })
    },
    // 地址合并
    colspan2({ row, column, rowIndex, columnIndex }) {
      switch (columnIndex) {
        case 0:
          return [1, 1]
        case 1:
          return [1, 10]
        default: return [0, 0]
      }
    },
    // 表格1 合并
    colSapn({ row, column, rowIndex, columnIndex }) {
      // if(rowIndex === 0 || rowIndex === 1)
      let spanType = 3 // 1: 合并 3列 return 1,3 ； 2 ：合并 2列 return 1，2 ；3： 不合并 reutrn 1,1 ； 4 被合并 return 0,0  默认不合并
      switch (columnIndex) {
        case 1:
          spanType = 1
          break
        case 2:
          spanType = 4
          break
        case 3:
          spanType = 4
          break
        case 5:
          spanType = 2
          break
        case 6:
          spanType = 4
          break
        case 7:
          spanType = 2
          break
        case 8:
          spanType = 4
          break
        case 9:
          spanType = 2
          break
        case 10:
          spanType = 4
          break
        default:
          spanType = 3
      }
      switch (spanType) {
        case 1:
          return [1, 3]
        case 2:
          return [1, 2]
        case 3:
          return [1, 1]
        case 4:
          return [0, 0]
        default: return
      }
    },
    // 打开打印交货单
    openprintSaleOrder(_data) {
      // 处理打印销售单数据
      const _this = this
      const _orderinfo = _this.$parent.$refs.orderHeader.orderHeadInfo
      _this.pritInfo = {
        companyName: _orderinfo.SellCompanyName,
        printType: '销售订单',
        tableList: [
        ],
        tableList1: [
        ],
        tableList2: [
        ]
      }
      // 处理表格 第一个 售达方/送达方
      // 如果 AgentCompanyID 》 0 则售达方是 AgentCompanyName
      // 如果 AgentCompanyID 《1 则售达方是 CompanyName
      _this.pritInfo.tableList = []
      let _comanpanyname = _orderinfo.CompanyName
      if (_orderinfo.AgentCompanyID > 0) {
        _comanpanyname = _orderinfo.AgentCompanyName
      }
      _this.pritInfo.tableList1.push({
        companyType: '售达方',
        companyName: _comanpanyname,
        colspan1: '',
        colspan2: '',
        showtype1: '订单编号',
        orderid: _this.OrderID,
        colspan3: '',
        showtype2: '订单日期',
        colspan4: '',
        ordertime: _orderinfo.WriteTime,
        colspan5: ''
      })
      _this.pritInfo.tableList1.push({
        companyType: '送达方',
        companyName: _orderinfo.CompanyName,
        colspan1: '',
        colspan2: '',
        showtype1: '部门科室',
        orderid: _orderinfo.SimsSectionName,
        colspan3: '',
        showtype2: '',
        colspan4: '',
        ordertime: '',
        colspan5: ''
      })
      // 第二个表格物料明细
      _this.pritInfo.tableList = []
      _data.ListOrderSapDetailsInfo.forEach((ele) => {
        _this.pritInfo.tableList.push({
          xsdh: _data.OrderSapID,
          invcode: ele.InvCode,
          ychh: ele.ManuPartNum,
          invname: ele.InvName,
          brand: ele.BrandName,
          gg: ele.Specification,
          dw: ele.StockUnit,
          sl: ele.OrderNum,
          ph: ele.LotNum,
          xq: ele.ExpiredDate,
          lsh: ''
        })
      })
      // 第三个地址备注信息
      _this.pritInfo.tableList2 = [
        {
          typename: '收货地址',
          value: _this.$parent.$refs.orderHeader.orderaddress.FullAddress,
          colspan1: '',
          colspan2: '',
          colspan3: '',
          colspan4: '',
          colspan5: '',
          colspan6: '',
          colspan7: '',
          colspan8: '',
          colspan9: ''
        },
        {
          typename: '订单备注',
          value: _orderinfo.Remark,
          colspan1: '',
          colspan2: '',
          colspan3: '',
          colspan4: '',
          colspan5: '',
          colspan6: '',
          colspan7: '',
          colspan8: '',
          colspan9: ''
        },
        {
          typename: '通用备注',
          value: _orderinfo.Remark,
          colspan2: '',
          colspan3: '',
          colspan4: '',
          colspan5: '',
          colspan6: '',
          colspan7: '',
          colspan8: '',
          colspan9: ''
        }
      ]
      _this.printSaleOrderVisible = true
    },
    printJStest() {
      printJS({
        printable: 'printsaleDom',
        type: 'html',
        scanStyles: false,
        style: '.el-table__body-wrapper table tr th { text-align: left; } .el-table__body-wrapper table{border-collapse:collapse;width:100% !important;} .el-table__body-wrapper table td { border:1px solid #EBEEF5; text-align: left}' // 表格样式
      })
    },
    // 打印交货单
    printSaleOrder() {
      const html = document.querySelector('#' + 'printsaleDom').innerHTML
      console.log(html)
      // 新建一个 DOM
      const div = document.createElement('div')
      const printDOMID = 'printDOMElement'
      div.id = printDOMID
      div.innerHTML = html
      document.querySelector('body').appendChild(div)
      const dom_ = document.getElementById('printDOMElement')
      const tr_arr = document.getElementsByClassName('mid_info')
      const header_arr = document.getElementsByClassName('header')
      const empty = document.getElementsByClassName('empty')
      // const btn = document.getElementById('btn')
      // btn.parentNode.removeChild(btn)
      dom_.style.padding = '10px'
      for (const i in tr_arr) {
        console.log(tr_arr[i].style)
        if (tr_arr[i].style) {
          tr_arr[i].style.display = 'flex'
          tr_arr[i].style.justifyContent = 'space-between'
          tr_arr[i].style.marginTop = '10px'
        }
      }
      for (const i in empty) {
        console.log(empty[i].style)
        if (empty[i].style) {
          empty[i].style.marginTop = '20px'
        }
      }
      for (const i in header_arr) {
        if (header_arr[i].style) {
          header_arr[i].style.width = '100%'
          header_arr[i].style.textAlign = 'center'
          header_arr[i].style.weight = '100'
        }
      }
      // 提取第一个表格的内容 即表头
      // const ths = div.querySelectorAll('#pritInfotablelist .el-table__header-wrapper th')
      // const ThsTextArry = []
      // for (let i = 0, len = ths.length; i < len; i++) {
      //   if (ths[i].innerText && ths[i].innerText.length > 0) {
      //     ThsTextArry.push(ths[i].innerText)
      //   }
      // }
      // // 删除多余的表头
      // div.querySelector('.hidden-columns').remove()
      // // 第一个表格的内容提取出来后已经没用了 删掉
      // div.querySelector('.el-table__header-wrapper').remove()
      // // 将第一个表格的内容插入到第二个表格
      // let newHTML = '<tr style="text-align: center;">'
      // for (let i = 0, len = ThsTextArry.length; i < len; i++) {
      //   newHTML += '<td style="text-align: center;">' + ThsTextArry[i] + '</td>'
      // }
      // newHTML += '</tr>'
      // div.querySelector('#pritInfotablelist').insertAdjacentHTML('afterbegin', newHTML)
      printJS({
        printable: 'printDOMElement',
        type: 'html',
        scanStyles: false,
        style: '.el-table__header-wrapper table { border-collapse:collapse !important;width:100% !important;} .el-table__header-wrapper table thead tr th { font-weight: 100; border-left:1px solid black; border-right:1px solid black;} .el-table__body-wrapper table tr td { text-align: center; } .el-table__body-wrapper table {border-collapse:collapse;width:100% !important;} .el-table__body-wrapper table tbody tr td { border:1px solid black;}' // 表格样式
      })
      div.remove()
    }
  }
}
export default salesOrder
