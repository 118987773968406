import PageHeader from '@/components/PageHeader.vue'
import $userRequest from '@/request/contactsapi.js'
import printJS from 'print-js'
import $orderReq from '@/request/orderApi.js'
import $commonJs from '@/minxin/commonJs.js'
const orderdetailHeader = {
  name: 'OrderdetailHeader',
  components: {
    PageHeader
  },
  props: {
    OrderID: {
      type: String,
      default: ''
    },
    Ishandler: {
      type: String,
      default: '-1'
    }
  },
  data() {
    return {
      orderheaderLoading: false,
      orderStep: 2,
      orderHeadInfo: {
        // OrderID: 'SDF233432',
        // WriteTime: '2021-03-09', // 创建时间
        // OrderSourceString: '换个方式', // 订单来源
        // OrderStatusString: '已发货', // 订单状态
        // CompanyName: '终fsdfsdfs反倒是就啊', // 终端
        // AgentCompanyName: '买方', // 买方
        // ExpectedDeliveryDate: '2021-03-06', // 期望到货时间
        // InvoiceType: null, // 发票信息
        // address: '上海市宝山区杨行镇氰化氢 1232号 423栋 308', // 收货地址
        // invoiceAddress: '上海市宝山区杨行镇氰化氢 1232号 423栋 308',
        // Remark: '订单备注',
        // IsMyFavoriteOrder: 2, // 是否收藏
        // SimsSectionName: '', // 部门科室
        // Mobile: '',
        // OfficeTel: ''
      }, // 头部信息
      orderaddress: {}, // 订单地址
      orderjpaddress: {}, // 订单寄票地址
      sellLocationAddress: {}, // 供应商仓库地址
      // 修改订单备注
      updateOrderRemarkVisibel: false,
      // ================================================= 选择部门 begin  =================================================
      // ================================================= 选择部门 begin ==================================================
      // 选择部门科室
      deptloading: false,
      selectUseractiveName: 'dept',
      seletUserVisible: false,
      deptdata: [],
      checkedDepInfo: null, // 选中的部门对象
      // ================================================= 选择部门 end  =================================================
      // ================================================= 选择部门 end ==================================================
      // ================================================= 选择地址 begin ==================================================
      addressloading: false,
      seletaddressVisible: false,
      addressList: [
        {
          province: '上海市',
          city: '上海市',
          district: '杨浦区',
          address: '海丽园 2555号 6号楼 2楼',
          username: '张大法师',
          moblie: '3213123',
          deprname: 'fdafsd',
          isdefault: false,
          stauts: 0,
          stautsname: '待审核'
        },
        {
          province: '上海市',
          city: '上海市',
          district: '杨浦区',
          address: '海丽园 2555号 6号楼 2楼',
          username: 'fafdsa',
          moblie: '3213123',
          deprname: 'fdafsd',
          isdefault: true,
          stauts: 1,
          stautsname: '已审核'
        }
      ],
      // ================================================= 选择地址 end  =================================================
      // 提交日志
      submitLogListLoading: false,
      submitLogList: [
        // {
        //   Writer: '432432',
        //   WriteTime: '2021-02-03',
        //   OperTypeSting: 'dfafdsafd'
        // }
      ],
      // 交货单日志
      deliveryLogListLoading: false,
      deliveryLogList: [
        // {
        //   NickName: 'NickName',
        //   WriteTime: '2021-02-03',
        //   OperTypeSting: 'dfafdsafd'
        // }
      ],
      // 仓库发货日志
      sendLogListLoading: false,
      sendLogList: [
        // {
        //   NickName: 'NickName',
        //   WriteTime: '2021-02-03',
        //   OperTypeSting: 'dfafdsafd'
        // }
      ],
      // 物流运输
      transportLogListLoading: false,
      transportLogList: [
        // {
        //   NickName: 'NickName',
        //   WriteTime: '2021-02-03',
        //   OperTypeSting: 'dfafdsafd'
        // }
      ],
      // 确认收货
      reciveLogListLoading: false,
      reciveLogList: [
        // {
        //   reciveNo: 'fsdafs', // 物流单号
        //   reciveTime: '2021-03-06 11:12:00',
        //   contactUser: 'monica'
        // }
      ],
      deliverStatus: -1,
      sendStatus: -1,
      transStatus: -1,
      reciveStatus: -1,
      // 打印订单   =========================================begin =============================
      printorderVisible: false,
      pritInfo: {
        fileNo: '销售出库单', // 文件编号
        OrderID: 'fdsaf', // 订单编号
        companyName: '上海润达医疗科技股份有限公司', // 公司名称
        dhdw: '订货单位：', // 订货单位：
        dhr: '订货人：', // 订货人：
        lxdg: '联系电话：', // 联系电话：
        shdx: '收货地址：', // 收货地址：
        shr: '收货人：', // 收货人：
        shdh: '收货电话：', // 收货电话：
        spdx: '收票地址：', // 收票地址：
        spr: '收票电话：', // 收票电话：
        sendcompany: '终端：', // 终端：
        remark: '订单备注：', // 订单备注：
        dhrq: '订货日期：', // 订货日期：
        tableList: [
          {
            hh: 'fsad', // 货号
            cpm: 'fsd', // 产品名
            gg: 'fsd', // 规格
            sl: 10, // 数量
            xydj: 10, // 协议单价
            zhdj: 5, // 折后单价
            yjze: 100, // 原价总额
            yfze: 50 // 应付总额
          },
          {
            hh: 'fsad', // 货号
            cpm: 'fsd', // 产品名
            gg: 'fsd', // 规格
            sl: 10, // 数量
            xydj: 10, // 协议单价
            zhdj: 5, // 折后单价
            yjze: 100, // 原价总额
            yfze: 50 // 应付总额
          },
          {
            hh: '', // 货号
            cpm: '', // 产品名
            gg: '', // 规格
            sl: 12, // 数量
            xydj: 21, // 协议单价
            zhdj: '合计（元）', // 折后单价
            yjze: 200, // 原价总额
            yfze: 100 // 应付总额
          }
        ]
      },
      checkList: [],
      // 打印订单   =========================================end =============================
      changeAddressType: 0, // 更换地址类型 10 收货 20：寄票
      editaddressReasonvisible: false,
      editaddressReason: '', // 修改收货地址添加备注
      edtitaddressinfo: {}, // 选中的要修改的地址
      editdeptReasonvisible: false,
      editdeptReason: ''
    }
  }, // data ends
  mounted() {
    this.getOrderHeader()
  },
  methods: {
    // 获取日志接口
    getOptionLogs(_type, _this) {
      _this.switchloainglog(_type, true, _this)
      $orderReq.GetOrderProcess({ OrderID: _this.OrderID, Type: _type }).then((res) => {
        _this.switchloainglog(_type, false, _this)
        if (res.RetCode === '0') {
          _this.dealsteps(_this, res.Message)
        } else {
          console.log('操作失败：' + res.RetMsg)
        }
      })
    },
    getStatusIcon(_status) {
      switch (_status) {
        case 0:
          return 'el-icon-remove'
        case 1:
          return 'iconfont icon-chart14'
        case 2:
          return 'el-icon-success'
        default: return 'el-icon-loading'
      }
    },
    dealsteps(_this, _data) {
      // 提交信息
      _this.submitLogList = _data.filter(function(ele) {
        return ele.Type === '1'
      })
      /*
        deliverStatus: 0,
        sendStatus: 0,
        transStatus: 0,
        reciveStatus: 0,
      */
      // 交货单信息
      _this.deliveryLogList = _data.filter(function(ele) {
        return ele.Type === '2'
      })
      _this.deliverStatus = 0
      if (_this.deliveryLogList && _this.deliveryLogList.length > 0) {
        _this.orderStep = 2
        _this.deliverStatus = _this.deliveryLogList[0].Status
      }
      // 仓库发货信息
      _this.sendStatus = 0
      _this.sendLogList = _data.filter(function(ele) {
        return ele.Type === '3'
      })
      if (_this.sendLogList && _this.sendLogList.length > 0) {
        _this.orderStep = 3
        _this.sendStatus = _this.sendLogList[0].Status
      }
      // 物流信息
      _this.transStatus = 0
      _this.transportLogList = _data.filter(function(ele) {
        return ele.Type === '4'
      })
      if (_this.transportLogList && _this.transportLogList.length > 0) {
        _this.orderStep = 4
        _this.transStatus = _this.transportLogList[0].Status
      }
      // 确认收货信息
      _this.reciveStatus = 0
      _this.reciveLogList = _data.filter(function(ele) {
        return ele.Type === '5'
      })
      if (_this.reciveLogList && _this.reciveLogList.length > 0) {
        _this.orderStep = 5
        _this.reciveStatus = _this.reciveLogList[0].Status
      }
    },
    getsubmitoptionsLog(_this) {
      _this.submitLogList = []
      _this.submitLogList.push({
        Writer: _this.orderHeadInfo.CreateUserName,
        WriteDate: _this.orderHeadInfo.WriteTime,
        Remark: '提交订单'
      })
      console.log(_this.submitLogList)
    },
    switchloainglog(_type, stauts, _this) {
      switch (_type) {
        case 1:
          _this.submitLogListLoading = stauts
          break
        case 2:
          _this.deliveryLogListLoading = stauts
          break
        case 3:
          _this.sendLogListLoading = stauts
          break
        case 4:
          _this.transportLogListLoading = stauts
          break
        case 5:
          _this.reciveLogListLoading = stauts
          break
        default:
      }
    },
    // 获取地址
    getSellcompanyAddress() {
      const _this = this
      $orderReq.GetWarehouseUserAddressInfo().then((res) => {
        if (res.RetCode === '0') {
          _this.sellLocationAddress = res.Message
        } else {
          console.log('操作失败：' + res.RetMsg)
        }
      })
    },
    // 修改订单备注
    comfirmUpateOrderRemar() {
      const _this = this
      if (!_this.orderHeadInfo.Remark || _this.orderHeadInfo.Remark.length < 1) {
        _this.$message('请输入备注！')
        return false
      }
      _this.$confirm('确认修改订单备注吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        _this.UpateOrderRemar()
      }).catch(() => {
      })
    },
    UpateOrderRemar() {
      const _this = this
      _this.orderheaderLoading = true
      $orderReq.ModifyOrderRemark({ OrderID: _this.OrderID, Remark: _this.orderHeadInfo.Remark, OrderGuid: localStorage.getItem('OrderGuid') }).then((res) => {
        _this.orderheaderLoading = false
        if (res.RetCode === '0') {
          _this.getOrderHeader()
          _this.$message.success('操作成功')
          localStorage.setItem('OrderGuid', res.OrderGuid)
          this.updateOrderRemarkVisibel = false
        } else {
          _this.$message.error('操作失败：' + res.RetMsg)
        }
      })
    },
    // 修改订单收货地址
    ModifyOrderReciveAddr(_addressid) {
      const _this = this
      _this.orderheaderLoading = true
      $orderReq.ModifyOrderReciveAddr({ OrderID: _this.OrderID, UserAddressID: _addressid, Remark: _this.editaddressReason, OrderGuid: localStorage.getItem('OrderGuid') }).then((res) => {
        _this.orderheaderLoading = false
        if (res.RetCode === '0') {
          _this.getOrderHeader()
          _this.$message.success('操作成功')
          localStorage.setItem('OrderGuid', res.OrderGuid)
          _this.seletaddressVisible = false
        } else {
          _this.$message.error('操作失败：' + res.RetMsg)
        }
      })
    },
    // 修改订单寄票地址
    ModifyOrderInvoiceAddr(_addressid) {
      const _this = this
      _this.orderheaderLoading = true
      $orderReq.ModifyOrderInvoiceAddr({ OrderID: _this.OrderID, UserAddressID: _addressid, OrderGuid: localStorage.getItem('OrderGuid') }).then((res) => {
        _this.orderheaderLoading = false
        if (res.RetCode === '0') {
          _this.getOrderHeader()
          _this.$message.success('操作成功')
          localStorage.setItem('OrderGuid', res.OrderGuid)
          _this.seletaddressVisible = false
        } else {
          _this.$message.error('操作失败：' + res.RetMsg)
        }
      })
    },
    // 获取订单地址
    Getordershdz(_orderid) {
      const _this = this
      $orderReq.GetOrderRefAddressInfo({ OrderId: _orderid }).then((res) => {
        if (res.RetCode === '0') {
          // 获取订单地址
          if (res.Message && res.Message.length > 0) {
            _this.orderaddress = res.Message[0]
          }
        } else {
          _this.$message.error('获取订单信息出错：' + res.RetMsg)
        }
      })
    },
    // 获取订单收票地址
    Getorderspdz(_orderid) {
      const _this = this
      $orderReq.GetOrderRefSendInvoiceAddressInfo({ OrderId: _orderid }).then((res) => {
        if (res.RetCode === '0') {
          // 获取订单收票地址
          if (res.Message && res.Message.length > 0) {
            _this.orderjpaddress = res.Message[0]
          }
        } else {
          _this.$message.error('获取订单信息出错：' + res.RetMsg)
        }
      })
    },
    // 获取订单表头信息
    getOrderHeader() {
      const _this = this
      _this.orderheaderLoading = true
      $orderReq.GetOrderList({ OrderID: _this.OrderID }).then((res) => {
        _this.orderheaderLoading = false
        if (res.RetCode === '0') {
          _this.orderHeadInfo = res.Message[0]
          // 调用地址信息
          // _this.getsubmitoptionsLog(_this)
          _this.Getordershdz(_this.OrderID, _this)
          _this.Getorderspdz(_this.OrderID, _this)
          _this.getSellcompanyAddress()
          _this.getOptionLogs(-1, _this)
          // _this.getOptionLogs(2, _this)
          // _this.getOptionLogs(3, _this)
          // _this.getOptionLogs(4, _this)
          // _this.getOptionLogs(5, _this)
        } else {
          _this.$message.error('获取订单信息出错：' + res.RetMsg)
        }
      })
    },
    // 获取人员信息
    getUserInfo(_CreateUserID, _this) {
      $orderReq.GetUserInfoList({ UserId: _CreateUserID }).then((res) => {
        if (res.RetCode === '0') {
          // 调用人员信息
        } else {
          _this.$message.error('获取订单信息出错：' + res.RetMsg)
        }
      })
    },
    // 打开打印订单
    openprintorder() {
      const _this = this
      // 处理打印数据
      this.pritInfo = {
        fileNo: $commonJs.guid(), // 文件编号
        OrderID: _this.OrderID, // 订单编号
        companyName: _this.orderHeadInfo.SellCompanyName, // 公司名称
        dhdw: _this.orderHeadInfo.companyName, // 订货单位：
        dhr: _this.orderHeadInfo.CreateUserName, // 订货人：
        lxdg: _this.orderHeadInfo.Mobile, // 联系电话：
        shdx: _this.orderaddress.FullAddress, // 收货地址：
        shr: _this.orderaddress.ContactName, // 收货人：
        shdh: _this.orderaddress.ContactTel, // 收货电话：
        spdx: _this.orderjpaddress.FullAddress, // 收票地址：
        spr: _this.orderjpaddress.ContactTel, // 收票电话：
        sendcompany: _this.orderHeadInfo.companyName, // 终端：
        remark: _this.orderHeadInfo.Remark, // 订单备注：
        dhrq: _this.orderHeadInfo.WriteTime, // 订货日期：
        tableList: [
          // {
          //   hh: 'fsad', // 货号
          //   cpm: 'fsd', // 产品名
          //   gg: 'fsd', // 规格
          //   sl: 10, // 数量
          //   xydj: 10, // 协议单价
          //   zhdj: 5, // 折后单价
          //   yjze: 100, // 原价总额
          //   yfze: 50 // 应付总额
          // }
        ]
      }
      let _yjze = 0
      let _yfze = 0
      const _invarr = []
      const _arr = _this.$parent.$refs.orderMaterialList.materialList
      let _money = 0
      _arr.forEach(element => {
        if (_invarr.indexOf(element.InvCode) < 0) {
          _invarr.push(element.InvCode)
          _money = element.LatestAmount * element.Num
          _yjze = _yjze + _money
          _yfze = _yfze + _money
          this.pritInfo.tableList.push(
            {
              hh: element.InvCode,
              cpm: element.MaterialName,
              gg: element.Specification,
              sl: element.Num,
              xydj: element.LatestAmount.toFixed(2),
              zhdj: element.LatestAmount.toFixed(2),
              yjze: _money.toFixed(2),
              yfze: _money.toFixed(2)
            }
          )
        }
      })
      // 处理最后一行
      this.pritInfo.tableList.push({
        hh: '',
        cpm: '',
        gg: '',
        sl: '',
        xydj: '',
        zhdj: '合计（元）',
        yjze: parseFloat(_yjze).toFixed(2),
        yfze: parseFloat(_yfze).toFixed(2)
      })
      this.printorderVisible = true
    },
    colspan({ row, column, rowIndex, columnIndex }) {
      if (rowIndex === this.pritInfo.tableList.length - 1) {
        switch (columnIndex) {
          case 0:
            return [1, 5]
          case 5:
            return [1, 1]
          case 6:
            return [1, 1]
          case 7:
            return [1, 1]
          default: return [0, 0]
        }
      }
    },
    // 打印订单
    printorder() {
      const html = document.querySelector('#' + 'printorderDom').innerHTML
      console.log(html)
      // 新建一个 DOM
      const div = document.createElement('div')
      const printDOMID = 'printDOMElement'
      div.id = printDOMID
      div.innerHTML = html
      document.querySelector('body').appendChild(div)
      const dom_ = document.getElementById('printDOMElement')
      const tr_arr = document.getElementsByClassName('mid_info')
      const header_arr = document.getElementsByClassName('header')
      const empty = document.getElementsByClassName('empty')
      const bigfontsize = document.getElementsByClassName('bigfontsize')
      const right = document.getElementsByClassName('right')
      // divider
      const dealdivider = document.getElementsByClassName('dealdivider')
      dom_.style.padding = '10px'
      for (const i in tr_arr) {
        console.log(tr_arr[i].style)
        if (tr_arr[i].style) {
          tr_arr[i].style.display = 'flex'
          tr_arr[i].style.justifyContent = 'space-between'
          tr_arr[i].style.marginTop = '10px'
        }
      }
      // 处理间距
      for (const i in empty) {
        console.log(empty[i].style)
        if (empty[i].style) {
          empty[i].style.marginTop = '20px'
        }
      }
      // 处理居中
      for (const i in header_arr) {
        if (header_arr[i].style) {
          header_arr[i].style.width = '100%'
          header_arr[i].style.textAlign = 'center'
          header_arr[i].style.weight = '100'
        }
      }
      // 处理字符大小
      for (const i in bigfontsize) {
        if (bigfontsize[i].style) {
          bigfontsize[i].style.fontSize = '20px'
        }
      }
      // 靠右
      for (const i in right) {
        if (right[i].style) {
          right[i].style.textAlign = 'right'
        }
      }
      // 处理分割线
      for (const i in dealdivider) {
        if (dealdivider[i].style) {
          dealdivider[i].style.borderTop = '1px solid #dcdfe6'
          dealdivider[i].style.height = '1px'
          dealdivider[i].style.width = '100%'
        }
      }
      printJS({
        printable: 'printDOMElement',
        type: 'html',
        scanStyles: false,
        style: '.el-table__header-wrapper table { border-collapse:collapse !important;width:100% !important;} .el-table__header-wrapper table thead tr th { border-left:1px solid black;border-top:1px solid black; border-right:1px solid black;} .el-table__body-wrapper table tr td { text-align: center; } .el-table__body-wrapper table {border-collapse:collapse;width:100% !important;} .el-table__body-wrapper table tbody tr td { border:1px solid black;}' // 表格样式
      })
      div.remove()
    },
    // ================================================= 选择地址 begin  =================================================
    openAddress(_changeAddressType) {
      // 更换地址类型 10 收货 20：寄票
      const _this = this
      _this.changeAddressType = _changeAddressType
      _this.orderheaderLoading = true
      _this.edtitaddressinfo = null
      // 10 收货地址 20 寄票地址
      $orderReq.GetUseraddressList({ CompanyID: _this.orderHeadInfo.CompanyID, AddrType: _changeAddressType }).then((res) => {
        _this.orderheaderLoading = false
        if (res.RetCode === '0') {
          _this.addressList = res.Message
        } else {
          _this.$message.error('获取地址出错：' + res.RetMsg)
        }
      })
      this.seletaddressVisible = true
    },
    confirmCheckedAddress(_address) {
      const _this = this
      _this.edtitaddressinfo = _address
      _this.editaddressReason = ''
      _this.editaddressReasonvisible = true
    },
    validchangeaddress() {
      const _this = this
      let message = '确认修改收货地址吗？'
      if (_this.changeAddressType === 20) {
        message = '确认修改寄票地址吗？'
      }
      _this.$confirm(message, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        _this.editaddressReasonvisible = false
        if (_this.changeAddressType === 10) {
          _this.ModifyOrderReciveAddr(_this.edtitaddressinfo.IDX)
        } else if (_this.changeAddressType === 20) {
          _this.ModifyOrderInvoiceAddr(_this.edtitaddressinfo.IDX)
        }
      }).catch(() => {
      })
    },
    // ================================================= 选择地址 end  =================================================
    // ================================================= 选择部门 begin  =================================================
    // 打开部门选择框
    opendept() {
      this.seletUserVisible = true
      this.getdeptList()
    },
    // 获取部门数据接口
    getdeptList() {
      const _this = this
      _this.deptloading = true
      $userRequest.GetDeptList().then(res => {
        _this.deptloading = false
        if (res.RetCode === '0') {
          _this.deptdata = res.Message
        }
      })
    },
    // 选中部门时
    checkedDept(ischecked, item) {
      const _this = this
      if (ischecked) {
        if (_this.checkedDepInfo) {
          item.ischecked = !ischecked
          _this.$message.warning('只能选择一个部门')
          return false
        }
        if (item.children) {
          item.ischecked = !ischecked
          _this.$message.warning('只能选择一个部门')
          return false
        } else {
          _this.checkedDepInfo = item
        }
      } else {
        _this.checkedDepInfo = null
      }
    },
    // 确认选中人员 _type 1：列表选择业务员 2 创建合约选择业务员
    doconnDept() {
      const _this = this
      if (!_this.checkedDepInfo || _this.checkedDepInfo.length < 1) {
        _this.$message.warning('当前未选择部门')
        return false
      }
      _this.editdeptReason = ''
      _this.editdeptReasonvisible = true
      // _this.dealOrderChangeDepe()
    },
    // 修改部门科室
    dealOrderChangeDepe() {
      const _this = this
      _this.editdeptReasonvisible = false
      // _this.checkedDepInfo
      _this.orderheaderLoading = true
      $orderReq.ModifyOrderDpt({ OrderID: _this.OrderID, GroupID: this.checkedDepInfo.IDX, Remark: _this.editdeptReason, OrderGuid: localStorage.getItem('OrderGuid') }).then(res => {
        _this.orderheaderLoading = false
        if (res.RetCode === '0') {
          _this.seletUserVisible = false
          _this.$message.success('操作成功')
          localStorage.setItem('OrderGuid', res.OrderGuid)
          _this.getOrderHeader()
        } else {
          _this.$message.error('操作失败：' + res.RetMsg)
        }
      })
    },
    // ================================================= 选择部门 end  =================================================
    // 添加/取消我的订单
    addFavorate() {
      const _this = this
      _this.orderheaderLoading = true
      // IsMyFavoriteOrder 1是，2否
      // OptionType  --操作类型：0加入 1取消
      $orderReq.Add_to_MyFavoriteOrder({ OrderId: _this.OrderID, OptionType: _this.orderHeadInfo.IsMyFavoriteOrder === 1 ? 1 : 0 }).then((res) => {
        _this.orderheaderLoading = false
        if (res.RetCode === '0') {
          _this.$message.success('操作成功')
          if (_this.orderHeadInfo.IsMyFavoriteOrder === 1) {
            _this.orderHeadInfo.IsMyFavoriteOrder = 2
          } else {
            _this.orderHeadInfo.IsMyFavoriteOrder = 1
          }
        } else {
          _this.$message.error('请求失败：' + res.RetMsg)
        }
      })
    }
  }
}
export default orderdetailHeader
