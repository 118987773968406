<template>
  <div v-loading.fullscreen.lock="fullscreenLoading" class="orderDetails">
    <!-- 表头 -->
    <orderDetailHeader v-if="!Purchase" id="orderHeader" ref="orderHeader" :order-i-d="OrderID" :ishandler="Ishandler" />
    <PurchaseOrderDetails v-else id="orderHeader" ref="orderHeader" :order-i-d="OrderID" />
    <!-- 物料明细 -->
    <orderdetailMaterialList id="orderMaterialList" ref="orderMaterialList" :ishandler="Ishandler" :order-i-d="OrderID" />
    <!-- 销售凭证 -->
    <salesOrder id="orderSaleList" ref="orderSaleList" :ishandler="Ishandler" :order-i-d="OrderID" />
    <!-- 交货单 -->
    <deliveryOrder id="orderDeliveryList" ref="orderDeliveryList" :ishandler="Ishandler" :order-i-d="OrderID" />
    <!-- 右侧导航 -->
    <orderNavigation ref="orderNavigation" />
    <!-- 底部操作 -->
    <!-- <div class="divbottom p-tb-20" v-if="Ishandler === '999'">
        <el-button class="m-right-20" @click="openCretateDelivery" type="primary" size="small" round>生成交货单</el-button>
        <el-button class="m-right-20" @click="dealTransPurchase" type="primary" size="small" round>转采购</el-button>
        <el-button class="m-right-20" type="primary" size="small" round plain>关闭订单</el-button>
      </div> -->
    <!-- 订单解锁 -->
    <el-dialog title="订单被锁定" :visible.sync="OrderResoveVisibel" width="400px" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false">
      <div class="lockbox m-top-10">
        <span>
          订单 {{ orderResovleInfo.OrderID }} 已被 {{ orderResovleInfo.UserName }} 锁定<br>
          锁定时间 {{ orderResovleInfo.lockTime }}
        </span></div>
      <el-divider />
      <div style="text-align: center;">
        <el-button type="primary" round @click="comfirmOrderResove">解锁并锁定订单</el-button>
      </div>
    </el-dialog>
    <!-- 创建交货单 -->
    <el-dialog title="创建交货单" :visible.sync="createDeliveryVisbel" width="800px" :close-on-click-modal="false">
      <el-table
        v-loading="createDeliveryloading"
        :header-cell-style="{background:'rgb(247,248,252)'}"
        :data="createDeliveryList"
        tooltip-effect="dark"
        show-header
        border
      >
        <el-table-column
          label="序号"
        >
          <template slot-scope="scope">
            <span>{{ scope.$index }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="InvCode"
          label="物料编号"
        />
        <el-table-column
          prop="InvName"
          label="物料名称"
          show-overflow-tooltip
        />
        <el-table-column
          prop="Specification"
          label="规格"
          show-overflow-tooltip
        />
        <el-table-column
          prop="BrandName"
          label="品牌"
          show-overflow-tooltip
        />
        <el-table-column
          prop="MRCStockUnitN"
          label="单位"
          show-overflow-tooltip
        />
        <el-table-column
          prop="MaterialStorageConditionName"
          label="存储条件"
          show-overflow-tooltip
        />
        <el-table-column
          prop="LotNum"
          label="批号"
          show-overflow-tooltip
        />
        <el-table-column
          prop="ExpiredDate"
          label="效期"
          show-overflow-tooltip
        />
        <el-table-column
          prop="Location"
          label="所属仓库"
          show-overflow-tooltip
        />
        <el-table-column
          prop="StockNumV2"
          label="库存"
          show-overflow-tooltip
        />
        <el-table-column
          prop="num"
          label="数量"
          show-overflow-tooltip
        />
        <el-table-column
          prop="NumSend"
          label="已交货数量"
          show-overflow-tooltip
        />
        <el-table-column
          prop="ContactPrice"
          label="单价"
          show-overflow-tooltip
        />
        <el-table-column
          prop=""
          label="小计"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{ scope.row.ContactPrice * scope.row.num }}
          </template>
        </el-table-column>
      </el-table>
      <div class="nowrap m-top-20 flex">
        创建理由：<el-input v-model="createDeliveryReason" size="small" />
      </div>
      <div slot="footer" class="dialog-footer center">
        <el-button @click="createDeliveryVisbel = false">关 闭</el-button>
        <el-button type="primary" @click="createDeliveryVisbel = false">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import orderDetails from '@/minxin/orderMananger/orderDetails.js'
export default orderDetails
</script>
<style scoped lang='scss'>
@import '@/style/index.scss';
@import '@/style/search.scss';
@import '@/style/main.scss';
@import '@/style/order.scss';
@import '@/style/selectUser.scss';
</style>
<style>
.shdz .el-form-item__content {
  width: 62%;
}

</style>
