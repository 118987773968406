import { get, post } from './request'

export default {
  // 订单列表  ===================================== 订单列表 begin ==========================================
  // 获取订单各个类型的数量
  GetOrderStatusStatistics: data => post('/Order/GetOrderStatusStatistics', data),
  // 批量关闭订单
  OrderInfoStatusUpdate: data => post('/Order/OrderInfoStatusUpdate', data),
  // 添加/取消 我的借口
  Add_to_MyFavoriteOrder: data => post('/Order/Add_to_MyFavoriteOrder', data),
  // 导出订单byidxs
  exportDataByidx: data => post('/exportDataByidx', data),
  // 导出订单根据条件
  exportData: data => post('/exportData', data),
  // 获取订单列表
  GetOrderList: data => post('/Order/GetOrderList', data),
  // 订单列表  ===================================== 订单列表 end ==========================================
  // 订单详情表头  =====================================订单详情表头 begin ==========================================
  // 获取人员信息
  GetUserInfoList: data => post('/Order/GetUserInfoList', data),
  // 获取订单收货地址
  GetOrderRefAddressInfo: data => post('/Order/GetOrderRefAddressInfo', data),
  // 获取订单寄票地址
  GetOrderRefSendInvoiceAddressInfo: data => post('/Order/GetOrderRefSendInvoiceAddressInfo', data),
  // 编辑部门和科室
  ModifyOrderDpt: data => post('/Order/ModifyOrderDpt', data),
  // 获取订单收货地址
  GetUseraddressList: data => get('/GetUseraddressList', data),
  // 修改订单收货地址
  ModifyOrderReciveAddr: data => post('/Order/ModifyOrderReciveAddr', data),
  // 修改订单寄票地址
  ModifyOrderInvoiceAddr: data => post('/Order/ModifyOrderInvoiceAddr', data),
  // 修改订单备注
  ModifyOrderRemark: data => post('/Order/ModifyOrderRemark', data),
  // 获取各个节点的操作日志
  GetOrderProcess: data => post('/Order/GetOrderProcess', data),
  // 订单详情表头  =====================================订单详情表头 end ==========================================
  // 订单详情物料列表  =====================================订单详情物料列表 begin ==========================================
  GetOrderDetailsList: data => post('/Order/GetOrderDetailsList', data),
  // 分配批号接口
  SaveDistributeStock: data => post('/SaveDistributeStock', data),
  // 一键清空批号
  ClearOrderLotNo: data => post('/ClearOrderLotNo', data),
  // 删除物料明细
  DeleteOrderDetails: data => post('/DeleteOrderDetails', data),
  // 修改订单原数量
  UpdateOrderDetailsNumBatch: data => post('/UpdateOrderDetailsNumBatch', data),
  // 获取可添加的物料列表
  GetAddOrderMaterialList: data => post('/GetAddOrderMaterialList', data),
  // 确认添加物料
  SaveAddOrderMaterial: data => post('/SaveAddOrderMaterial', data),
  // 添加拆分行行备注
  SaveOrderDetailsPartRemark: data => post('/SaveOrderDetailsPartRemark', data),
  // 获取订单物料操作日志
  GetOperationRecordList: data => get('/GetOperationRecordList', data),
  // 获取订单物料明细合约列表
  GetOrderDetailsContractList: data => get('/GetOrderDetailsContractList', data),
  // 修改订单物料明细合约
  SaveOrderDetailsContractUpdate: data => post('/SaveOrderDetailsContractUpdate', data),
  // 选择批号列表
  GetMaterialStockList: data => get('/GetMaterialStockList', data),
  // 确认批号
  SaveOrderDetailsPartSelectStock: data => post('/SaveOrderDetailsPartSelectStock', data),
  // 生成交货单
  CreateOrderDelivery: data => post('/CreateOrderDelivery', data),
  // 获取仓库地址
  GetWarehouseUserAddressInfo: data => get('/GetWarehouseUserAddressInfo', data),
  // 获取上一次的批号
  GetHistoryLotNum: data => get('/GetHistoryLotNum', data),
  // 转采购
  CreatePruchaseApply: data => post('/CreatePruchaseApply', data),
  // 保存物料
  SaveOrderDetails: data => post('/SaveOrderDetails', data),
  // 订单详情物料列表  =====================================订单详情物料列表 end ==========================================
  // 订单销售订单列表  =====================================订单销售订单列表 begin ==========================================
  GetSalesOrderList: data => post('/Order/GetSalesOrderList', data),
  // 删除销售订单
  DeleteOrderSapDetailsBatch: data => post('/DeleteOrderSapDetailsBatch', data),
  // 修改销售明细得数量
  UpdateOrderSapDetailsNumBatch: data => post('/UpdateOrderSapDetailsNumBatch', data),
  // 订单销售订单列表  =====================================订单销售订单列表 end ==========================================
  // 订单交货单列表  =====================================订单交货单列表 begin ==========================================
  GetDeliveryOrderList: data => post('/Order/GetDeliveryOrderList', data),
  // 删除交货单明细
  DeleteDeliveryBatch: data => post('/Order/DeleteDeliveryBatch', data),
  // 订单交货单列表  =====================================订单交货单列表 end ==========================================
  // 导出全部订单物料清单(根据搜索条件)
  ExportOrderList: data => post('/Order/ExportOrderList', data),
  // 勾选导出订单物料清单
  ExportOrderDetailsMaterialListCheckBox: data => get('/ExportOrderDetailsMaterialListCheckBox', data),
  // 导出全部交货单物料清单(根据搜索条件)
  ExportOrderDeliveryMaterialList: data => post('/ExportOrderDeliveryMaterialList', data),
  // 勾选导出交货单物料清单
  ExportOrderDeliveryMaterialListCheckBox: data => get('/ExportOrderDeliveryMaterialListCheckBox', data),
  // 查询订单是否被锁住
  OrderIsLock: data => post('/Order/OrderIsLock', data),
  // 解锁订单是否被锁住
  OrderUnLock: data => post('/Order/OrderUnLock', data),
  // 导出全部订单物料清单(根据搜索条件)
  ExportOrderDetailsMaterialList: data => post('/ExportOrderDetailsMaterialList', data),
  // 获取订单物料清单-按订单查询
  GetOrderDetailsMaterialList: data => post('/GetOrderDetailsMaterialList', data),
  // 获取订单物料清单-按交货单查询
  GetOrderDeliveryMaterialList: data => post('/GetOrderDeliveryMaterialList', data),
  // 获取订单处理guid
  getOrderGuid: data => get('/GetOrderGuid', data),
  // 处理订单详情 手动录入库存
  InputStockByOrder: data => post('/InputStockByOrder', data),
  // 处理订单详情 导入库存 上传文件 base64
  OrderMateialStock_ImportUpload: data => post('/OrderMateialStock_ImportUpload', data),
  // 处理订单详情 导入库存保存数据
  OrderMateialStock_ImportData: data => post('/OrderMateialStock_ImportData', data),
  // 处理订单详情 获取可导入拆分数据
  OrderMateialStock_ExportData: data => get('/OrderMateialStock_ExportData', data),
  // 订单处理删除拆分行
  DeleteOrderDetailsPartByIDX: data => post('/DeleteOrderDetailsPartByIDX', data),
  // 导出交货单明细
  ExprotGetOrderDeliveryInfoList: data => post('/ExprotGetOrderDeliveryInfoList', data)
}
