<template>
  <div class="orderDetailsMaterial">
    <div class="p-lr-20 p-bottom-20">
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span class="cardTitle"><i class="el-icon-box" /> 交货单</span>
          <el-button class="ModelBttton1-white-24" type="primary" style="float: right;" round plain @click="getDeliveryList">刷新</el-button>
        </div>
        <div v-for="(item, index) in orderDeliveryList" :key="index" v-loading="deOrderloading" class="saleeachitem">
          <div class="orderHeader flex j-between p-top-10">
            <div class="deliverylistleft flex j-between" style="flex:1">
              <ul>
                <li><label class="Name">交货单号：</label><span>{{ item.SapDeliveryID }}</span></li>
                <li><label class="Name">发货时间</label><span v-if="item.Status > 0">{{ item.DeliveryTime }}</span><span v-else>未发货</span></li>
                <li><label class="Name">供应商</label><span>{{ item.SellCompanyName }}</span></li>
                <li><label class="Name">总价</label><span>{{ item.Price }}</span></li>
                <li><label class="Name">创建时间</label><span>{{ item.WriteTime }}</span></li>
                <li><label class="Name">状态</label><span>{{ item.StatusStr }}</span></li>
              </ul>
            </div>
            <div class="deliverylistright p-left-10">
              <el-button type="primary" size="mini" plain @click="openprintDelivery_(item)">打印交货单</el-button>
              <el-button v-if="Ishandler === '999'" type="primary" size="mini" plain @click="confirmDeletedelevry">删除选中</el-button>
              <el-button type="primary" size="mini" plain @click="exportDelivery(item.DeliveryID)">导出交货单</el-button>
            </div>
          </div>
          <div class="flex j-between p-top-10">
            <el-table
              :header-cell-style="{background:'rgb(247,248,252)'}"
              :data="item.OrderDeliveryDetailslist"
              tooltip-effect="dark"
              show-header
              border
              @selection-change="deliveryOrderListhandleSelectionChange"
            >
              <af-table-column
                fixed="left"
                type="selection"
                width="40"
              />
              <af-table-column
                prop="InvCode"
                label="物料编号"
                show-overflow-tooltip
              />
              <af-table-column
                prop="ManuPartNum"
                label="原厂编号"
                show-overflow-tooltip
              />
              <af-table-column
                prop="MaterialName"
                label="物料名称"
                show-overflow-tooltip
              />
              <af-table-column
                prop="Specification"
                label="规格"
                show-overflow-tooltip
              />
              <af-table-column
                prop="BrandName"
                label="品牌"
                show-overflow-tooltip
              />
              <af-table-column
                prop="SapZCHRG"
                label="批号"
                show-overflow-tooltip
              />
              <af-table-column
                prop="ExpiredDateShow"
                label="效期"
                show-overflow-tooltip
              >
                <!-- <template slot-scope="scope">
                  <span>{{ (scope.row.SapVFDAT !== null && scope.row.SapVFDAT.length > 0) ? $minCommon.formatDate(new Date(scope.row.SapVFDAT), 'yyyy-MM-dd') : '' }}</span>
                </template> -->
              </af-table-column>
              <af-table-column
                prop="Price"
                label="单价"
                show-overflow-tooltip
              />
              <af-table-column
                prop="Num"
                label="数量"
                show-overflow-tooltip
              />
              <af-table-column
                label="金额"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <span>{{ parseFloat(scope.row.Num * scope.row.Price).toFixed(2) }}</span>
                </template>
              </af-table-column>
            </el-table>
          </div>
        </div>
      </el-card>
    </div>
    <!--    打印交货单 模板 start-->
    <el-dialog title="打印交货单" custom-class="dialog_view_print" center :visible="printSaleOrderVisible" :show-close="false" :close-on-click-modal="true">
      <!-- 打印表单 this.$store.state.index.printTemplateBase-->
      <div v-if="printSaleOrderVisible" class="content_view_print">
        <div id="printDom" class="table_info p-lr-20 " style="height:auto">
          <div id="top_view_print">
            <!-- 标题 start -->
            <div class="header weight f20 flex j-center column a-center m-bottom-20">
              <template v-for="(item,index) in info.titleArr">
                <span v-if="item.isChecked" :key="index">
                  {{ item.prop !== 'desc' ? data_info.DeliveryInfo[item.prop] : item.label }}
                </span>
              </template>
            </div>
            <!-- 标题 end -->
            <!-- 表头 start -->
            <div class="flex f-warp t_b_view m-tb-10">
              <template v-for="(item,index) in info.header">
                <div v-if="item.isChecked" :key="index" :ref="'dom_' +index" class="mid_info item_ flex j-between a-center select_none" :style="{width: item.width +'%'}">
                  <div class="content flex ">
                    <div>{{ item.label }}：</div>
                    <div>{{ data_info.DeliveryInfo[item.prop] }}</div>
                  </div>
                </div>
              </template>
            </div>
            <!-- 表头 end -->
            <!-- 描述 start-->
            <div v-if="info.header_desc.isChecked" class="header weight f14 flex j-start a-center m-tb-10">
              {{ info.header_desc.label }}
            </div>
            <!-- 描述 end-->
          </div>
          <!-- 表格 start :span-method="arraySpanMethod"-->
          <div class="m-top-10" style="width: 100%">
            <el-table
              id="pritInfotablelist"
              ref="multipleTable"
              tooltip-effect="dark"
              show-header
              style="width:100%"
              row-key="id"
              :data="pritInfo_.tableList"
              border
            >
              <template v-for="(item,index) in info.table">
                <af-table-column v-if="item.isChecked" :key="index" align="center" :prop="item.prop" :label="item.label" />
              </template>
            </el-table>
          </div>
          <!-- 表格 end-->
          <!-- 合计 备注 start -->
          <div id="table_footer">
            <div v-if="info.foot_table[0].isChecked" class=" flex p-lr-20 header table_footer">
              <div style="margin-right:20px">{{ info.foot_table[0].label }} </div>
              {{ data_info.DeliveryInfo.OrderRemark }}
            </div>
            <div v-if="info.foot_table[1].isChecked" class="flex p-lr-20 header table_footer table_footer_2" :class="info.foot_table[0].isChecked ? '': 'table_footer_1' ">
              <div style="margin-right:20px">{{ info.foot_table[1].label }} </div>
              {{ data_info.DeliveryInfo.SumSapKWERTFormat }}
            </div>
            <!-- 合计 备注 end -->
          </div>

          <div id="bottom_view_print">
            <!-- 描述 start-->
            <div v-if="info.footer_desc.isChecked" class="header weight f14 flex j-start a-center m-tb-10">
              {{ info.footer_desc.label }}
            </div>
            <!-- 描述 end-->
            <!-- 表头 start -->
            <div class="flex f-warp t_b_view m-tb-10">
              <template v-for="(item,index) in info.footer">
                <div v-if="item.isChecked" :key="index" :ref="'dom_' +index" class="mid_info item_ flex j-between a-center select_none m-tb-10" :style="{width: item.width +'%'}">
                  <div class="content flex">
                    <div>{{ item.label }}</div>
                    <div>{{ item.value }}</div>
                  </div>
                </div>
              </template>
            </div>
            <!-- 表头 end -->
          </div>
        </div>
      </div>
      <div slot="footer" class="caozuo t-right p-top-20 t_line_s m-top-10">
        <el-button @click="print_open">设置打印模板</el-button>
        <el-button @click="printSaleOrderVisible = false">取 消</el-button>
        <el-button type="primary" @click="print">打印</el-button>
      </div>
    </el-dialog>
    <!--    打印交货单 模板 end-->
    <!-- 打印交货单 -->
    <el-dialog class="DepartmentBox" title="打印交货单预览" center :visible.sync="printDeliveryVisible" width="80%" :close-on-click-modal="false">
      <!--<PageHeader class="m-top-10" :border="false" title="打印交货单预览"/>-->
      <div id="printDeliveryDom" class="pritInfo p-lr-10">
        <div class="header weight f14 flex j-center">{{ pritInfo.companyName }}</div>
        <div class="header weight f14 flex j-center">{{ pritInfo.printType }}</div>
        <div class="mid_info f12 flex j-between m-top-20">
          <div style="min-width:300px">客户名称：{{ pritInfo.khName }}</div>
          <div style="min-width:300px">发货温度：{{ pritInfo.fhwd }}</div>
          <div style="min-width:200px">发货方式：{{ pritInfo.fsway }}</div>
        </div>
        <div class="mid_info f12 flex j-between m-top-10">
          <div style="min-width:300px">联系方式：{{ pritInfo.lxfs }}</div>
          <div style="min-width:300px">到货温度：{{ pritInfo.dhwd }}</div>
          <div style="min-width:200px">交货单号：{{ pritInfo.deliveryNo }}</div>
        </div>
        <div class="mid_info f12 flex j-between m-top-10">
          <div style="min-width:300px">地址：{{ pritInfo.address }}</div>
          <div style="min-width:300px">备注：{{ pritInfo.remark }}</div>
          <div style="min-width:200px">发货日期：{{ pritInfo.fhdate }}</div>
        </div>
        <div style="height:20px" />
        <div class="m-tb-10" style="width: 100%">
          <el-table
            :header-cell-style="{background:'rgb(247,248,252)'}"
            :data="pritInfo.tableList"
            :cell-style="cellstyle"
            border
            style="width: 100%"
          >
            <af-table-column prop="ckname" label="仓库名称" width="100px" />
            <af-table-column prop="invname" label="物料名称" />
            <af-table-column prop="ychh" label="原厂货号" />
            <af-table-column prop="chmc" label="存货名称" />
            <af-table-column prop="gg" label="规格型号" />
            <af-table-column prop="dw" label="单位" />
            <af-table-column prop="ph" label="批号" />
            <af-table-column prop="scrq" label="生产日期" />
            <af-table-column prop="xq" label="效期" />
            <af-table-column prop="sl" label="数量" />
            <af-table-column prop="dj" label="单价" />
            <af-table-column prop="je" label="金额" />
            <af-table-column prop="zczh" label="注册证号" />
            <af-table-column prop="xkzh" label="生产企业许可号" />
            <af-table-column prop="sccj" label="生产厂家" />
            <af-table-column prop="cc" label="存储" />
          </el-table>
        </div>
        <div class="mid_info f12 flex j-between m-top-10 empty p-tb-10">
          <div class="printbottom">制单人：{{ pritInfo.zdr }}</div>
          <div class="printbottom">出库人：{{ pritInfo.ckr }}</div>
          <div class="printbottom">复核人：{{ pritInfo.fhr }}</div>
          <div class="printbottom">收发票人：{{ pritInfo.sfpr }}</div>
          <div class="printbottom">收货人：{{ pritInfo.shr }}</div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="printDeliveryVisible = false">取 消</el-button>
        <el-button type="primary" @click="printDelivery">确认打印</el-button>
      </div>
    </el-dialog>
    <Print ref="printa" :companyid="row.SellCompanyID" :custominfo="info" :customerid="row.SendCompanyID" />
  </div>
</template>
<script>
import printJS from 'print-js'
// import PageHeader from '@/components/PageHeader.vue'
import $orderReq from '@/request/orderApi.js'
import $commonJs from '@/minxin/commonJs.js'
import Print from '@/components/print'
import index from '../../minxin/orderMananger/DeliveryOrder'
export default {
  name: 'DeliveryOrder',
  // components: {
  // PageHeader
  // },
  components: {
    Print
  },
  mixins: [index],
  props: {
    // eslint-disable-next-line vue/prop-name-casing
    OrderID: {
      type: String,
      default: ''
    },
    // eslint-disable-next-line vue/prop-name-casing
    Ishandler: {
      type: String,
      default: '-1'
    }
  },
  data() {
    return {
      deOrderloading: false,
      // 打印 start
      printSaleOrderVisible: false,
      info: {},
      data_info: { DeliveryReportCustomInfo: '' },
      row: {},
      pritInfo_: {
        SellCompanyName: '',
        CompanyName: '',
        AgentCompanyName: '',
        DeliveryTime: '',
        SendAddress: '',
        DeliveryID: '',
        SapDeliveryID: '',
        tableList: []
      },
      // 打印 end
      orderDeliveryList: [
        {
          DeliveryID: 'fdada',
          DeliveryTime: '2021-03-26', // 发货时间
          SellCompanyName: 'fdsff', // 供应商
          Price: 1536, // 总价
          Num: 1536, // 总数
          StatusStr: 'f已发货', // 状态
          orderdetalList: [
            {
              IDX: 1,
              InvCode: '4324',
              ManuPartNum: 'fdsfas',
              MaterialName: 'fdsaf',
              Specification: 'fsdf',
              BrandName: 'fsdfa',
              LotNo: 'df',
              ExpiredDate: '效期', // 效期
              Price: 100, // 单价
              Num: 312 // 数量
              // Price: 312 // 金额
            }
          ]
        }
      ],
      // 打印数据
      printDeliveryVisible: false,
      pritInfo: {
        companyName: '上海润达医疗科技股份有限公司',
        printType: '销售出库单',
        khName: '复旦大学附属医院', // 客户名称
        lxfs: '1654544616', // 联系方式
        address: 'fdsfsdfsdfsdfsdfdf',
        fhwd: '36.6', // 发货温度
        fsway: 'fdsfsda', // 发送方式
        dhwd: '46.3', // 到货温度
        deliveryNo: 'd45413545', // 交货单号
        fhdate: '2021-03-06', // 发货日期
        remark: 'fdsf', // 备注
        tableList: [
          {
            ckname: 'fdsaf', // 仓库名称
            invname: 'fsdaf', // 物料名称
            ychh: 'df', // 原厂货号
            chmc: 'fdsaf', // 存货名称
            gg: 'fdf', // 规格型号
            dw: 'fsdaf', // 单位
            ph: 'fsa', // 批号
            scrq: 'fsdfas', // 生产日期
            xq: 'fsdfsaf', // 效期
            sl: 10, // 数量
            dj: 10, // 单价
            je: 100, // 金额
            zczh: 'heg', // 注册证号
            xkzh: 'fdsaf', // 生产厂家许可证号
            sccj: '生产fhrhr厂家', // 生产厂家
            cc: 'ykt' // 存储
          },
          {
            ckname: '合计', // 仓库名称
            invname: '', // 物料名称
            ychh: '', // 原厂货号
            chmc: '', // 存货名称
            gg: '', // 规格型号
            dw: '', // 单位
            ph: '', // 批号
            scrq: '', // 生产日期
            xq: '', // 效期
            sl: 10, // 数量
            dj: null, // 单价
            je: 100, // 金额
            zczh: '', // 注册证号
            xkzh: '', // 生产厂家许可证号
            sccj: '', // 生产厂家
            cc: '' // 存储
          }
        ],
        total: 8,
        price: 4324324,
        zdr: '制单人', // 制单人
        ckr: '出库人', // 出库人
        fhr: '复核人', // 复核人
        sfpr: '收发票人', // 收发票人
        shr: '收货人' // 收货人
      },
      checkeddeliveryOrder: [],
      checkeddeliveryOrderidx: []
    }
  }, // data ends
  mounted() {
    // 获取销售订单列表
    this.getDeliveryList()
    console.log(this.OrderID)
  },
  methods: {
    // 导出交货单
    exportDelivery(deliveryID) {
      const _this = this
      _this.deOrderloading = true
      const paras = {
        SelectIDList: [deliveryID]
      }
      $orderReq.ExprotGetOrderDeliveryInfoList(paras).then((res) => {
        _this.deOrderloading = false
        if (res.RetCode === '0') {
          _this.$message('操作成功')
          $commonJs.downloadFile(res.Message, $commonJs.guid() + '.xlsx')
        } else {
          _this.$message('操作失败')
        }
      })
    },
    // 交货单明细选中
    deliveryOrderListhandleSelectionChange(val) {
      console.log(val)
      this.checkeddeliveryOrder = val
      this.checkeddeliveryOrderidx = []
      if (val) {
        val.forEach(element => {
          this.checkeddeliveryOrderidx.push(element.IDX)
        })
      }
    },
    // 验证删除交货单
    confirmDeletedelevry() {
      const _this = this
      if (!_this.checkeddeliveryOrder || _this.checkeddeliveryOrder.length < 1) {
        _this.$message('请至少选择一条交货明细！')
        return false
      }
      _this.$confirm('确认删除选中的交货明细吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 处理数据
        const _data = []
        _this.checkeddeliveryOrder.forEach((ele) => {
          // IDX#OrderDeliveryIDX#Num#OrderDetailsIDX|交货单明细idx#交货单idx#|订单明细idx|数量
          _data.push(ele.IDX + '#' + ele.DeliveryIDX + '#' + ele.Num + '#' + ele.OrderDetailsIDX)
        })
        console.log(_data)
        _this.Deletedelevry(_data)
      }).catch(() => {
      })
    },
    Deletedelevry(_data) {
      const _this = this
      _this.deOrderloading = true
      $orderReq.DeleteDeliveryBatch({ DeliveryString: _data.join('|') }).then((res) => {
        _this.deOrderloading = false
        if (res.RetCode === '0') {
          _this.$parent.$refs.orderMaterialList.getOrderMaterialList()
          _this.$parent.$refs.orderSaleList.getSaleList()
          _this.getDeliveryList()
          _this.$message('操作成功')
        } else {
          _this.$message('删除交货明细出错：' + res.RetMsg)
        }
      })
    },
    // 获取订单交货单列表
    getDeliveryList() {
      const _this = this
      _this.deOrderloading = true
      _this.orderDeliveryList = []
      $orderReq.GetDeliveryOrderList({ OrderID: _this.OrderID }).then((res) => {
        _this.deOrderloading = false
        if (res.RetCode === '0') {
          _this.orderDeliveryList = res.Message
          _this.dealzj(_this)
        } else {
          _this.$message('获取订单交货单出错：' + res.RetMsg)
        }
      })
    },
    print_open() {
      // this.GetOrderDeliveryPrintInfo()
      this.$refs.printa.open(this.row)
      console.log(this.row)
      // this.printSaleOrderVisible = false
    },
    // 计算总价
    dealzj(_this) {
      let itemzj = 0
      _this.orderDeliveryList.forEach((ele) => {
        itemzj = 0
        ele.OrderDeliveryDetailslist.forEach((item) => {
          itemzj = itemzj + item.Price * item.Num
        })
        ele.Price = itemzj.toFixed(2)
      })
    },
    // 表格居中
    cellstyle() {
      return 'text-align: center;'
    },
    initTemaplateData() {
      this.info = {}
      this.info = { // 自定义打印模板基础数据  非新增或删除  不要做改动
        titleArr: [
          { label: '客户名称', prop: 'SendCompanyName', isChecked: true },
          { label: '供应商名称', prop: 'SellCompanyName', isChecked: false },
          { label: '销售出货单', prop: 'desc', isChecked: true }
        ],
        header: [
          { label: '送达方', prop: 'SendCompanyName', isChecked: true, value: '', width: 42, sort: null },
          { label: 'WMS单号', prop: 'WMSOrderNo', isChecked: true, value: '', width: 35, sort: null },
          { label: '发票号码', prop: 'InvoiceNo', isChecked: true, value: '', width: 20, sort: null },
          { label: '售达方', prop: 'AgentCompanyName', isChecked: true, value: '', width: 42, sort: null },
          { label: '发货单号', prop: 'DeliveryID', isChecked: true, value: '', width: 35, sort: null },
          { label: '出库单号', prop: 'StockOutId', isChecked: true, value: '', width: 35, sort: null },
          { label: '出库日期', prop: 'DeliveryTimeFormat', isChecked: true, value: '', width: 20, sort: null },
          { label: '送货地址', prop: 'SendAddress', isChecked: true, value: '', width: 42, sort: null },
          { label: '币别', prop: 'CurrencyType', isChecked: true, value: '', width: 55, sort: null },
          // 以上为默认展示
          { label: '订单号', prop: 'OrderID', isChecked: false, value: '', width: 35, sort: null },
          { label: '采购单编号', prop: 'SimsOrderID', isChecked: false, value: '', width: 35, sort: null },
          { label: '开票日期', prop: 'InvoiceDateFormat', isChecked: false, value: '', width: 35, sort: null },
          { label: '卖方SAP交货单号', prop: 'SapDeliveryID', isChecked: false, value: '', width: 20, sort: null },
          { label: '快递名称', prop: 'ExpressName', isChecked: false, value: '', width: 42, sort: null },
          { label: '快递单号', prop: 'ExpressNo', isChecked: false, value: '', width: 20, sort: null },
          { label: '供应商', prop: 'SellCompanyName', isChecked: false, value: '', width: 35, sort: null },
          { label: '供货单位地址', prop: 'SellCompanyAddress', isChecked: false, value: '', width: 42, sort: null },
          { label: '供货单位开户银行', prop: 'SellBankName', isChecked: false, value: '', width: 20, sort: null },
          { label: '供货单位账号', prop: 'SellBankAccountNo', isChecked: false, value: '', width: 35, sort: null },
          { label: '物流单号', prop: 'LogisticsCode', isChecked: false, value: '', width: 35, sort: null },
          { label: '订单备注', prop: 'OrderRemark', isChecked: false, value: '', width: 20, sort: null },
          { label: '交货单创建时间', prop: 'WriteTimeFormat', isChecked: false, value: '', width: 42, sort: null },
          { label: '订单创建时间', prop: 'OrderWriteTimeFormat', isChecked: false, value: '', width: 20, sort: null },
          { label: '拣货时间', prop: 'PickedTimeFormat', isChecked: false, value: '', width: 20, sort: null }
        ],
        header_desc: { label: '我是第一个描述的数据', isChecked: false },
        table: [
          { label: '物料编号', isChecked: true, prop: 'InvCode' },
          { label: '原厂编号', isChecked: true, prop: 'ManuPartNum' },
          { label: '物料名称', isChecked: true, prop: 'MaterialName' },
          { label: '品牌', isChecked: true, prop: 'BrandName' },
          { label: '规格\/型号', isChecked: true, prop: 'Specification' },
          { label: '单位', isChecked: true, prop: 'StockUnitN' },
          { label: '物料映射编号', isChecked: true, prop: 'CustomInvCode' },
          { label: '标类', isChecked: true, prop: 'StandardType' },
          { label: '批号', isChecked: true, prop: 'LotNum' },
          { label: '生产日期', isChecked: true, prop: 'ProductDateFormat' },
          { label: '有效截止', isChecked: true, prop: 'ExpiredDateFormat' },
          { label: '数量', isChecked: true, prop: 'DeliveryNumFormat' },
          { label: '含税单价', isChecked: true, prop: 'SapKBETR2Format' },
          { label: '含税金额', isChecked: true, prop: 'SapKWERTFormat' },
          { label: '注册证号', isChecked: true, prop: 'RegisterNo' },
          { label: '生产企业许可证', isChecked: true, prop: 'ProductionLicence' },
          { label: '生产企业', isChecked: true, prop: 'ProductFactory' },
          { label: '储存', isChecked: true, prop: 'StorageConditionName' },
          // 以上为默认展示的字段
          { label: '物料类型', isChecked: false, prop: 'MaterialTypeName' },
          { label: '注册证失效期', isChecked: false, prop: 'RegisterEndFormat' },
          { label: '序号', isChecked: false, prop: 'ROWNUM' },
          { label: '发票号码', isChecked: false, prop: 'InvoiceNo' },
          { label: '订购数量', isChecked: false, prop: 'OrderNumFormat' },
          { label: '物料子类型', isChecked: false, prop: 'MaterialSubTypeName' },
          { label: '批次', isChecked: false, prop: 'LotNo' },
          { label: '交货单号', isChecked: false, prop: 'DeliveryID' },
          { label: '使用科室', isChecked: false, prop: 'SimsSectionName' },
          { label: '订单明细备注', isChecked: false, prop: 'ODIRemark' },
          { label: '交货单明细时间', isChecked: false, prop: 'WriteTimeFormat' }

        ],
        foot_table: [
          { label: '备注', isChecked: true, prop: 'OrderRemark' },
          { label: '总金额', isChecked: true, prop: 'SumSapKWERTFormat' }
        ],
        footer_desc: { label: '我是第二个描述的数据', isChecked: false },
        footer: [
          { label: '发票号', prop: '', isChecked: true, value: '', width: 20 },
          { label: '制单', prop: '', isChecked: true, value: '', width: 20 },
          { label: '货物签收', prop: '', isChecked: true, value: '', width: 20 },
          { label: '发票签收', prop: '', isChecked: true, value: '', width: 20 },
          { label: '货票签收', prop: '', isChecked: true, value: '', width: 20 },
          { label: '到货温度', prop: '', isChecked: true, value: '', width: 20 },
          { label: '签收日期', prop: '', isChecked: true, value: '', width: 20 },
          { label: '签收日期', prop: '', isChecked: true, value: '', width: 20 },
          { label: '签收日期', prop: '', isChecked: true, value: '', width: 20 }

        ]
      }
    },
    setPrintData(newDate) {
      console.log('处理数据', newDate)
      // 处理头部信息
      for (let i = 0; i < this.info.titleArr.length; i++) {
        for (let j = 0; j < newDate.titleArr.length; j++) {
          if (this.info.titleArr[i].prop === newDate.titleArr[j].prop) {
            Object.keys(this.info.titleArr[i]).map(item => {
              this.info.titleArr[i][item] = newDate.titleArr[j][item]
            })
            // this.$set(this.info.titleArr[i], i, newDate.titleArr[j])
          }
        }
      }
      // 处理表头
      for (let i = 0; i < this.info.header.length; i++) {
        for (let j = 0; j < newDate.header.length; j++) {
          if (this.info.header[i].prop === newDate.header[j].prop) {
            Object.keys(this.info.header[i]).map(item => {
              this.info.header[i][item] = newDate.header[j][item]
            })
          }
        }
      }
      this.info.header.sort((a, b) => a.sort - b.sort) // 重新排序
      // 处理第一个自定义描述 header_desc
      this.info.header_desc = newDate.header_desc
      // 处理表格 table
      for (let i = 0; i < this.info.table.length; i++) {
        for (let j = 0; j < newDate.table.length; j++) {
          if (this.info.table[i].prop === newDate.table[j].prop) {
            Object.keys(this.info.table[i]).map(item => {
              this.info.table[i][item] = newDate.table[j][item]
            })
            // this.$set(this.info.table[i], i, newDate.table[j])
          }
        }
      }
      // 处理第一个自定义描述 footer_desc
      this.info.footer_desc = newDate.footer_desc
      // 处理底部表头 footer
      this.info.footer = newDate.footer
      console.log('info', this.info)
      // this.info = obj
      this.printSaleOrderVisible = !this.printSaleOrderVisible
    },
    // 打印交货单PRO
    openprintDelivery_(_data) {
      this.row.SellCompanyID = this.$parent.$refs.orderHeader.orderHeadInfo.SellCompanyID
      this.row.SendCompanyID = this.$parent.$refs.orderHeader.orderHeadInfo.SendCompanyID
      console.log(_data)
      console.log(this.$parent.$refs.orderHeader.orderHeadInfo)
      this.initTemaplateData()
      const form = {
        DeliveryID: _data.DeliveryID,
        CustomerID: this.$parent.$refs.orderHeader.orderHeadInfo.SendCompanyID,
        CompanyID: this.$parent.$refs.orderHeader.orderHeadInfo.SellCompanyID
      }
      this.$api.GetOrderDeliveryPrintInfo(form).then(res => {
        console.log(res)
        if (res.RetCode !== '0') return this.$message.error('错误：' + res.Re)
        this.data_info = res.Message // OrderDeliveryDetailslist
        this.pritInfo_.tableList = Array.isArray(res.Message.OrderDeliveryDetailslist) ? res.Message.OrderDeliveryDetailslist : []
        if (this.data_info.DeliveryReportCustomInfo !== null) {
          const newArrjson = JSON.parse(this.data_info.DeliveryReportCustomInfo.PrintJson)
          // 先排序 头部
          newArrjson.header.map((item, index) => {
            this.$set(item, 'sort', index)
          })
          // console.log('头部排序后的数据', newArrjson)
          // 如果info, 则处理数据
          return this.setPrintData(newArrjson)
        }
        this.printSaleOrderVisible = !this.printSaleOrderVisible
      })
    },
    // 打开打印交货单
    openprintDelivery(_data) {
      const _this = this
      const _orderinfo = _this.$parent.$refs.orderHeader.orderHeadInfo
      const _orderaddress = _this.$parent.$refs.orderHeader.orderaddress
      _this.pritInfo = {
        companyName: _orderinfo.SellCompanyName,
        printType: '销售出库单',
        khName: _orderinfo.CompanyName, // 客户名称
        lxfs: _orderinfo.CreateUserMobile, // 联系方式
        address: _orderaddress.FullAddress,
        fhwd: '', // 发货温度
        fsway: '', // 发送方式
        dhwd: '', // 到货温度
        deliveryNo: _data.DeliveryID, // 交货单号
        fhdate: _data.DeliveryTime, // 发货日期
        remark: '', // 备注
        tableList: [
        ],
        zdr: '', // 制单人
        ckr: '', // 出库人
        fhr: '', // 复核人
        sfpr: '', // 收发票人
        shr: '' // 收货人
      }
      let total = 0
      let totalmoney = 0
      _data.OrderDeliveryDetailslist.forEach((ele) => {
        total = total + parseFloat(ele.Num)
        totalmoney = totalmoney + (ele.Price * ele.Num)
        _this.pritInfo.tableList.push({
          ckname: ele.StockName, // 仓库名称
          invname: ele.MaterialName, // 物料名称
          ychh: ele.ManuPartNum, // 原厂货号
          chmc: '', // 存货名称
          gg: ele.Specification, // 规格型号
          dw: ele.StockUnitN, // 单位
          ph: ele.SapZCHRG, // 批号
          scrq: ele.ProductDate, // 生产日期
          xq: ele.ExpiredDateShow, // 效期
          sl: ele.Num, // 数量
          dj: ele.Price, // 单价
          je: ele.Price * ele.Num, // 金额
          zczh: ele.RegisterNo, // 注册证号
          xkzh: ele.ProductLicenseNo, // 生产厂家许可证号
          sccj: ele.ProductFactory, // 生产厂家
          cc: ele.StorageConditionName // 存储
        })
      })
      // 处理最后一行
      _this.pritInfo.tableList.push({
        ckname: '合计', // 仓库名称
        invname: '', // 物料名称
        ychh: '', // 原厂货号
        chmc: '', // 存货名称
        gg: '', // 规格型号
        dw: '', // 单位
        ph: '', // 批号
        scrq: '', // 生产日期
        xq: '', // 效期
        sl: total, // 数量
        dj: null, // 单价
        je: totalmoney, // 金额
        zczh: '', // 注册证号
        xkzh: '', // 生产厂家许可证号
        sccj: '', // 生产厂家
        cc: '' // 存储
      })
      this.printDeliveryVisible = true
    },
    printJStest() {
      printJS({
        printable: 'printDeliveryDom',
        type: 'html',
        scanStyles: false,
        style: '.el-table__body-wrapper table tr td { text-align: center; } .el-table__body-wrapper table{border-collapse:collapse;width:100% !important;} .el-table__body-wrapper table td { border:1px solid #EBEEF5; text-align: center}' // 表格样式
      })
    },
    // 打印交货单
    printDelivery() {
      const html = document.querySelector('#' + 'printDeliveryDom').innerHTML
      console.log(html)
      // 新建一个 DOM
      const div = document.createElement('div')
      const printDOMID = 'printDOMElement'
      div.id = printDOMID
      div.innerHTML = html
      document.querySelector('body').appendChild(div)
      // 删除最后一项
      const dom_ = document.getElementById('printDOMElement')
      const tr_arr = document.getElementsByClassName('mid_info')
      const header_arr = document.getElementsByClassName('header')
      // const remake_view = document.getElementById('remake_view')
      const empty = document.getElementsByClassName('empty')
      // const btn = document.getElementById('btn')
      // btn.parentNode.removeChild(btn)
      dom_.style.padding = '10px'
      for (const i in tr_arr) {
        console.log(tr_arr[i].style)
        if (tr_arr[i].style) {
          tr_arr[i].style.display = 'flex'
          tr_arr[i].style.justifyContent = 'space-between'
          tr_arr[i].style.marginTop = '10px'
        }
      }
      for (const i in empty) {
        console.log(empty[i].style)
        if (empty[i].style) {
          empty[i].style.marginTop = '20px'
        }
      }
      for (const i in header_arr) {
        console.log(tr_arr[i].style)
        if (header_arr[i].style) {
          // header_arr[i].style.width = '100%'
          header_arr[i].style.textAlign = 'center'
          header_arr[i].style.weight = 'blod'
        }
      }
      // 提取第一个表格的内容 即表头
      const ths = div.querySelectorAll('.el-table__header-wrapper th')
      const ThsTextArry = []
      for (let i = 0, len = ths.length; i < len; i++) {
        if (ths[i].innerText && ths[i].innerText.length > 0) {
          ThsTextArry.push(ths[i].innerText)
        }
      }
      // 删除多余的表头
      div.querySelector('.hidden-columns').remove()
      // 第一个表格的内容提取出来后已经没用了 删掉
      div.querySelector('.el-table__header-wrapper').remove()
      // 将第一个表格的内容插入到第二个表格
      let newHTML = '<tr style="text-align: center;">'
      for (let i = 0, len = ThsTextArry.length; i < len; i++) {
        newHTML += '<td style="text-align: center;">' + ThsTextArry[i] + '</td>'
      }
      newHTML += '</tr>'
      div.querySelector('.el-table__body-wrapper table').insertAdjacentHTML('afterbegin', newHTML)
      //  .el-table__body-wrapper table td{ border:1px solid #EBEEF5; text-align: center;};
      // 将新的 DIV 添加到页面 打印后再删掉
      // document.querySelector('body').appendChild(div) .el-table__body-wrapper table{border-collapse:collapse};
      printJS({
        printable: 'printDOMElement',
        type: 'html',
        scanStyles: false,
        style: '.el-table__body-wrapper table tr td { text-align: center; } .el-table__body-wrapper table{border-collapse:collapse;width:100% !important;} .el-table__body-wrapper table td { border:1px solid #EBEEF5; text-align: center}' // 表格样式
      })
      div.remove()
      this.printDeliveryVisible = false
    },
    group(array, subGroupLength) {
      let index = 0
      const newArray = []
      while (index < array.length) {
        newArray.push(array.slice(index, index += subGroupLength))
      }
      return newArray
    }
  }
}
</script>
<style scoped lang='scss'>
@import '@/style/index.scss';
@import '@/style/search.scss';
@import '@/style/main.scss';
@import '@/style/order.scss';
</style>
<style>
.el-card__header {
  background-color: #f1f9ff;
}
.table_footer {
   height:25px;
   border:1px solid #EBEEF5;
   border-top:none;
   line-height:25px;
}
.table_footer_1 {
   border:1px solid #EBEEF5;
}
.table_footer_2 {
   height:25px;
   line-height:25px;
   border-left:1px solid #EBEEF5;
   border-right:1px solid #EBEEF5;
   border-bottom:1px solid #EBEEF5;
}
</style>
