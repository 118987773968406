import { get, post } from './request'

export default {
  // 获取人员列表
  GetUserList: data => post('/user/GetUserList', data),
  // 获取部门列表
  GetDeptList: data => get('/dept/GetDeptList', data),
  // 修改人员部门职位
  UpdateDeptPosition: data => post('/dept/UpdateDeptPosition', data),
  // 获取角色列表
  GetRoleList: data => get('/role/GetRoleList', data),
  // 新增角色
  SubmitRole: data => post('/role/SubmitRole', data),
  // 新增部门
  SubmitDept: data => post('/dept/SubmitDept', data),
  // 新增人员
  SubmitUser: data => post('/user/SubmitUser', data),
  // 根据id 获取角色
  GetRoleById: data => get('/role/GetRoleById', data),
  // 根据id 获取人员
  GetUserByUserId: data => get('/user/GetUserByUserId', data),
  // 根据id 获取部门
  GetDeptById: data => get('/dept/GetDeptById', data),
  // 删除角色
  DeletePermissionRoleInfo: data => post('/role/DeletePermissionRoleInfo', data),
  // 删除部门
  DeleteDept: data => post('/dept/DeleteDept', data),
  // 选择人员时，成员列表
  SelectUserQueryListOfDept: data => get('/user/SelectUserQueryListOfDept', data),
  // 选择人员时，角色添加人员
  PermissionRoleUserSave: data => post('/role/PermissionRoleUserSave', data),
  // 选择人员时，部门加人员
  PermissionDeptUserSave: data => post('/dept/PermissionDeptUserSave', data),
  // 人员删除/ 禁用 / 启用 接口
  UpdateUserStatus: data => post('/user/UpdateUserStatus', data),
  // 清空通讯录接口
  EmptyAddressBook: data => post('/user/EmptyAddressBook', data),
  // 角色 --批量移除选中用户
  PermissionRoleUserDelete: data => post('/role/PermissionRoleUserDelete', data),
  // 角色 --全部移除选中用户
  PermissionRoleUserDeleteAll: data => post('/role/PermissionRoleUserDeleteAll', data),
  // 部门 --批量移除选中用户
  PermissionDeptUserDelete: data => post('/dept/PermissionDeptUserDelete', data),
  // 部门 --全部移除选中用户
  PermissionDeptUserDeleteAll: data => post('/dept/PermissionDeptUserDeleteAll', data),
  // 获取当前登录人
  GetUserInfo: data => get('/GetUserInfo', data)
}
