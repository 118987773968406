<template>
  <div class="orderDetailsHeader">
    <!-- <PageHeader :border="false" title="订单信息" >
            <el-button @click="$router.push('orderList')" type="primary" size="mini" round>返回</el-button>
        </PageHeader> -->
    <div class="p-lr-20 p-tb-20">
      <el-card v-loading="orderheaderLoading" class="box-card">
        <div slot="header" class="clearfix">
          <span class="cardTitle"><i class="el-icon-document" /> 订单信息</span>
          <el-button class="ModelBttton1-white-24" type="primary" style="float: right;" round @click="$router.push({ name: 'orderList1', isCache:6 })">返回</el-button>
          <span v-if="Ishandler === '999'">
            <el-button v-if="orderHeadInfo.IsMyFavoriteOrder !== 1" class="ModelBttton1-white-24 m-right-10" style="float: right;" size="mini" round @click="addFavorate">
              <i class="el-icon-star-off  m-right-10" />添加到我的订单</el-button>
            <el-button v-if="orderHeadInfo.IsMyFavoriteOrder === 1" class="ModelBttton1-white-24 m-right-10" style="float: right;" size="mini" round @click="addFavorate">
              <i class="el-icon-star-on  m-right-10" />取消为我的订单</el-button>
          </span>
        </div>
        <div class="orderHeader flex j-between">
          <div class="hederleft flex j-between" style="flex:1">
            <div>订单编号：<span class="p-left-10">{{ orderHeadInfo.OrderID }}</span><span class="p-left-10" v-if="orderHeadInfo.POID">({{ orderHeadInfo.POID }})</span></div>
            <div>订单创建时间：<span class="p-left-10">{{ orderHeadInfo.WriteTime }}</span></div>
            <div>订单来源：<span class="p-left-10">{{ orderHeadInfo.OrderSourceString }}</span></div>
            <div>状态：<span class="p-left-10">{{ orderHeadInfo.OrderStatusString }}</span></div>
          </div>
          <div class="headerright p-lr-10">
            <el-button class="ModelBttton1-white-24" plain @click="openprintorder">打印订单</el-button>
          </div>
        </div>
        <div class="steps OrderTail p-top-0 m-tb-5">
          <el-steps :active="orderStep" align-center>
            <el-step title="提交订单" icon="el-icon-success">
              <template slot="description">
                <el-popover
                  placement="top"
                  trigger="hover"
                >
                  <el-table v-loading="submitLogListLoading" :data="submitLogList" :border="false" style="height:200px;overflow:auto">
                    <el-table-column prop="Writer" label="操作人" />
                    <el-table-column prop="WriteDate" label="操作时间" />
                    <el-table-column prop="Remark" label="操作内容" />
                  </el-table>
                  <el-button slot="reference" style="height:21px;line-height:21px" type="primary" size="mini" round>查看详情</el-button>
                </el-popover>
              </template>
            </el-step>
            <el-step title="交货单处理" :icon="getStatusIcon(deliverStatus)">
              <template slot="description">
                <el-popover
                  placement="top"
                  trigger="hover"
                >
                  <el-table v-loading="deliveryLogListLoading" :data="deliveryLogList" :border="false" style="height:200px;overflow:auto">
                    <el-table-column prop="Writer" label="操作人" />
                    <el-table-column prop="WriteDate" label="操作时间" />
                    <el-table-column prop="Remark" label="操作内容" />
                  </el-table>
                  <el-button slot="reference" style="height:20px;line-height:20px" :disabled="orderStep < 1" type="primary" size="mini" round>查看详情</el-button>
                </el-popover>
              </template>
            </el-step>
            <el-step title="仓库发货" :icon="getStatusIcon(sendStatus)">
              <template slot="description">
                <el-popover
                  placement="top"
                  trigger="hover"
                >
                  <el-table v-loading="sendLogListLoading" :data="sendLogList" :border="false" style="height:200px;overflow:auto">
                    <el-table-column prop="Writer" label="操作人" />
                    <el-table-column prop="WriteDate" label="操作时间" />
                    <el-table-column prop="Remark" label="操作内容" />
                  </el-table>
                  <el-button slot="reference" style="height:20px;line-height:20px" type="primary" size="mini" :disabled="orderStep < 2" round>查看详情</el-button>
                </el-popover>
              </template>
            </el-step>
            <el-step title="物流运输" :icon="getStatusIcon(transStatus)">
              <template slot="description">
                <el-popover
                  placement="top"
                  trigger="hover"
                >
                  <el-table v-loading="transportLogListLoading" :data="transportLogList" :border="false" style="height:200px;overflow:auto">
                    <el-table-column property="transNo" label="物流单号" />
                    <el-table-column property="WriteTime" label="发货时间" />
                    <el-table-column property="contactUser" label="联系人" />
                  </el-table>
                  <el-button slot="reference" style="height:20px;line-height:20px" type="primary" size="mini" :disabled="orderStep < 3" round>查看详情</el-button>
                </el-popover>
              </template>
            </el-step>
            <el-step title="确认收货" :icon="getStatusIcon(reciveStatus)">
              <template slot="description">
                <el-popover
                  placement="top"
                  trigger="hover"
                >
                  <el-table v-loading="reciveLogListLoading" :data="reciveLogList" :border="false" style="height:200px;overflow:auto">
                    <el-table-column property="reciveNo" label="物流单号" />
                    <el-table-column property="WriteTime" label="收货时间" />
                    <el-table-column property="contactUser" label="联系人" />
                  </el-table>
                  <el-button slot="reference" style="height:20px;line-height:20px" type="primary" size="mini" :disabled="orderStep < 4" round>查看详情</el-button>
                </el-popover>
              </template>
            </el-step>
          </el-steps>
        </div>
        <!-- 买家信息 -->
        <div class="BuyerInfo">
          <div class="buyer">
            <div class="eachitem flex j-between" style="flex:1">
              <div class="eachitem-eachword">创建人：<span>{{ orderHeadInfo.CreateUserName }}</span></div>
              <div class="eachitem-eachword">办公电话：<span>{{ orderHeadInfo.OfficeTel }}</span></div>
              <div class="eachitem-eachword">移动电话：<span>{{ orderHeadInfo.CreateUserMobile }}</span></div>
              <div class="eachitem-eachword">部门科室：<span>{{ orderHeadInfo.SimsSectionName }}
                <el-link v-if="Ishandler === '999'" class="f12" type="primary" :underline="false" @click="opendept">编辑</el-link></span></div>
            </div>
            <div class="eachitem flex j-between" style="flex:1">
              <div class="eachitem-eachword">终端：<span>{{ orderHeadInfo.CompanyName }}</span></div>
              <div class="eachitem-eachword">买方：<span>{{ orderHeadInfo.AgentCompanyName > 0 ?orderHeadInfo.AgentCompanyName : orderHeadInfo.CompanyName }}</span></div>
              <div class="eachitem-eachword">期望到货时间：<span>{{ orderHeadInfo.ExpectedDeliveryDateShow }}</span></div>
              <div class="eachitem-eachword">发票信息：<span>
                {{ orderHeadInfo.InvoiceType === 2 ? '月结' : orderHeadInfo.InvoiceType === 1 ? '随货' : '未设置' }}
              </span></div>
            </div>
            <div class="eachitem flex j-between" style="flex:1">
              <div class="eachitem-eachword2">收货地址：<span>
                <span v-if="orderaddress.TakeSelf !== 1 ">{{ orderaddress.FullAddress }}</span>
                <span v-if="orderaddress.TakeSelf === 1 ">用户自提</span>
                <el-link v-if="Ishandler === '999'" class="f12" type="primary" :underline="false" @click="openAddress(10)">编辑</el-link></span></div>
            </div>
            <div class="eachitem flex j-between" style="flex:1">
              <div class="eachitem-eachword2">寄票地址：<span>
                <span v-if="orderjpaddress.TakeSelf !== 1 ">{{ orderjpaddress.FullAddress }}</span>
                <span v-if="orderjpaddress.TakeSelf === 1 ">用户自提</span>
                <el-link v-if="Ishandler === '999'" class="f12" type="primary" :underline="false" @click="openAddress(20)">编辑</el-link></span></div>
            </div>
            <div class="eachitem flex j-between" style="flex:1">
              <div class="eachitem-eachword2">订单备注：<span>{{ orderHeadInfo.Remark }}<el-link v-if="Ishandler === '999'" class="f12" type="primary" :underline="false" @click="updateOrderRemarkVisibel = true">编辑</el-link></span></div>
            </div>
          </div>
        </div>
      </el-card>
    </div>
    <!-- 修改订单备注 -->
    <el-dialog class="DepartmentBox" title="修改订单备注" :visible.sync="updateOrderRemarkVisibel" width="500px" :close-on-click-modal="false">
      <el-form label-position="right" :model="orderHeadInfo" class="demo-form-inline">
        <el-input
          v-model="orderHeadInfo.Remark"
          type="textarea"
          :rows="4"
          placeholder="请输入订单备注"
        />
      </el-form>
      <div class="p-tb-20 caozuo">
        <el-button @click="updateOrderRemarkVisibel = false">取 消</el-button>
        <el-button type="primary" @click="comfirmUpateOrderRemar">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 选择部门科室 -->
    <el-dialog title="选择部门科室" class="DepartmentBox" center :visible.sync="seletUserVisible" width="300px" :close-on-click-modal="false">
      <div v-loading="deptloading" class="rolebody">
        <el-tabs v-model="selectUseractiveName">
          <el-tab-pane label="部门" name="dept" />
        </el-tabs>
        <div v-if="selectUseractiveName === 'dept'" class="selectuser">
          <el-tree
            ref="tree"
            :data="deptdata"
            node-key="value"
            highlight-current
          >
            <span slot-scope="{ node, data }" style="width:100%">
              <span class="roleSpan"><div class="deptSpandiv"><i class="el-icon-user" /></div></span>
              <span class="deptnamespan">
                {{ node.label }}</span>
              <span class="deptcountspan"><el-checkbox v-model="data.ischecked" @change="checkedDept(data.ischecked,data)" /></span>
            </span>
          </el-tree>
        </div>
      </div>
      <div slot="footer" class="caozuo">
        <el-button @click="seletUserVisible = false">取 消</el-button>
        <el-button type="primary" @click="doconnDept">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 选择地址 -->
    <el-dialog class="DepartmentBox" title="选择地址" center :visible.sync="seletaddressVisible" width="500px" :close-on-click-modal="false">
      <!-- <el-button  size="small" type="primary" round plain>新建地址</el-button> -->
      <div class="m-bottom-20" style="height:200px;overflow: auto;">
        <div v-for="(item, index) in addressList" :key="index" class="seladdress flex j-between">
          <div class="addressleft flex j-between" style="flex:1">
            <div><span>
              <!-- {{item.province}} {{item.city}} {{item.district}} {{item.address}} {{item.deprname}}
                    {{item.username}} {{item.moblie}} -->
              {{ item.FullAddress }}
            </span></div>
          </div>
          <div class="addressright p-lr-10">
            <!-- <el-button  size="mini" round plain>编辑</el-button> -->
            <!-- <el-button  size="mini" round plain>删除</el-button>
                  <el-button  size="mini" v-if="!item.isdefault" round plain>设为默认地址</el-button> -->
            <!-- v-if="item.Status === 1" -->
            <el-button class="ModelBttton1-white-24" type="primary" round plain @click="confirmCheckedAddress(item)">选择</el-button>
            <!-- <span style="color:red" class="m-left-10" v-if="item.Status === 0">{{item.SellStatusString}}</span> -->
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- 打印订单 -->
    <el-dialog title="打印订单预览" center :visible.sync="printorderVisible" width="60%" :close-on-click-modal="false">
      <div id="printorderDom" class="pritInfo p-lr-10">
        <div class="f14 right" style="text-align: right;">文件编号：{{ pritInfo.fileNo }}</div>
        <div class="empty" />
        <div class="dealdivider m-tb-20" style="height:1px;border-top:1px solid #dcdfe6" />
        <div class="empty" />
        <div class="f14 flex left">订单编号：{{ pritInfo.OrderID }}</div>
        <div class="header weight f16 flex j-center m-top-10">{{ pritInfo.companyName }}</div>
        <div class="mid_info f12 flex j-between m-top-10">
          <div style="min-width:300px">订货单位：{{ pritInfo.dhdw }}</div>
        </div>
        <div class="mid_info f12 flex j-between m-top-10">
          <div style="min-width:300px">订货人：{{ pritInfo.dhr }}</div>
          <div style="min-width:300px">联系电话：{{ pritInfo.lxdg }}</div>
        </div>
        <div class="mid_info f12 flex j-between m-top-10">
          <div style="min-width:300px">收货地址：{{ pritInfo.shdx }}</div>
        </div>
        <div class="mid_info f12 flex j-between m-top-10">
          <div style="min-width:300px">收货人：{{ pritInfo.shr }}</div>
          <div style="min-width:300px">收货电话：{{ pritInfo.shdh }}</div>
        </div>
        <div class="mid_info f12 flex j-between m-top-10">
          <div style="min-width:300px">收票地址：{{ pritInfo.spdx }}</div>
        </div>
        <div class="mid_info f12 flex j-between m-top-10">
          <div style="min-width:300px">收票人：{{ pritInfo.spr }}</div>
          <div style="min-width:300px">收票电话：{{ pritInfo.spdh }}</div>
        </div>
        <div class="mid_info f12 flex j-between m-top-10">
          <div style="min-width:300px">终端：{{ pritInfo.sendcompany }}</div>
        </div>
        <div class="mid_info f12 flex j-between m-top-10">
          <div style="min-width:300px">订单备注：{{ pritInfo.remark }}</div>
        </div>
        <div class="empty" />
        <div class="mid_info f12 flex j-between m-top-10">
          <div style="min-width:300px">订货日期：{{ pritInfo.dhrq }}</div>
        </div>
        <div class="m-tb-10" style="width: 100%">
          <el-table
            :header-cell-style="{background:'rgb(247,248,252)'}"
            :data="pritInfo.tableList"
            :span-method="colspan"
            border
            style="width: 100%"
          >
            <el-table-column prop="hh" label="货号" />
            <el-table-column prop="cpm" label="产品名" />
            <el-table-column prop="gg" label="规格" />
            <el-table-column prop="sl" label="数量" />
            <el-table-column prop="xydj" label="协议单价" />
            <el-table-column prop="zhdj" label="折后单价" />
            <el-table-column prop="yjze" label="原价总额" />
            <el-table-column prop="yfze" label="应付总额" />
          </el-table>
        </div>
        <div class="empty" />
        <div class="mid_info f12 flex j-between m-top-10">
          <div style="min-width:300px;color:red">PS：请在签名后将订单回传，并在付款后将付款凭证传真至本公司，我们将在收到凭证后进行发货，谢谢配合</div>
        </div>
        <div class="empty" />
        <div class="empty" />
        <div class="mid_info f12 flex j-between m-top-10">
          <div style="min-width:300px">订货单位负责签字（盖章）：</div>
        </div>
        <div class="empty" />
        <div class="empty" />
        <div class="dealdivider m-tb-20" style="height:1px;border-top:1px solid #dcdfe6" />
        <div class="empty" />
        <div class="mid_info f12 flex j-between m-top-10">
          <div style="min-width:300px;font-size:15px" class="bigfontsize">以下由上海润达医疗科技股份有限公司填写：</div>
        </div>
        <div class="mid_info f12 flex j-between m-top-10">
          <div style="min-width:300px">发货日期：</div>
          <div style="min-width:300px">发票编号：</div>
        </div>
        <div class="mid_info f12 flex j-between m-top-10">
          <div style="min-width:300px">运输单位：</div>
          <div style="min-width:300px">运单号：</div>
        </div>
        <div class="mid_info f12 flex j-between m-top-10">
          <div style="min-width:300px">联系电话：</div>
        </div>
        <div class="mid_info f12 flex j-between m-top-10">
          <div style="min-width:300px">商务负责：</div>
        </div>
        <div class="mid_info f12 flex j-between m-top-10">
          <div style="min-width:300px">商务负责：</div>
          <div style="min-width:300px">销售负责审核：</div>
        </div>
        <div class="mid_info f12 flex j-between m-top-10">
          <div style="min-width:500px">附件：
            <el-checkbox-group v-model="checkList" class="nowrap" style="display:inline">
              <el-checkbox label="增票" />
              <el-checkbox label="软件" />
              <el-checkbox label="冰袋" />
            </el-checkbox-group>
          </div>
        </div>
      </div>
      <div slot="footer" class="caozuo p-top-20 t-right t_line_s m-top-10">
        <el-button @click="printorderVisible = false">取 消</el-button>
        <el-button type="primary" @click="printorder">确认打印</el-button>
      </div>
    </el-dialog>
    <!-- 修改收货地址添加备注 -->
    <el-dialog class="DepartmentBox" title="修改地址添加备注" :visible.sync="editaddressReasonvisible" width="300px" :close-on-click-modal="false">
      <el-form label-position="right"  size="mini" class="demo-form-inline">
        <el-input
          v-model="editaddressReason"
          type="textarea"
          :rows="3"
        />
      </el-form>
      <div class="p-tb-20 caozuo">
        <el-button @click="editaddressReasonvisible = false">取 消</el-button>
        <el-button type="primary" @click="validchangeaddress">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 修改部门添加备注 -->
    <el-dialog title="修改部门添加备注" :visible.sync="editdeptReasonvisible" width="400px" :close-on-click-modal="false">
      <el-form label-position="right" label-width="20%" :model="editdeptReason" size="mini" class="demo-form-inline p-lr-20">
        <el-input
          v-model="editdeptReason"
          type="textarea"
          :rows="3"
        />
      </el-form>
      <div class="caozuo t-right p-top-20 p-right-20">
        <el-button @click="editdeptReasonvisible = false">取 消</el-button>
        <el-button type="primary" @click="dealOrderChangeDepe">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import orderdetailHeader from '@/minxin/orderMananger/orderdetailHeader.js'
export default orderdetailHeader
</script>
<style scoped lang='scss'>
@import '@/style/iconfont.scss';
@import '@/style/index.scss';
@import '@/style/search.scss';
@import '@/style/main.scss';
@import '@/style/order.scss';
@import '@/style/selectUser.scss';
</style>
<style>
.el-step__icon-inner[class*=el-icon]:not(.is-status) {
  font-size: 20px;
}
.steps .el-button--mini {
  padding: 0px 15px;
}
.el-step__title {
  font-size: 12px;
}
.el-dialog--center .el-dialog__body {
  padding: 25px 25px 0px;
}
.cardTitle .el-button--mini {
  padding: 7px 15px;
}
.el-card__header {
  background-color: #f1f9ff;
}
</style>
