<template>
  <div class="orderDetailsMaterial">
    <div class="p-lr-20 p-bottom-20">
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span class="cardTitle"><i class="el-icon-wallet" /> 销售订单</span>
          <el-button class="ModelBttton1-white-24" type="primary" style="float:right;" round plain @click="getSaleList">刷新</el-button>
        </div>
        <div v-for="(item, index) in orderList" :key="index" v-loading="saOrderloading" class="saleeachitem">
          <div class="orderHeader j-between">
            <div class="salelistleft">
              <ul>
                <li><label class="Name">销售订单号：</label><span>{{ item.OrderSapID }}</span></li>
                <li><label class="Name">采购申请：</label><span>{{ item.SAPPR }}</span></li>
                <li><label class="Name">上传人：</label><span>{{ item.CreateUserName }}</span></li>
                <li><label class="Name">上传时间：</label><span>{{ item.WriteTime }}</span></li>
                <li><label class="Name">销售负责人：</label><span>{{ item.CreateUserName }}</span></li>
              </ul>
            </div>
            <div class="salelistright">
              <el-button type="primary" size="mini" plain @click="openprintSaleOrder(item)">打印销售凭证</el-button>
              <el-button v-if="Ishandler === '999'" type="primary" size="mini" plain @click="confirmDeleteSales">删除选中</el-button>
              <el-button v-if="(item.SAPPR !== null && item.SAPPR.length > 0) && (item.OrderQty > item.DeliveredNum) && Ishandler === '999'" type="primary" size="mini" @click="openCretateDelivery(item)">生成交货单</el-button>
            </div>
          </div>
          <div class="j-between p-top-10">
            <el-table
              ref="salesdetails"
              :header-cell-style="{background:'rgb(247,248,252)'}"
              :data="item.ListOrderSapDetailsInfo"
              tooltip-effect="dark"
              show-header
              border
              @selection-change="saOrderListhandleSelectionChange($event, item, index)"
            >
              <af-table-column
                fixed="left"
                type="selection"
                width="40"
              />
              <af-table-column
                prop="InvCode"
                label="物料编号"
                show-overflow-tooltip
              />
              <af-table-column
                prop="ManuPartNum"
                label="原厂编号"
                show-overflow-tooltip
              />
              <af-table-column
                prop="InvName"
                label="物料名称"
                show-overflow-tooltip
              />
              <af-table-column
                prop="Specification"
                label="规格"
                show-overflow-tooltip
              />
              <af-table-column
                prop="BrandName"
                label="品牌"
                show-overflow-tooltip
              />
              <!-- <af-table-column
                        prop="LotNum"
                        label="批号"
                        show-overflow-tooltip
                    />
                    <af-table-column
                        prop="ExpiredDate"
                        label="效期"
                        show-overflow-tooltip
                    /> -->
              <!-- <af-table-column
                        prop="HasStock"
                        label="是否有库存"
                        show-overflow-tooltip
                    >
                    <template slot-scope="scope">
                      <span>{{(scope.row.StockNum && scope.row.StockNum > 0)? '是': '否'}}</span>
                    </template>
                    </af-table-column> -->
              <af-table-column
                prop="OrderQty"
                label="数量"
              >
                <template slot-scope="scope">
                  <el-link :disabled="scope.row.OrderQty === scope.row.DeliveredNum" :underline="false" type="primary" @click="openUpdateNum(item,scope.row)">
                    {{ scope.row.OrderQty }}
                  </el-link>
                </template>
              </af-table-column>
              <af-table-column
                prop="DeliveredNum"
                label="已交货数量"
                show-overflow-tooltip
              />
            </el-table>
          </div>
        </div>
      </el-card>
    </div>
    <!-- 修改销售单订单数量 -->
    <el-dialog class="DepartmentBox" title="修改销售单订单数量" :visible.sync="updateNumVisbel" width="400px" :close-on-click-modal="false">
      <div class="orderstatus">
        <div>销售订单编号：{{ updateNumitem.OrderSapID }}</div>
        <!-- <div class="eachupdate-orderstatus">订单状态：<span>{{ orderInfo.OrderStatusString }}</span></div><span /> -->
        <div>创建时间：{{ updateNumitem.WriteTime }}</div>
      </div>
      <div v-for="(item, index) in orderUpdataNumList" :key="index" class="orderstatuslist">
        <div class="eachupdatelist"><label class="Name">物料编号：</label><span>{{ item.InvCode }}</span></div>
        <div class="eachupdatelist"><label class="Name">物料名称：</label><span>{{ item.MaterialName }}</span></div>
        <div class="eachupdatelist"><label class="Name">销售明细数量：</label><span>{{ item.Num }}</span></div>
        <div class="eachupdatelist"><label class="Name">已交货数量：</label><span>{{ item.NumSend }}</span></div>
        <div class="eachupdatelist"><label class="Name textcolorblue">新数量：</label><el-input v-model="item.newNum" class="NewNumb" /></div>
        <div class="eachupdatelist"><label class="Name textcolorblue">修改理由：</label><el-input v-model="updateNumReason" class="xiugai" /></div>
      </div>
      <div slot="footer" class="caozuo t-right">
        <el-button @click="updateNumVisbel = false">取 消</el-button>
        <el-button type="primary" @click="confirmUpdateNum">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 销售单创建交货单 -->
    <el-dialog class="DepartmentBox" title="创建交货单" :visible.sync="createDeliveryVisbel" width="80%" :close-on-click-modal="false">
      <el-table
        v-loading="createDeliveryloading"
        :header-cell-style="{background:'rgb(247,248,252)'}"
        :data="createDeliveryList"
        tooltip-effect="dark"
        show-header
        border
        :span-method="dealspan_SalesForDelivery"
      >
        <el-table-column
          label="序号"
        >
          <template slot-scope="scope">
            <span>{{ scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="InvCode"
          label="物料编号"
        />
        <el-table-column
          prop="InvName"
          label="物料名称"
          show-overflow-tooltip
        />
        <el-table-column
          prop="Specification"
          label="规格"
          show-overflow-tooltip
        />
        <el-table-column
          prop="BrandName"
          label="品牌"
          show-overflow-tooltip
        />
        <el-table-column
          prop="MRCStockUnitN"
          label="单位"
          show-overflow-tooltip
        />
        <el-table-column
          prop="MaterialStorageConditionName"
          label="存储条件"
          show-overflow-tooltip
        />
        <el-table-column
          prop="LotNum"
          label="批号"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <el-link :underline="false" type="primary" @click="openupdatestock(scope.$index,scope.row)">
              {{ (scope.row.stockid > 0 )? scope.row.LotNum: '选择批号' }}
            </el-link>
          </template>
        </el-table-column>
        <el-table-column
          prop="ExpiredDate"
          label="效期"
          show-overflow-tooltip
        />
        <el-table-column
          prop="Location"
          label="所属仓库"
          show-overflow-tooltip
        />
        <el-table-column
          prop="StockNumV2"
          label="库存"
          show-overflow-tooltip
        />
        <el-table-column
          prop="num"
          label="数量"
          show-overflow-tooltip
        />
        <el-table-column
          prop="CurrentSendNum"
          label="发货数量"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <el-input v-model="scope.row.CurrentSendNum" @blur="checkedNum(scope.row)" />
          </template>
        </el-table-column>
        <el-table-column
          prop="NumSend"
          label="已交货数量"
          show-overflow-tooltip
        />
        <el-table-column
          prop="UnitPrice"
          label="单价"
          show-overflow-tooltip
        />
        <el-table-column
          prop=""
          label="小计"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{ scope.row.UnitPrice * scope.row.num }}
          </template>
        </el-table-column>
        <el-table-column
          prop=""
          label="操作"
          show-overflow-tooltip
          fixed="right"
        >
          <template slot-scope="scope">
            <el-link v-if="scope.row.isOrign === 1" :underline="false" type="primary" @click="DoPart(scope.row, scope.$index)">
              拆行
            </el-link>
            <el-link v-else :underline="false" type="primary" @click="DoPartDelet(scope.$index)">
              移除
            </el-link>
          </template>
        </el-table-column>
      </el-table>
      <div class="nowrap m-tb-20 flex">
        <label class="Name">创建理由：</label><el-input v-model="createDeliveryReason" size="small" />
      </div>
      <div slot="footer" class="caozuo">
        <el-button @click="createDeliveryVisbel = false">关 闭</el-button>
        <el-button type="primary" @click="confirmCreateDelivery">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 选择批号 -->
    <el-dialog title="选择库存" :visible.sync="updatestockVisbel" width="1000px" :close-on-click-modal="false">
      <el-table
        v-loading="stockListloading"
        :header-cell-style="{background:'rgb(247,248,252)'}"
        :data="stockList"
        tooltip-effect="dark"
        show-header
        border
      >
        <af-table-column
          label="序号"
          width="50"
        >
          <template slot-scope="scope">
            <span>{{ scope.$index+1 }}</span>
          </template>
        </af-table-column>
        <af-table-column
          prop="INDATE"
          label="入库时间"
        >
          <template slot-scope="scope">
            <span>{{ (scope.row.INDATE && scope.row.INDATE.length > 0) ? $minCommon.formatDate(new Date(scope.row.INDATE),'yyyy-MM-dd') : '' }}</span>
          </template>
        </af-table-column>
        <af-table-column
          prop="LotNum"
          label="批号"
          show-overflow-tooltip
        />
        <af-table-column
          prop="LotNo"
          label="批次号"
          show-overflow-tooltip
        />
        <af-table-column
          prop="ExpiredDate"
          label="效期"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span>{{ (scope.row.ExpiredDate && scope.row.ExpiredDate.length > 0) ? $minCommon.formatDate(new Date(scope.row.ExpiredDate),'yyyy-MM-dd') : '' }}</span>
          </template>
        </af-table-column>
        <af-table-column
          prop="StockNum"
          label="库存量"
          show-overflow-tooltip
        />
        <af-table-column
          prop="LFIMG"
          label="冻结"
          show-overflow-tooltip
        />
        <af-table-column
          prop="StockCode"
          label="所属仓库"
          show-overflow-tooltip
        />
        <af-table-column
          prop="ProductDate"
          label="生产日期"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span>{{ (scope.row.ProductDate && scope.row.ProductDate.length > 0) ? $minCommon.formatDate(new Date(scope.row.ProductDate),'yyyy-MM-dd') : '' }}</span>
          </template>
        </af-table-column>
        <af-table-column
          prop=""
          label="操作"
          show-overflow-tooltip
          width="70"
        >
          <template slot-scope="scope">
            <el-button v-if="scope.row.StockNum > 0" class="ModelBttton1-white-24" type="primary" round plain @click="confirmUseStock(scope.row)">使用</el-button>
            <span v-if="scope.row.StockNum < 1" class="hui">无可用</span>
          </template>
        </af-table-column>
      </el-table>
    </el-dialog>
    <!-- 打印销售凭证 -->
    <el-dialog class="DepartmentBox" title="打印销售凭证" center :visible.sync="printSaleOrderVisible" width="80%" :close-on-click-modal="false">
      <div id="printsaleDom" class="pritInfo">
        <div class="header weight f14 flex j-center">{{ pritInfo.companyName }}</div>
        <div class="header weight f14 flex j-center">{{ pritInfo.printType }}</div>
        <div class="empty" />
        <div class="m-tb-20" style="width: 100%">
          <el-table
            :data="pritInfo.tableList1"
            border
            :show-header="false"
            :span-method="colSapn"
            style="width: 100%"
          >
            <af-table-column prop="companyType" label="" />
            <af-table-column prop="companyName" label="" />
            <af-table-column prop="colspan1" label="" />
            <af-table-column prop="colspan2" label="" />
            <af-table-column prop="showtype1" label="" />
            <af-table-column prop="orderid" label="" />
            <af-table-column prop="colspan3" label="" />
            <af-table-column prop="showtype2" label="" />
            <af-table-column prop="colspan4" label="" />
            <af-table-column prop="ordertime" label="" />
            <af-table-column prop="colspan5" label="" />
          </el-table>
          <el-table
            id="pritInfotablelist"
            :data="pritInfo.tableList"
            border
            class="xiaoshoudan"
          >
            <af-table-column prop="xsdh" label="销售单号" />
            <af-table-column prop="invcode" label="物料编号" />
            <af-table-column prop="ychh" label="原厂货号" />
            <af-table-column prop="invname" label="物料名称" />
            <af-table-column prop="brand" label="品牌" />
            <af-table-column prop="gg" label="规格" />
            <af-table-column prop="dw" label="单位" />
            <af-table-column prop="sl" label="数量" />
            <af-table-column prop="ph" label="批号" />
            <af-table-column prop="xq" label="效期" />
            <af-table-column prop="lsh" label="流水号" />
          </el-table>
          <el-table
            :data="pritInfo.tableList2"
            border
            :show-header="false"
            :span-method="colspan2"
            style="width: 100%"
          >
            <af-table-column prop="typename" label="" />
            <af-table-column prop="value" label="" />
            <af-table-column prop="colspan1" label="" />
            <af-table-column prop="colspan2" label="" />
            <af-table-column prop="colspan3" label="" />
            <af-table-column prop="colspan4" label="" />
            <af-table-column prop="colspan5" label="" />
            <af-table-column prop="colspan6" label="" />
            <af-table-column prop="colspan7" label="" />
            <af-table-column prop="colspan8" label="" />
            <af-table-column prop="colspan9" label="" />
          </el-table>
        </div>
      </div>
      <div slot="footer" class="caozuo t-right">
        <el-button @click="printSaleOrderVisible = false">取 消</el-button>
        <el-button type="primary" @click="printSaleOrder">确认打印</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import salesOrder from '@/minxin/orderMananger/salesOrder.js'
export default salesOrder
</script>
<style scoped lang='scss'>
@import '@/style/index.scss';
@import '@/style/search.scss';
@import '@/style/main.scss';
@import '@/style/order.scss';
</style>
<style>
.el-card__header {
  background-color: #f1f9ff;
}
</style>
