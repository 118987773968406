// import $orderReq from '@/request/orderApi.js'
import PageHeader from '@/components/PageHeader.vue'
import orderDetailHeader from '@/views/orderMananger/orderdetailHeader.vue' // 订单表头
import PurchaseOrderDetails from '@/views/purchasing-management/purchase-order-details.vue' // 订单表头 采购
import orderdetailMaterialList from '@/views/orderMananger/orderdetailMaterialList.vue' // 物料明细
import salesOrder from '@/views/orderMananger/salesOrder.vue' // 销售订单
import deliveryOrder from '@/views/orderMananger/deliveryOrder.vue' // 交货单
import orderNavigation from '@/views/orderMananger/orderNavigation.vue' // 右侧菜单栏
import $userRequest from '@/request/contactsapi.js'
import $orderReq from '@/request/orderApi.js'
import $comjs from '@/minxin/commonJs.js'
import { regionData, CodeToText } from 'element-china-area-data' // CodeToText
const orderDetails = {
  name: 'orderDetails1',
  components: {
    PageHeader,
    orderDetailHeader,
    orderdetailMaterialList,
    salesOrder,
    deliveryOrder,
    orderNavigation,
    PurchaseOrderDetails
  },
  data() {
    const validaddrestype = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请选择地址用途'))
      } else {
        callback()
      }
    }
    return {
      Ishandler: '1', // 除了999 都不是 处理页v
      OrderID: null,
      orderInfo: {},
      Purchase: false, // 采购订单
      fullscreenLoading: false,
      // 订单解锁
      OrderResoveVisibel: false,
      // 订单锁住信息
      orderResovleInfo: {
        OrderID: 'fdsfs',
        NickName: 'fdsaf',
        WriteTime: '2021-03-23'
      },
      createDeLiveryData: [], // 生成交货单数据
      // 转采购 =======================================begin ====================================
      TransToPurchaseData: {
        IDX: 0,
        ArrivalDate: '', // 期望到货日期
        ExpireDateFlag: null, // 效期要求
        LotNoRequire: null, // 批号要求
        StockCode: null, // 入库仓位
        IsDirectSending: 0, // 是否直送医院
        SendID: null,
        sendAddress: '',
        Remark: '', // 备注
        NoticeUser: '',
        NoticeUserName: [],
        OrderDetailsContent: '',
        OrderID: '',
        tableList: [
        ],
        OrderGuid: ''
      }, // 转采购数据
      transTopurchaseVisbel: false,
      // 转采购 =======================================begin ====================================
      // 创建交货单 begin ==========================begin==========================
      createDeliveryVisbel: false,
      createDeliveryList: [
        {
          InvCode: 'fdsaf',
          InvName: 'fdsa',
          Specification: '规格', // 规格
          BrandName: '品牌', // 品牌
          MRCStockUnitN: '单位', // 单位
          cctj: '存储条件', // 存储条件
          ph: '批号', // 批号
          xq: '效期', // 效期
          ssck: '所属仓库', // 所属仓库
          sybh: '剩余备货', // 剩余备货
          kc: '库存', // 库存
          sl: '效期', // 数量
          yjhsl: '已交货数量', // 已交货数量
          dj: '单价', // 单价
          xj: '小计' // 小计
        }
      ],
      createDeliveryloading: false,
      createDeliveryReason: '',
      // 创建交货单 end ==========================end==========================
      // ================================================= 转采购选择人员 begin  =================================================
      seletUserVisible: false,
      selectUseractiveName: 'user', // 选择人员tab切换
      isshow: false, // 控制 部门和角色选择是否显示
      selectUserkeywords: '', // 搜索成员关键字
      connuserlist: [], // 成员list
      selectUserDeptData: [], // 部门数据
      selectUserroleData: [], // 角色数据
      checkedUserInfo: [], // 选中的人员对象
      checkedUserIDXs: [], // 选中的人员对象
      checkedUserNames: [], // 选中的人员对象
      // ================================================= 转采购选择人员 end  =================================================
      // ================================================= 新增地址 begin  =================================================
      Province: [],
      City: [],
      Area: [],
      showSetAddress: false,
      ruleForm: {
        IDX: '',
        Province: '',
        City: '',
        Area: '',
        Address: '',
        DeptName: '',
        ContactName: '',
        ContactTel: '',
        Filepath: '',
        AddressCode: '',
        DefaultFlag: '',
        Base64String: '',
        FileExtName: '',
        AddrType: null
      },
      ruleForm_temp: {
        IDX: '',
        Province: '',
        City: '',
        Area: '',
        Address: '',
        DeptName: '',
        ContactName: '',
        ContactTel: '',
        Filepath: '',
        AddressCode: '',
        DefaultFlag: '',
        Base64String: '',
        FileExtName: '',
        AddrType: ''
      },
      AddrType: [],
      rules: {
        Province: [
          { required: true, message: '请输入省', trigger: 'blur' }
        ],
        City: [
          { required: true, message: '请输入市', trigger: 'blur' }
        ],
        Area: [
          { required: true, message: '请输入区', trigger: 'blur' }
        ],
        Address: [
          { required: true, message: '请输入详细地址', trigger: 'blur' }
        ],
        AddrType: [
          { validator: validaddrestype, trigger: 'blur' }
        ],
        ContactName: [
          { required: true, message: '联系人', trigger: 'blur' }
        ],
        ContactTel: [
          { required: true, message: '联系电话', trigger: 'blur' }
        ]
      },
      seletaddressVisible: false,
      addressList: [],
      // ================================================= 新增地址 end  =================================================
      locatList: [] // 仓位列表
    }
  },
  destroyed() {
    // window.removeEventListener('scroll', this.onScroll, true)
  },
  created() {
    const _orderid = this.$route.params.orderid
    this.Purchase = this.$route.params.Purchase
    this.OrderID = _orderid
    this.Ishandler = this.$route.params.ishandler
    if (this.Ishandler === '999') {
      // 只有是handler 页才请求是否锁住接口
      this.getOrderLockInfo(this.OrderID)
      this.getOrderGuid(this.OrderID)
    }
  },
  mounted() {
    const _this = this
    window.addEventListener('scroll', function(e) {
      // console.log(e.target.scrollTop)
      _this.onScroll(e.target.scrollTop, _this)
    }, true)
    regionData.map(item => {
      this.Province.push({
        label: item.label,
        value: item.value
      })
    })
  },
  watch: {
    selectUserkeywords(val) {
      this.$refs.allusertree.filter(val)
    }
  },
  methods: {
    // 验证批量关闭订单
    comfirmColseOrder() {
      const _this = this
      _this.$confirm('确认关闭订单?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const _ordrinfo = _this.$refs.orderHeader.orderHeadInfo
        _this.ColseOrder(_ordrinfo.OrderID)
      }).catch(() => {
      })
    },
    //  1 _oderId 大于0 则单个关闭
    ColseOrder(_oderId) {
      const _this = this
      _this.fullscreenLoading = true
      const paras = {
        OrderIDS: _oderId,
        Stauts: 100
      }
      $orderReq.OrderInfoStatusUpdate(paras).then((res) => {
        _this.fullscreenLoading = false
        if (res.RetCode === '0') {
          _this.$refs.orderMaterialList.getOrderMaterialList()
          _this.$refs.orderDeliveryList.getDeliveryList()
          _this.$refs.orderSaleList.getSaleList()
          _this.$refs.orderHeader.getOrderHeader()
          _this.$message.success('操作成功')
        } else {
          _this.$message.error('操作失败:' + res.RetMsg)
        }
      })
    },
    // 获取仓位接口
    getLocation() {
      const _this = this
      _this.$api.GetStockCodeList({ KeyWords: '', PageIndex: 1, PageSize: -1 }).then((res) => {
        console.log(res)
        if (res.RetCode === '0') {
          _this.locatList = res.Message
        } else {
          console.log('获取仓位列表出错： ' + res.RetMsg)
        }
      })
    },
    // ========================================================================新增地址 begin ===================================================
    // 打开地址列表
    selectAddress() {
      const _this = this
      _this.fullscreenLoading = true
      $orderReq.GetUseraddressList({ CompanyID: _this.$refs.orderHeader.orderHeadInfo.SellCompanyID, AddrType: 10 }).then((res) => {
        _this.fullscreenLoading = false
        if (res.RetCode === '0') {
          _this.addressList = res.Message
        } else {
          _this.$message.error('获取地址出错：' + res.RetMsg)
        }
      })
      this.seletaddressVisible = true
    },
    // 确认选择地址
    confirmCheckedAddress(_item) {
      const _this = this
      _this.TransToPurchaseData.SendID = _item.IDX
      _this.TransToPurchaseData.sendAddress = _item.FullAddress
      this.seletaddressVisible = false
    },
    // 新建地址
    handleCheckedCitiesChange(val) {
      this.ruleForm.AddrType = null
      if (val) {
        if (val.length > 1) {
          this.ruleForm.AddrType = 2
        } else {
          if (val[0] === '收货地址') {
            this.ruleForm.AddrType = 10
          } else {
            this.ruleForm.AddrType = 20
          }
        }
      }
    },
    creatArdess() {
      this.showSetAddress = true
      this.ruleForm = this.ruleForm_temp
      this.AddrType = [] // 地址用途清空
    },
    // 新增地址/ 编辑
    submitAddress(formName) {
      if (!this.ruleForm.IDX) {
        this.ruleForm.Province = CodeToText[this.ruleForm.Province[0]]
        this.ruleForm.City = CodeToText[this.ruleForm.City[0]]
        this.ruleForm.Area = CodeToText[this.ruleForm.Area[0]]
      }
      if (this.ruleForm.IDX) {
        this.ruleForm.Province = CodeToText[this.ruleForm.Province]
        this.ruleForm.City = CodeToText[this.ruleForm.City]
        this.ruleForm.Area = CodeToText[this.ruleForm.Area]
      }
      console.log('this.ruleForm', this.ruleForm)
      this.$refs[formName].validate((valid) => {
        if (!valid) return false
        this.$api.SaveUserAddress_sys(this.ruleForm).then(res => {
          if (res.RetCode !== '0') return this.$message.error('错误：' + res.RetMsg)
          this.selectAddress()
          this.$message.success('新建地址成功')
          this.showSetAddress = false
        })
      })
    },
    handleFileChange(e) {
      const _this = this
      const file = e.target.files[0]
      const size = Math.floor(file.size / 1024 / 1024)
      if (size > 5) {
        return this.$message({
          message: '图片大小不能超过5M',
          type: 'warning'
        })
      }
      console.log(file)
      _this.ruleForm.FileExtName = file.type.split('/')[1]
      var reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = (e) => {
        console.log(e)
        _this.ruleForm.Base64String = e.target.result // 传base64
      }
    },
    ProvincehandleChange(value) {
      console.log(value[0])
      this.City = []
      const City = []
      Object.assign(City, regionData.filter(item => item.value === value[0]))
      City[0].children.map(item => {
        this.City.push({
          label: item.label,
          value: item.value
        })
      })
    },
    CityhandleChange1(value) {
      this.Area = []
      const City = regionData.filter(item => item.value === this.ruleForm.Province[0])
      const Area = City[0].children.filter(item => item.value === value[0])
      if (Area[0].children) {
        Area[0].children.map(item => {
          this.Area.push({
            label: item.label,
            value: item.value
          })
        })
      }
      // this.ruleForm1.City = CodeToText[value[0]]
    },
    AreahandleChange2(value) {
      // this.ruleForm1.Area = CodeToText[value[0]]
    },
    closeaddrss(e) {
      this.showSetAddress = false
      this.$refs[e].resetFields()
      this.ruleForm.IDX = ''
      this.AddrType = [] // 地址用途清空'
    },
    // ========================================================================新增地址 end ===================================================
    // ========================================================================转采购 begin =====================================================
    changeLot(_type) {
      if (_type === 10) {
        // 调用同步批号接口
        this.getLastlotNum()
      }
    },
    // chagesend 选择师傅直送
    chagesend(_type) {
      const _this = this
      // const _ordrinfo = _this.$refs.orderHeader.orderHeadInfo
      if (_type === 1) {
        // 是 直送 companyid
        const _ordrinfoaddress = _this.$refs.orderHeader.orderaddress
        _this.TransToPurchaseData.SendID = _ordrinfoaddress.IDX
        _this.TransToPurchaseData.sendAddress = _ordrinfoaddress.FullAddress
      } else if (_type === 0) {
        // 否
        const _sellLocationAddress = _this.$refs.orderHeader.sellLocationAddress
        if (!_this.$refs.orderHeader.sellLocationAddress) {
          _this.TransToPurchaseData.SendID = 0
          _this.TransToPurchaseData.sendAddress = ''
        } else {
          _this.TransToPurchaseData.SendID = _sellLocationAddress.IDX
          _this.TransToPurchaseData.sendAddress = _sellLocationAddress.FullAddress
        }
      } else {
        _this.TransToPurchaseData.SendID = 0
        _this.TransToPurchaseData.sendAddress = ''
      }
    },
    // 打开转采购框
    dealTransPurchase() {
      const _this = this
      // 初始化其他信息
      _this.initTranPur()
      const _arr = _this.$refs.orderMaterialList.materialCheckedList
      const _ordrinfo = _this.$refs.orderHeader.orderHeadInfo
      if (!_arr || _arr.length < 1) {
        _this.$message.warning('请选择你要操作的物料')
        return false
      }
      let isgong = true
      _this.$refs.orderMaterialList.materialList.forEach(ele => {
        if (ele.PartID < 1) {
          isgong = false
          return false
        }
      })
      if (!isgong) {
        _this.$message.warning('当前存在未保存的拆分数据，请先保存再操作转采购')
        return false
      }
      _this.fullscreenLoading = true
      // 获取仓位接口
      this.getLocation()
      _this.TransToPurchaseData.tableList = []
      _this.orderInfo = _ordrinfo
      let isgoing = true
      _arr.forEach((element) => {
        // 判断物料行是否允许转采
        // if ((element.Num - element.NumSend - element.NumToPR) < 1) {
        //   isgoing = false
        //   return false
        // }
        if (element.PartNum <= 0) {
          isgoing = false
          return false
        }
        _this.TransToPurchaseData.tableList.push({
          IDX: element.IDX,
          InvCode: element.InvCode,
          ManuPartNum: element.ManuPartNum,
          MaterialName: element.MaterialName,
          Specification: element.Specification, // 规格
          BrandName: element.BrandName, // 品牌
          MRCStockUnitN: element.StockUnitN || element.StockUnit, // 单位
          MaterialStorageConditionName: element.MaterialStorageConditionName, // 存储条件
          LotNum: element.LotNum, // 批号
          LotNo: null, // 批号
          Num: element.Num, // 数量
          cgsl: element.PartNum, // 采购数量
          ExpiredDate: element.ExpiredDate, // 效期
          Location: element.Location, // 所属仓库
          StockNumV2: element.StockNumV2, // 库存
          NumSend: element.NumSend, // 已交货数量
          LatestAmount: element.LatestAmount, // 单价
          PartID: element.PartID,
          stockid: element.MaterialStockSellID,
          NumToPR: element.NumToPR // 已转采数量
        })
      })
      if (!isgoing) {
        _this.$message.warning('选择的数据已不能转采')
        _this.fullscreenLoading = false
        return false
      }
      const _sellLocationAddress = _this.$refs.orderHeader.sellLocationAddress
      if (!_this.$refs.orderHeader.sellLocationAddress) {
        _this.TransToPurchaseData.SendID = 0
        _this.TransToPurchaseData.sendAddress = ''
      } else {
        _this.TransToPurchaseData.SendID = _sellLocationAddress.IDX
        _this.TransToPurchaseData.sendAddress = _sellLocationAddress.FullAddress
      }
      // 处理备注 以及期望到货时间
      _this.TransToPurchaseData.ArrivalDate = _this.$refs.orderHeader.orderHeadInfo.ExpectedDeliveryDateShow
      _this.TransToPurchaseData.Remark = _this.$refs.orderHeader.orderHeadInfo.Remark
      // _this.TransToPurchaseData.ArrivalDate = Object.assign(_this.TransToPurchaseData.ArrivalDate, _this.$refs.orderHeader.orderHeadInfo.ExpectedDeliveryDateShow)
      // _this.TransToPurchaseData.Remark = Object.assign(_this.TransToPurchaseData.Remark, _this.$refs.orderHeader.orderHeadInfo.Remark)
      _this.fullscreenLoading = false
      this.transTopurchaseVisbel = true
      // 获取选中的物料明细数据 判断是否都能转采购
    },
    initTranPur() {
      this.TransToPurchaseData = {
        IDX: 0,
        ArrivalDate: '', // 期望到货日期
        ExpireDateFlag: null, // 效期要求
        LotNoRequire: null, // 批号要求
        StockCode: null, // 入库仓位
        IsDirectSending: 0, // 是否直送医院
        SendID: null,
        sendAddress: '',
        Remark: '', // 备注
        NoticeUser: '',
        NoticeUserName: [],
        OrderDetailsContent: '',
        OrderID: '',
        tableList: [
        ]
      }
    },
    // 获取上次使用批号
    getLastlotNum() {
      const _this = this
      _this.fullscreenLoading = true
      const _ordrinfo = _this.$refs.orderHeader.orderHeadInfo
      const _invcodearr = []
      _this.TransToPurchaseData.tableList.forEach((ele) => {
        _invcodearr.push(ele.InvCode)
      })
      $orderReq.GetHistoryLotNum({ CompanyID: _ordrinfo.SellCompanyID, InvCodes: _invcodearr.join(',') }).then((res) => {
        _this.fullscreenLoading = false
        if (res.RetCode === '0') {
          if (res.Message) {
            _this.dealgetLotNum(res.Message, _this)
          }
          // _this.$message('操作成功')
        } else {
          _this.$message.error('获取当前公司的仓库地址出错：' + res.RetMsg)
        }
      })
    },
    // 处理获取的批号
    dealgetLotNum(_data, _this) {
      _data.forEach((ele) => {
        _this.TransToPurchaseData.tableList.forEach((item) => {
          if (ele.InvCode === item.InvCode) {
            item.LotNum = ele.LotNum
            item.ExpiredDate = ele.ExpiredDate
          }
        })
      })
    },
    // 校验输入的采购数量是否是数字
    checkedcgsl(_row) {
      if ($comjs.checkedNum(_row.cgsl) < 0) {
        _row.cgsl = 0
        this.$message.warning('采购数量必须是数字')
      }
    },
    // 确认转采购
    comfirmTransTopur() {
      const _this = this
      // 校验通知人是否填写
      // if (!_this.TransToPurchaseData.NoticeUser || _this.TransToPurchaseData.NoticeUser.length < 1) {
      //   _this.$message('请选择通知处理人')
      //   return false
      // }
      if (_this.TransToPurchaseData.SendID === null || _this.TransToPurchaseData.SendID < 1) {
        _this.$message.error('请选择地址！！')
        return false
      }
      _this.$confirm('确认将选中数据转采购吗', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const _data = _this.TransToPurchaseData
        const _contentarr = []
        _data.OrderID = _this.OrderID
        _data.OrderDetailsContent = ''
        let isgoing = true
        let message = ''
        _this.TransToPurchaseData.tableList.forEach((ele) => {
          if (ele.cgsl <= 0) {
            isgoing = false
            message = '物料' + ele.InvCode + '填写的转采数量不能小于等于0！'
            return false
          }
          // 判断物料行是否允许转采
          if ((ele.Num - ele.NumSend - ele.NumToPR - parseFloat(ele.cgsl)) < 0) {
            isgoing = false
            const _num = ele.Num - ele.NumSend - ele.NumToPR
            message = '物料' + ele.InvCode + '填写的转采数量不能大于' + _num + '！'
            return false
          }
          _contentarr.push({
            orderDetailsID: ele.IDX,
            Num: ele.cgsl,
            SendCompanyID: 0,
            LotNo: ele.LotNo,
            PartID: ele.PartID,
            ExpiryDate: ele.ExpiredDate,
            SellCompanyID: _this.$refs.orderHeader.orderHeadInfo.SellCompanyID,
            IsGroupPurchase: 0
          })
        })
        if (!isgoing) {
          _this.$message.error(message)
          return false
        }
        _data.OrderDetailsContent = JSON.stringify(_contentarr)
        _data.OrderGuid = localStorage.getItem('OrderGuid')
        _this.TransTopur(_data)
      }).catch(() => {
      })
    },
    TransTopur(_data) {
      const _this = this
      _this.fullscreenLoading = true
      $orderReq.CreatePruchaseApply(_data).then(res => {
        _this.fullscreenLoading = false
        if (res.RetCode === '0') {
          _this.$refs.orderMaterialList.getOrderMaterialList()
          _this.$refs.orderDeliveryList.getDeliveryList()
          _this.$refs.orderSaleList.getSaleList()
          _this.$refs.orderHeader.getOrderHeader()
          _this.$message.success('操作成功')
          localStorage.setItem('OrderGuid', res.OrderGuid)
          _this.transTopurchaseVisbel = false
        } else {
          _this.$message.error('操作失败：' + res.RetMsg)
        }
      })
    },
    // ========================================================================转采购 end =====================================================
    // 先请求订单是否被锁住  解锁后才能处理订单
    // 点亮锚点
    onScroll(_scrolled, _this) {
      // 获取四个节点的锚点距离
      if (document.getElementById('orderMaterialList')) {
        const _orderMaterialList = document.getElementById('orderMaterialList').offsetTop
        const _orderSaleList = document.getElementById('orderSaleList').offsetTop
        const _orderDeliveryList = document.getElementById('orderDeliveryList').offsetTop
        if (_this.$refs.orderNavigation) {
          if (_scrolled >= _orderDeliveryList) {
            // 定位交货单
            _this.$refs.orderNavigation.activeNav = 4
          } else if (_scrolled >= _orderSaleList - 50 && _scrolled < _orderDeliveryList) {
            _this.$refs.orderNavigation.activeNav = 3
          } else if (_scrolled >= _orderMaterialList - 50 && _scrolled < _orderSaleList) {
            _this.$refs.orderNavigation.activeNav = 2
          } else {
            _this.$refs.orderNavigation.activeNav = 1
          }
        }
      }
    },
    // 转采购选择人员_   ====================================begin ===================================
    oprenSelectContractUser() {
      const _this = this
      _this.checkedUserInfo = []
      _this.getSelectUserQueryListOfDept()
      _this.seletUserVisible = true
    },
    // 获取选择人员时 ，成员列表
    getSelectUserQueryListOfDept() {
      const _this = this
      _this.fullscreenLoading = true
      _this.connuserlist = []
      $userRequest.SelectUserQueryListOfDept().then(res => {
        _this.fullscreenLoading = false
        if (res.RetCode === '0') {
          _this.connuserlist = res.Message
        }
      })
    },
    // 选中人员
    checkeduser(ischecked, item) {
      const _this = this
      _this.pusharr(ischecked, item)
      if (item.children) {
        _this.dgcheck(ischecked, item)
      }
      console.log(this.checkeduserIdList)
    },
    pusharr(ischecked, item) {
      if (ischecked) {
        if (this.checkedUserInfo.indexOf(item) < 0) {
          this.checkedUserInfo.push(item)
        }
      } else {
        this.checkedUserInfo.splice(this.checkedUserInfo.indexOf(item), 1)
      }
    },
    dgcheck(ischecked, data) {
      const _this = this
      data.ischecked = ischecked
      _this.pusharr(ischecked, data)
      if (data.children) {
        data.children.forEach(element => {
          return _this.dgcheck(ischecked, element)
        })
      }
    },
    // 过滤树形数据
    alluserfilterNode(value, data) {
      if (!value) return true
      return data.label.indexOf(value) !== -1
    },
    doconnuser() {
      const _this = this
      if (!_this.checkedUserInfo || _this.checkedUserInfo.IDX < 1) {
        _this.$message.warning('请选择人员')
        return false
      }
      // _this.TransToPurchaseData.NoticeUser =
      _this.checkedUserIDXs = []
      _this.TransToPurchaseData.NoticeUser = ''
      _this.TransToPurchaseData.NoticeUserName = []
      _this.checkedUserInfo.forEach((ele) => {
        _this.checkedUserIDXs.push(ele.IDX)
        _this.TransToPurchaseData.NoticeUserName.push(ele.GroupName)
      })
      _this.TransToPurchaseData.NoticeUser = _this.checkedUserIDXs.join(',')
      _this.seletUserVisible = false
    },
    // 生成交货单
    // 打开创建交货单
    openCretateDelivery() {
      // 判断是否有选中值
      const _this = this
      const _arr = _this.$refs.orderMaterialList.materialCheckedList
      const _ordrinfo = _this.$refs.orderHeader.orderHeadInfo
      if (!_arr || _arr.length < 1) {
        _this.$message.warning('请选择你要操作的物料')
        return false
      }
      let isgoing = true
      _this.$refs.orderMaterialList.materialList.forEach(ele => {
        if (ele.PartID < 1) {
          isgoing = false
          return false
        }
      })
      if (!isgoing) {
        _this.$message.warning('当前存在未保存的拆分数据，请先保存再操作生成交货单')
        return false
      }
      _arr.forEach((ele) => {
        if (!ele.MaterialStockSellID || ele.MaterialStockSellID < 1) {
          isgoing = false
          _this.$message.warning('物料 ' + ele.InvCode + ' 存在未选择批号的数据，不能生成交货单')
          return false
        }
      })
      if (isgoing) {
        // 处理创建交货单的数据
        _this.fullscreenLoading = true
        _this.createDeliveryList = []
        _this.orderInfo = _ordrinfo
        _arr.forEach(element => {
          _this.createDeliveryList.push(
            {
              IDX: element.IDX,
              InvCode: element.InvCode,
              InvName: element.MaterialName,
              Specification: element.Specification, // 规格
              BrandName: element.BrandName, // 品牌
              MRCStockUnitN: element.StockUnitN || element.StockUnit, // 单位
              MaterialStorageConditionName: element.MaterialStorageConditionName, // 存储条件
              LotNum: element.LotNum, // 批号
              ExpiredDate: element.ExpiredDate, // 效期
              Location: element.StockCode, // 所属仓库
              StockNumV2: element.StockNumV2, // 库存
              num: element.CurrentSendNum, // 数量
              NumSend: element.NumSend, // 已交货数量
              ContactPrice: element.LatestAmount, // 单价
              PartID: element.PartID,
              stockid: element.MaterialStockSellID,
              DelFlag: element.DelFlag
            }
          )
        })
        _this.createDeliveryReason = _this.orderInfo.Remark
        _this.createDeliveryVisbel = true
        _this.fullscreenLoading = false
      }
    },
    // 验证生成交货单
    confirmCreateDelivery() {
      const _this = this
      _this.$confirm('确认生成交货单吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const _data = []
        // 处理数据
        _this.createDeliveryList.forEach((ele) => {
          _data.push(
            {
              OrderDetailsID: ele.IDX,
              PartID: ele.PartID,
              StockID: ele.stockid,
              Num: ele.num,
              DelFlag: ele.DelFlag
            }
          )
        })
        // 已移除的数据
        const _deleterow = _this.$refs.orderMaterialList.deletePartMateialitem
        if (_deleterow && _deleterow.length > 0) {
          _deleterow.forEach((ele) => {
            _data.push(
              {
                OrderDetailsID: ele.IDX,
                PartID: ele.PartID,
                StockID: ele.MaterialStockSellID,
                Num: ele.num,
                DelFlag: ele.DelFlag
              }
            )
          })
        }
        // {"PartID":111,"MaterialStockSellID":222,"Num":33}
        _this.CreateDelivery(_data)
      }).catch(() => {
      })
    },
    // 生成交货单
    CreateDelivery(_data) {
      const _this = this
      _this.fullscreenLoading = true
      $orderReq.CreateOrderDelivery({ OrderID: _this.OrderID, Remark: _this.createDeliveryReason, OrderDetailsList: JSON.stringify(_data), OrderGuid: localStorage.getItem('OrderGuid') }).then((res) => {
        _this.fullscreenLoading = false
        if (res.RetCode === '0') {
          _this.$refs.orderHeader.getOrderHeader()
          _this.$refs.orderMaterialList.getOrderMaterialList()
          _this.$refs.orderDeliveryList.getDeliveryList()
          _this.$refs.orderSaleList.getSaleList()
          _this.$message.success('操作成功')
          localStorage.setItem('OrderGuid', res.OrderGuid)
          _this.createDeliveryVisbel = false
        } else {
          _this.$message.error('生成交货单出错' + res.RetMsg)
        }
      })
      this.createDeliveryVisbel = false
    },
    // 获取订单锁住信息
    async getOrderLockInfo() {
      const _this = this
      _this.fullscreenLoading = true
      await $orderReq.OrderIsLock({ OrderId: _this.OrderID }).then((res) => {
        if (res.RetCode === '0') {
          _this.fullscreenLoading = false
        } else if (res.RetCode === '1') {
          _this.OrderResoveVisibel = true
          _this.fullscreenLoading = false
          _this.orderResovleInfo = res.Message
        } else {
          _this.$router.push('/orderMananger/orderList')
        }
      })
    },
    // 获取订单处理guid 并存入缓存中
    async getOrderGuid() {
      const _this = this
      await $orderReq.getOrderGuid({ OrderID: _this.OrderID }).then((res) => {
        if (res.RetCode === '0') {
          localStorage.setItem('OrderGuid', res.Message)
        } else {
          _this.$message.error('获取处理订单权限信息出错： ' + res.RetMsg)
          _this.$router.push('/orderMananger/orderList')
        }
      })
    },
    // 订单解锁
    comfirmOrderResove() {
      const _this = this
      _this.fullscreenLoading = true
      $orderReq.OrderUnLock({ OrderId: _this.OrderID }).then((res) => {
        _this.fullscreenLoading = false
        if (res.RetCode === '0') {
          _this.OrderResoveVisibel = false
        } else {
          _this.$message.error('订单解锁失败！')
          _this.$router.push('/orderMananger/orderList')
        }
      })
    }
  }
}
export default orderDetails
