export default {
  // 校验只能输入两位小数
  checkedNum(val) {
    const reg = /^\d+(.\d{1,2})?$/
    let temp = -1
    if (reg.test(val)) {
      temp = val
    }
    return temp
  },
  // 校验整数
  checkedintNum(val) {
    const reg = /^\d+$/g
    let temp = -1
    if (reg.test(val)) {
      temp = val
    }
    return temp
  },
  evil(fn) {
    const Fn = Function
    return new Fn('return ' + fn)()
  },
  // 格式化时间 _type 1 时间 2 ：日期
  formartTime(value, _type) {
    if (!value) {
      return ''
    }
    const date = this.eval('new ' + value.substr(1, value.length - 2))
    // let date = new Date(value)
    const y = date.getFullYear()
    let MM = date.getMonth() + 1
    MM = MM < 10 ? '0' + MM : MM
    let d = date.getDate()
    d = d < 10 ? '0' + d : d
    let h = date.getHours()
    h = h < 10 ? '0' + h : h
    let m = date.getMinutes()
    m = m < 10 ? '0' + m : m
    let s = date.getSeconds()
    s = s < 10 ? '0' + s : s
    if (_type === 1) {
      return y + '-' + MM + '-' + d + ' ' + h + ':' + m + ':' + s
    } else {
      return y + '-' + MM + '-' + d
    }
  },
  // 处理base64的字节数组 下载文件 begin =============================begin =============================
  // 处理生成 唯一码名称
  S4() {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
  },
  // 处理生成 唯一码名称
  guid() {
    return (this.S4() + this.S4() + '-' + this.S4() + '-' + this.S4() + '-' + this.S4() + '-' + this.S4() + this.S4() + this.S4())
  },
  // 处理字节流
  downloadFile(data, fileName) {
    var byteString = atob(data)
    var arrayBuffer = new ArrayBuffer(byteString.length) // 创建缓冲数组
    var intArray = new Uint8Array(arrayBuffer) // 创建视图
    for (var i = 0; i < byteString.length; i++) {
      intArray[i] = byteString.charCodeAt(i)
    }
    const blob = new Blob([intArray], { type: '' })
    // 获取heads中的filename文件名
    const downloadElement = document.createElement('a')
    // 创建下载的链接
    const href = window.URL.createObjectURL(blob)
    downloadElement.href = href
    // 下载后文件名
    downloadElement.download = fileName
    document.body.appendChild(downloadElement)
    // 点击下载
    downloadElement.click()
    // 下载完成移除元素
    document.body.removeChild(downloadElement)
    // 释放掉blob对象
    window.URL.revokeObjectURL(href)
  }
  // 处理base64的字节数组 下载文件 end =============================end =============================
}
