export default {
  methods: {
    //  打印
    print() {
      var title = ''
      var divElements = ''
      const top_view_print = document.getElementById('top_view_print').innerHTML
      const bottom_view_print = document.getElementById('bottom_view_print').innerHTML
      const table_footer = document.getElementById('table_footer').innerHTML
      // console.log(divElements)
      var printWindow = window.open(Math.random() * 1000, '_blank', '')
      // // 构造一个表格
      let str_tr = ''
      let str_td = ''
      let num = 0
      this.info.table.map(item => {
        if (item.isChecked) {
          num += 1
          str_tr += `
              <th style="font-size: 12px;font-weight:400;min-width:100px" >${item.label}</th>
          `
        }
      })
      this.pritInfo_.tableList.map((item, index) => {
        // console.log(index)
        this.info.table.map((item1, index1) => {
          if (item1.isChecked) {
            str_td += `<td  id="testTd" style="text-align:center;border:1px solid #666;font-size: 12px">${item[item1.prop] ? item[item1.prop] : ''}</td>]`
          }
        })
      })
      const testTD = str_td.split(']')
      testTD.splice(str_td.split(']').length - 1, 1)
      // console.log('str_td', testTD)
      // console.log('num', num)
      let arr = []
      let str = ''
      arr = this.group(testTD, num)
      // console.log(arr)
      arr.map((item, index) => {
        str += `<tr>${item.join('')}</tr>`
      })
      const table_ = `
            <table border="1" class='table-0' id="table_main_v" cellpadding="0" cellspacing="0" style="border-collapse:collapse;border:1px solid #666;width:100%">
            <tr>
              ${str_tr}
            </tr>
            ${str}
          </table>
        `
      // console.log(table_)
      divElements = `<div class='div-0' id='div_0'>${top_view_print + table_ + table_footer + bottom_view_print}</div>`
      printWindow.document.open()
      printWindow.document.write('<html><head><meta http-equiv="Pragma" content="no-cache" /><meta http-equiv="Cache-Control" content="no-cache" /><meta http-equiv="Expires" content="0" /><title>' + title + '</title>')
      printWindow.document.write(`<style type="text/css">
         html, body {
           font-size: 12px;
           margin: 0;
         }
        .p-lr-20 {padding: 0 20px; }
        .m-bottom-20 {margin-bottom: 20px; }
        .weight { font-weight: 700 }
        .f14 { font-size:12px}
        .f20 {font-size:12px}
        .flex {display:flex}
        .j-center {justify-content: center;}
        .column { flex-direction: column;}
        .a-center {align-items: center;}
        .f-warp { flex-wrap: wrap; }
        .j-start {justify-content: flex-start;}
        .m-tb-10 {margin: 10px 0;}
        .m-tb-20 {margin: 20px 0;}
        .j-between {justify-content: space-between;}
        .m-top-10 {margin-top: 10px }
        .element.style {width: auto; }
        .table_info {
          max-width: 1000px;
          margin: 0 auto;
        }
        .table_footer {
          height:25px;
          border:1px solid #666;
          border-top:none;
          line-height:25px;
       }
       .table_footer_1 {
          border:1px solid #666;
       }
       .table_footer_2 {
          height:25px;
          line-height:25px;
          border-left:1px solid #666;
          border-right:1px solid #666;
          border-bottom:1px solid #666;
       }
        table{table-layout: fixed;}
        td{word-break: break-all; word-wrap:break-word;}
       
       .div-0{
          // width: 100%;
          // height: 297mm;
          page-break-after: always;
        }
      
        </style>`
      )
      printWindow.document.write('<script src="https://cdn.bootcdn.net/ajax/libs/jquery/3.6.0/jquery.min.js"></script></head><body>')
      printWindow.document.write(divElements)
      printWindow.document.write('</body></html>')
      printWindow.document.write(`<script type="text/javascript">
      $(document).ready(function(){
        // window.onload = function() {
          class Test {
            constructor() {
              this.windowHeight = null // 窗口高度
              this.pageIndex = 0 // 当前页
              this.height_print = '' // 初始高度
              this.max_height = null // 最大高度
              this.tbody_tr = '' // 获取表格子集
              this.splice_arr = [] // 被删掉的元素
              this.header_tr = null // 表头的tr 不改动
              this.table_main = null // 表格 父级
              this.table_main_height = 0 // 表格高度
              this.bottom_veiw_height = 0 // 表底高度
              this.top_veiw_height = 0 // 表头高度
              this.table_footer_height = 0 //  // 表格合计高度
              this.top_veiw_html = null // 表头的内容、
              this.bottom_veiw_html = null // 表底的内容
              this.title_view_html = null //  顶部标题dom
              this.table_footer_html_0 = null // 表格合计内容
              this.table_footer_html_1 = null // 表格合计内容
              this.delArr = []
            }
            init(a) {
              this.windowHeight =  document.body.clientHeight // window.innerHeight
              // this.max_height =  Math.floor(this.windowHeight  / 2)
              this.max_height =   Math.floor(980 / 2)
              //  console.log('this.max_height ',this.max_height )
              const t_b_view = document.getElementsByClassName('t_b_view')
              this.table_main_height = document.getElementById('table_main_v').offsetHeight // 表格高度
              // this.table_main = document.getElementById('table_main_v') // 表格 父级
              this.table_main =  document.getElementsByClassName('table-0')[this.pageIndex] // 表格 父级
              // document.getElementsByClassName('div-0')[this.pageIndex].style.height =  this.max_height + 'px'
              // console.log( this.table_main.childNodes)
              this.tbody_tr = this.table_main.childNodes.length > 1 ? this.table_main.childNodes[1].children : this.table_main.childNodes[0].children // 获取表格子集 35
              this.header_tr = this.tbody_tr[0].innerHTML // 表头的tr 不改动
              this.title_view_html = document.getElementsByClassName('header')[0].innerHTML // 顶部标题dom
              this.table_footer_height = (document.getElementsByClassName('table_footer')[0].offsetHeight) * 2 // 表格合计高度
              this.table_footer_html_0 = document.getElementsByClassName('table_footer')[0].innerHTML // 表格合计内容
              this.table_footer_html_1 = document.getElementsByClassName('table_footer')[1].innerHTML // 表格合计内容
              for (let i = 0; i < t_b_view.length; i++) {
                this.top_veiw_height = t_b_view[0].offsetHeight
                this.bottom_veiw_height = t_b_view[1].offsetHeight
                this.top_veiw_html = t_b_view[0].innerHTML
                this.bottom_veiw_html = t_b_view[1].innerHTML
              }
              this.isSet()
            }
            // 是否换页
            isSet() {
              this.heightA()
              if (this.height_print < this.max_height) return // 不需要换页
              if (this.height_print > this.max_height) {
                 this.setData()
              }
            }
            // 处理换页的数据
            setData() {
              // console.log(this.height_print, this.max_height)
              let arr = []
                for (let i = 1; i <  this.tbody_tr.length; i++) {
                  if (this.height_print > this.max_height) {
                    // 将一个节点存起来
                    arr.push(this.tbody_tr[i])
                    // 删除一个节点
                    this.table_main.childNodes[1].removeChild(this.tbody_tr[i])
                    this.heightA()
                  }
                }
              console.log("arr", arr)
              // this.heightA()
              // for (let i = this.tbody_tr.length - 1; i >= 1; i--) {
              //   if (this.height_print > this.max_height) {
              //     // 将一个节点存起来
              //     arr.push(this.tbody_tr[i])
              //     // 删除一个节点
              //     this.table_main.childNodes[1].removeChild(this.tbody_tr[i])
              //   }
              //   this.heightA()
              // }
              this.tbody_tr =  arr
              this.initDocument()
            }
            // 计算高度 当前页高度
            heightA() {
              const s = this
              // s.height_print = document.getElementsByClassName('div-0')[s.pageIndex].offsetHeight
              console.log('s.pageIndex',  s.pageIndex)
              s.height_print = $("#div_" + s.pageIndex).height()
              // console.log('s.height_print',  s.height_print)
                // this.height_print = document.getElementsByClassName('div-0')[this.pageIndex].offsetHeight
                // document.getElementsByClassName('div-0')[0].style.background = 'red'
                // console.log("最大高度",this.max_height)
                // console.log('目前高度', this.height_print)
              // return this.height_print
            }
            // 为页面添加新的页
            initDocument() {
              this.pageIndex += 1
              let new_tr_td = ''
              for (let i = 0; i < this.tbody_tr.length; i++) {
                new_tr_td += '<tr>' + this.tbody_tr[i].innerHTML + '</tr>'
              }
              // 新的表格
              const table_ = "<table border='1' class='table-0' id='table_main_v' cellpadding='0' cellspacing='0' style='border-collapse:collapse;border:1px solid #666;width:100%'>" + this.header_tr + new_tr_td + '</table>'
              const dom_new = "<div id='" + 'div_'  +  this.pageIndex  + "'  class='div-0' ><div class='header weight f20 flex j-center column a-center m-tb-20'>" + this.title_view_html + '</div>' + "<div class='flex f-warp t_b_view m-tb-10'>" + this.top_veiw_html + '</div>' + table_ + "<div class='flex p-lr-20 header table_footer'>" + this.table_footer_html_0 + '</div>' + "<div class='flex p-lr-20 header table_footer table_footer_2'>" + this.table_footer_html_1 + '</div>' + "<div class='flex f-warp t_b_view m-tb-10'>" + this.bottom_veiw_html + '</div> </div> '
              //   window.document.write(dom_new)
              const conentDiv = document.createElement('div')
              conentDiv.innerHTML = dom_new
              document.body.appendChild(conentDiv)
              const aaa = this
              $(document).ready(function(){
                aaa.init()
              });
            }
          }
              const test = new Test()
              test.init()
      })
        </script>`)
      printWindow.document.close()
      printWindow.focus()
      setTimeout(function() {
        printWindow.print()
        printWindow.close()
      }, 100)
    }
  }

}
