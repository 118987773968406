<template>
  <div class="orderDetailsMaterial">
    <div class="p-lr-20 p-bottom-20">
      <el-card v-loading="materialListDataloading" class="box-card">
        <div slot="header" class="clearfix">
          <span class="cardTitle"><i class="el-icon-search" /> 订单详情</span>
        </div>
        <div v-if="Ishandler === '999'" class="searchView  flex a-center m-bottom-20 j-between">
          <div class="flex">
            <div class="out flex a-center cursor ModelBttton-white-30" @click="openoptionLog">
              操作记录
            </div>
          </div>
          <div class="materiallist flex m-left-20" style="flex:1">
            <el-input v-model="KeyWords" size="mini" round placeholder="货品编号 | 名称 | 品牌" class="rule-input-edit">
              <div slot="append" class="cursor" @click="searchMaterial">搜  索</div>
            </el-input>
            <div class="out p-left-10 flex a-center">
              <el-button round size="mini" @click="showall()">{{ showalltext }}</el-button>
              <el-button round size="mini" :loading="buttonloading" @click="confirmSaveDistributeStock">分配批号</el-button>
              <el-button round size="mini" :loading="buttonloading" @click="confirmClearOrderLotNo">清空批号</el-button>
              <el-button round size="mini" v-if="($parent.$refs.orderHeader.orderHeadInfo.OrderType !== 1) || ($parent.$refs.orderHeader.orderHeadInfo.POID === null || $parent.$refs.orderHeader.orderHeadInfo.POID.length < 1)"  @click="openUpdateNum">修改数量</el-button>
              <el-button round size="mini" v-if="($parent.$refs.orderHeader.orderHeadInfo.OrderType !== 1) || ($parent.$refs.orderHeader.orderHeadInfo.POID === null || $parent.$refs.orderHeader.orderHeadInfo.POID.length < 1)" @click="confirmDeleteMaterial">删除物料</el-button>
              <!-- <el-button round size="mini" v-if="($parent.$refs.orderHeader.orderHeadInfo.OrderType !== 1) || ($parent.$refs.orderHeader.orderHeadInfo.POID === null || $parent.$refs.orderHeader.orderHeadInfo.POID.length < 1)"  @click="openInputStock">库存录入</el-button> -->
              <el-button round size="mini" @click="openImportStock">导入库存</el-button>
              <el-button round size="mini" v-if="($parent.$refs.orderHeader.orderHeadInfo.OrderType !== 1) || ($parent.$refs.orderHeader.orderHeadInfo.POID === null || $parent.$refs.orderHeader.orderHeadInfo.POID.length < 1)" type="primary" @click="confirmopenaddmateri"><i class="el-icon-s-unfold m-right-10" />添加物料</el-button>
              <el-button round size="mini" type="primary" @click="confirmSave"><i class="el-icon-s-unfold m-right-10" />保存</el-button>
            </div>
          </div>
        </div>
        <!-- handler列表 -->
        <el-table
          v-if="Ishandler === '999'"
          ref="materialListref"
          v-loading="materialListDataloading"
          :header-cell-style="{background:'rgb(247,248,252)'}"
          :data="materialList"
          tooltip-effect="dark"
          show-header
          :span-method="dealrowspan"
          border
          max-height="350"
          @selection-change="materialListhandleSelectionChange"
        >
          <el-table-column
            fixed="left"
            type="selection"
            :selectable="checkboxInit"
            width="40" />
          <el-table-column
            fixed="left"
            label="操作"
          >
            <template slot-scope="scope">
                  <div v-if="scope.row.UploadFlag === -1 && scope.row.PartID >0" class="OperationButton">
                    <!-- <el-link type="primary" :underline="false" @click="splitPartMaterial(scope.row, scope.$index)"><i class="el-icon-connection p-right-10" />拆分</el-link> -->
                    <el-link type="primary" :underline="false" @click="openSplitDetails(scope.row)"><i class="el-icon-connection p-right-10" />拆分</el-link>
                  </div>
                  <div v-if="scope.row.UploadFlag === -1 && scope.row.isDeleteRow" class="OperationButton">
                    <el-link type="danger" :underline="false" @click="confirmDeletePartDetails(scope.row)"><i class="el-icon-circle-close p-right-10" />删拆</el-link>
                  </div>
            </template>
          </el-table-column>
          <el-table-column
            fixed="left"
            prop="InvCode"
            label="物料编号"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.InvCode }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="ManuPartNum"
            label="原厂编号"
            show-overflow-tooltip
          />
          <el-table-column
            prop="MaterialName"
            label="物料名称"
            show-overflow-tooltip
          />
          <el-table-column
            prop="Specification"
            label="规格"
            show-overflow-tooltip
          />
          <el-table-column
            prop="BrandName"
            label="品牌"
            show-overflow-tooltip
          />
          <el-table-column
            prop="LotNum"
            label="批号"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <el-link :disabled="scope.row.UploadFlag === -1 ? false : true" :underline="false" type="primary" @click="openupdatestock(scope.$index,scope.row)">{{ (scope.row.LotNum && scope.row.LotNum.length >0)? scope.row.LotNum: '选择批号' }}</el-link>
            </template>
          </el-table-column>
          <el-table-column
            prop="ExpiredDate"
            label="效期"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              {{ (scope.row.ExpiredDate && scope.row.ExpiredDate.length > 0) ? $minCommon.formatDate(new Date(scope.row.ExpiredDate),'yyyy-MM-dd') : '' }}
            </template>
          </el-table-column>
          <el-table-column
            prop="ProductDate"
            label="生产日期"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              {{ (scope.row.ProductDate && scope.row.ProductDate.length > 0) ? $minCommon.formatDate(new Date(scope.row.ProductDate),'yyyy-MM-dd') : '' }}
            </template>
          </el-table-column>
          <el-table-column
            prop="StockCode"
            label="仓位"
            show-overflow-tooltip
          />
          <el-table-column
            prop="ContractName"
            label="合约"
            show-overflow-tooltip
            width="150"
          >
            <template slot-scope="scope">
              <el-link :disabled="scope.row.isdisable" :underline="false" type="primary" @click="openupdatecontract(scope.row)">
                <span v-if="scope.row.contractName && scope.row.contractName.length > 0">{{ scope.row.contractName - scope.row.SAPContractID }}</span>
                <span v-else>{{ scope.row.SAPContractID }}</span>
              </el-link>
            </template>
          </el-table-column>
          <el-table-column
            prop="LatestAmount"
            label="单价"
            show-overflow-tooltip
          />
          <el-table-column
            prop=""
            label="小计"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <span>{{ scope.row.LatestAmount * scope.row.Num }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="StockUnitN"
            label="单位"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <span>{{ scope.row.StockUnitN || scope.row.StockUnit }}</span>
            </template>
          </el-table-column>
          <el-table-column>
            <template slot="header">
              <el-select v-model="xslvalue" @change="chagnexsl">
                <el-option label="近一月销售量" value="AvgBuyCount1Mon" />
                <el-option label="近三月销售量" value="AvgBuyCount3Mon" />
                <el-option label="近半年销售量" value="AvgBuyCount6Mon" />
                <el-option label="近一年销售量" value="AvgBuyCount12Mon" />
              </el-select>
            </template>
            <template slot-scope="scope">
              <span v-if="xslvalue === 'AvgBuyCount1Mon'">{{ scope.row.AvgBuyCount1Mon }}</span>
              <span v-if="xslvalue === 'AvgBuyCount3Mon'">{{ scope.row.AvgBuyCount3Mon }}</span>
              <span v-if="xslvalue === 'AvgBuyCount6Mon'">{{ scope.row.AvgBuyCount6Mon }}</span>
              <span v-if="xslvalue === 'AvgBuyCount12Mon'">{{ scope.row.AvgBuyCount12Mon }}</span>
            </template>
          </el-table-column>
          <!-- 近一月销售量 近三月销售量 近半年销售量 近一年销售量 -->
          <el-table-column
            prop="Remark"
            label="行备注"
            show-overflow-tooltip
          />
          <el-table-column
            prop="MaterialStorageConditionName"
            label="储存条件"
            show-overflow-tooltip
          />
          <el-table-column
            fixed="right"
            prop="StockNumV2"
            label="库存"
            show-overflow-tooltip
          />
          <el-table-column
            fixed="right"
            prop="Num"
            label="订单数量"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div v-if="($parent.$refs.orderHeader.orderHeadInfo.OrderType === 1) && ($parent.$refs.orderHeader.orderHeadInfo.POID !== null || $parent.$refs.orderHeader.orderHeadInfo.POID.length > 0)">
                <el-link :disabled="true" :underline="false" type="primary">
                  <span>{{ scope.row.Num }}</span>
                </el-link>
              </div>
              <div v-else>
                <el-link v-if="scope.row.isdisable_num" :disabled="true" :underline="false" type="primary">
                  <span>{{ scope.row.Num }}</span>
                </el-link>
                <el-link v-else :disabled="false" :underline="false" type="primary" @click="updateonenum(scope.row)">
                  <span>{{ scope.row.Num }}</span>
                </el-link>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            fixed="right"
            prop="NumToPR"
            label="转采购"
            show-overflow-tooltip
          />
          <el-table-column
            fixed="right"
            prop="NumLeft"
            label="未发"
            show-overflow-tooltip
          />
          <el-table-column
            fixed="right"
            label="发货"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <el-input v-if="scope.row.UploadFlag === 2" v-model="scope.row.PartNum" :disabled="true" />
              <el-input v-else v-model="scope.row.CurrentSendNum" @blur="checkedNum(scope.row)" />
            </template>
          </el-table-column>
          <el-table-column
            fixed="right"
            prop="NumSend"
            label="已发"
            show-overflow-tooltip
          />
           <!-- <el-table-column
            fixed="right"
            prop="optioncol"
            label="操作"
            width="45px"
          >
            <template slot-scope="scope">
              <el-popover
                v-if="scope.row.UploadFlag === -1"
                placement="left"
                trigger="hover"
                :width="100"
                popper-class="test_pop_view"
              >
                <div class="DropDownList">
                  <div v-if="scope.row.UploadFlag === -1" class="OperationButton">
                    <el-link :underline="false" @click="splitPartMaterial(scope.row, scope.$index)"><i class="el-icon-connection p-right-10" />拆分</el-link>
                  </div>
                  <div v-if="scope.row.UploadFlag === -1" class="OperationButton">
                    <el-link :underline="false" @click="deletePart(scope.row, scope.$index)"><i class="el-icon-circle-close p-right-10" />删拆</el-link>
                  </div>
                   <div v-if="(scope.row.PartID > 0) && (($parent.$refs.orderHeader.orderHeadInfo.OrderType !== 1) || ($parent.$refs.orderHeader.orderHeadInfo.POID === null || $parent.$refs.orderHeader.orderHeadInfo.POID.length < 1))" class="OperationButton">
                    <el-link :underline="false" @click="deleteMaterial(scope.row.IDX)"><i class="el-icon-delete p-right-10" />删除物料</el-link>
                  </div>
                </div>
                <i slot="reference" class="el-icon-more" />
              </el-popover>
            </template>
          </el-table-column> -->
        </el-table>
        <!-- 明细列表 -->
        <el-table
          v-if="Ishandler !== '999'"
          ref="materialListref"
          v-loading="materialListDataloading"
          :header-cell-style="{background:'rgb(247,248,252)'}"
          :data="materialList"
          tooltip-effect="dark"
          show-header
          :span-method="dealrowspandetails"
          max-height="350"
          border
        >
          <af-table-column
            label="序号"
            width="50"
          >
            <template slot-scope="scope">
              <span>{{ scope.$index+1 }}</span>
            </template>
          </af-table-column>
          <af-table-column
            prop="InvCode"
            label="物料编号"
          />
          <af-table-column
            prop="ManuPartNum"
            label="原厂编号"
            show-overflow-tooltip
          />
          <af-table-column
            prop="MaterialName"
            label="物料名称"
            show-overflow-tooltip
          />
          <af-table-column
            prop="Specification"
            label="规格"
            show-overflow-tooltip
          />
          <af-table-column
            prop="BrandName"
            label="品牌"
            show-overflow-tooltip
          />
          <af-table-column
            prop="StockUnitN"
            label="单位"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <span>{{ scope.row.StockUnitN || scope.row.StockUnit }}</span>
            </template>
          </af-table-column>
          <af-table-column
            prop="LotNum"
            label="批号"
            show-overflow-tooltip
          />
          <af-table-column
            prop="ExpiredDate"
            label="效期"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              {{ $minCommon.formatDate1(scope.row.ExpiredDate,'yyyy-MM-dd') }}
            </template>
          </af-table-column>
          <af-table-column
            prop="ProductDate"
            label="生产日期"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              {{ $minCommon.formatDate1(scope.row.ProductDate,'yyyy-MM-dd') }}
            </template>
          </af-table-column>
          <af-table-column
            prop="ContactPrice"
            label="单价"
            show-overflow-tooltip
          />
          <af-table-column
            prop="Num"
            label="订单数量"
            show-overflow-tooltip
          />
          <af-table-column
            prop="PartNum"
            label="拆分数量"
            show-overflow-tooltip
          />
          <af-table-column
            prop="NumSend"
            label="已发数量"
            show-overflow-tooltip
          />
          <af-table-column
            prop="Remark"
            label="行备注"
            show-overflow-tooltip
          />
        </el-table>
        <div class="sumstuff">
          <div class="eachitem flex j-between" style="flex:1">
            <div class="mateialeachitem-eachword sumstuffleft">总计：<span class="p-left-10">共{{ materialList.length }}件商品</span></div>
            <div class="mateialeachitem-eachword sumstuffright m-right-30">货品总计：<span class="p-left-10">{{ zj.toFixed(2) }}元</span></div>
          </div>
          <!-- <div class="eachitem flex j-between" style="flex:1">
            <div class="mateialeachitem-eachword sumstuffleft"><span class="p-left-10" /></div>
            <div class="mateialeachitem-eachword sumstuffright m-right-30">应收总计：<span class="p-left-10">{{ ys }}元</span></div>
          </div>
          <div class="eachitem flex j-between" style="flex:1">
            <div class="mateialeachitem-eachword sumstuffleft"><span class="p-left-10" /></div>
            <div class="mateialeachitem-eachword sumstuffright m-right-30">实收总计：<span class="p-left-10">{{ ss }}元</span></div>
          </div> -->
        </div>
        <div v-if="Ishandler === '999'" class="materiladivbottom p-top-20">
          <el-button v-if="disableoption === false" type="primary" size="mini" @click="$parent.openCretateDelivery">生成交货单</el-button>
          <el-button v-if="disableoption === false" type="primary" size="mini" @click="$parent.dealTransPurchase">转采购</el-button>
          <el-button type="primary" size="mini" plain @click="$parent.comfirmColseOrder()">关闭订单</el-button>
        </div>
      </el-card>
    </div>
    <!-- 拆分处理 -->
    <el-dialog title="拆分处理" :visible.sync="SplitDetailsVisbel" width="80%" :close-on-click-modal="false">
      <div class="p-lr-20">
      <div class="SplitDetailsheader right flex" style="flex:1">
        原数量： {{splitOldNum}}
        当前拆分数量之和 {{splitNum}}
      </div>
      <el-table
        v-loading="SplitDetailsLoading"
        :header-cell-style="{background:'rgb(247,248,252)'}"
        :data="SplitDetailsList"
        tooltip-effect="dark"
        show-header
        border
      >
        <af-table-column
          label="物料编号"
          prop="InvCode"
        />
        <el-table-column
          label="物料名称"
          prop="MaterialName"
        />
        <el-table-column
          label="品牌/规格"
        >
        <template slot-scope="scope">
          <span>{{scope.row.BrandName}}</span>
          <span>{{scope.row.Specification}}</span>
        </template>
        </el-table-column>
        <el-table-column
          label="存储条件"
          prop="MaterialStorageConditionName"
        />
        <af-table-column
          prop="LotNum"
          label="批号"
          show-overflow-tooltip
        />
        <el-table-column
          prop="ExpiredDate"
          label="效期"
          show-overflow-tooltip
        >
        </el-table-column>
        <af-table-column
          prop="LotNo"
          label="批次"
          show-overflow-tooltip
        />
        <af-table-column
          prop="StockCode"
          label="仓位"
          show-overflow-tooltip
        />
        <af-table-column
          prop="PartNum"
          label="数量"
          show-overflow-tooltip
          with="100px"
        >
        <template slot-scope="scope">
              <el-input v-model="scope.row.PartNum" @blur="checkSplitDetailsNum(scope.row)" />
        </template>
        </af-table-column>
        <af-table-column
          prop=""
          label="操作"
          show-overflow-tooltip
          width="50"
          align="center"
        >
          <template slot-scope="scope">
            <el-link v-if="scope.row.PartID <= 0" type="primary" round @click="removeSplitDeatils(scope.row, scope.$index)">删除</el-link>
          </template>
        </af-table-column>
      </el-table>
      <el-divider></el-divider>
      <div style="text-align: center;">
        <el-button type="primary" size="mini" @click="SplitAddDetails()">新增拆分行</el-button>
        <el-button type="primary" size="mini" @click="confirmSaveSplitDetails()">提交</el-button>
        <el-button size="mini" @click="SplitDetailsVisbel  = false">关闭</el-button>
      </div>
      </div>
    </el-dialog>
    <!-- 导入库存信息 -->
    <el-dialog title="导入库存信息" :visible.sync="ImportStockVisbel" width="80%" :close-on-click-modal="false">
      <div class="p-lr-20">
        <div class="m-bottom-10 input_file">
            <el-button  class="m-right-20" plain size="mini" @click="ImportStockOpenFile">上传文件</el-button>
            <input id="file" ref="filElem" type="file" name="file" @change="handleFileChange">
            <el-button  class="m-right-20" plain size="mini" @click="ExportPartStockData">下载可导入明细</el-button>
            <el-button  class="m-right-20" plain size="mini" @click="confirmImportStock">确认导入</el-button>
            <!-- <el-upload
                    style="width:100%;height:auto"
                    class="upload-demo"
                    :action="b2bapicUrl + '/OrderMateialStock_ImportByFile'"
                    :headers="fjHeader"
                    >
                    <el-button size="small" type="primary">上传附件</el-button>
            </el-upload> -->
        </div>
      <el-table
        v-loading="ImportStockLoading"
        :header-cell-style="{background:'rgb(247,248,252)'}"
        :data="ImportStockDataList"
        tooltip-effect="dark"
        show-header
        border
      >
        <af-table-column
          label="物料编号"
          prop="InvCode"
        />
        <af-table-column
          prop="LotNum"
          label="批号"
          show-overflow-tooltip
        />
        <el-table-column
          prop="ExpiredDate"
          label="效期"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="ProductDate"
          label="生产日期"
          show-overflow-tooltip
        >
        </el-table-column>
        <af-table-column
          prop="Num"
          label="数量"
          show-overflow-tooltip
        />
        <af-table-column
          prop=""
          label="操作"
          show-overflow-tooltip
          width="50"
          align="center"
        >
          <template slot-scope="scope">
            <el-link  type="primary" round @click="removeImportStock(scope.$index)">移除</el-link>
          </template>
        </af-table-column>
      </el-table>
      </div>
    </el-dialog>
    <!-- 操作日志查看 -->
    <el-dialog title="操作日志" :visible.sync="logListVisbel" width="80%" :close-on-click-modal="false">
      <div class="p-lr-20">
        <el-table
          v-loading="logListloading"
          :header-cell-style="{background:'rgb(247,248,252)'}"
          :data="optionLogList"
          tooltip-effect="dark"
          show-header
          border
        >
          <af-table-column
            fixed="left"
            type="selection"
            width="40"
          />
          <af-table-column
            prop="OrderID"
            label="订单号"
          />
          <af-table-column
            prop="InvCode"
            label="物料编号"
            show-overflow-tooltip
          />
          <af-table-column
            prop="InvName"
            label="物料名称"
            show-overflow-tooltip
          />
          <!-- <af-table-column
                  prop="gg"
                  label="规格"
                  show-overflow-tooltip
              />
              <af-table-column
                  prop="brandname"
                  label="品牌"
                  show-overflow-tooltip
              /> -->
          <af-table-column
            prop="OperTypeSting"
            label="操作类型"
            show-overflow-tooltip
          />
          <af-table-column
            prop="OldValue"
            label="原数据"
            show-overflow-tooltip
          />
          <af-table-column
            prop="NewValue"
            label="新数据"
            show-overflow-tooltip
          />
          <af-table-column
            prop="NickName"
            label="操作人"
            show-overflow-tooltip
          />
          <af-table-column
            prop="WriteTime"
            label="操作时间"
            show-overflow-tooltip
          />
          <af-table-column
            prop="Remark"
            label="修改原因"
            show-overflow-tooltip
          />
        </el-table>
      </div>
    </el-dialog>
    <!-- 修改原订单数量 -->
    <el-dialog title="修改原订单数量" :visible.sync="updateNumVisbel" width="800px" :close-on-click-modal="false">
      <div class="p-lr-20">
        <div class="orderstatus p-tb-10 flex j-between" style="flex:1">
          <div class="eachupdate-orderstatus">订单编号：<span>{{ orderInfo.OrderID }}</span></div>
          <div class="eachupdate-orderstatus">订单状态：<span>{{ orderInfo.OrderStatusString }}</span></div>
          <div class="eachupdate-orderstatus">创建时间：<span>{{ orderInfo.WriteTime }}</span></div>
        </div>
        <el-table
          :data="orderUpdataNumList"
          tooltip-effect="dark"
          :show-header="true"
        >
          <af-table-column
            prop="InvCode"
            label="物料编号"
          />
          <af-table-column
            prop="MaterialName"
            label="物料名称"
            show-overflow-tooltip
          />
          <af-table-column
            prop="Num"
            label="原数量"
            show-overflow-tooltip
          />
          <af-table-column
            prop="NumSend"
            label="已交货数量"
            show-overflow-tooltip
          />
          <af-table-column
            prop="newNum"
            label="新数量"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <el-input v-model="scope.row.newNum" size="small" />
            </template>
          </af-table-column>
        </el-table>
        <!-- <div v-for="(item, index) in orderUpdataNumList" :key="index" class="orderstatuslist flex j-between" style="flex:1">
        <div class="eachupdatelist p-tb-10">物料编号：<span>{{ item.InvCode }}</span></div><span />
        <div class="eachupdatelist p-tb-10">物料名称：<span>{{ item.MaterialName }}</span></div><span />
        <div class="eachupdatelist p-tb-10">原数量：<span>{{ item.Num }}</span></div><span />
        <div class="eachupdatelist p-tb-10">已交货数量：<span>{{ item.NumSend }}</span></div><span />
        <div class="eachupdatelist p-tb-10 nowrap">新数量：<el-input v-model="item.newNum" style="width:80%" size="small" /></div><span />
      </div> -->
        <div class="nowrap m-top-20 flex">
          修改理由：<el-input v-model="updateNumReason" size="small" />
        </div>
        <div slot="footer" class="caozuo t-right p-top-20">
          <el-button @click="updateNumVisbel = false">取 消</el-button>
          <el-button type="primary" :loading="buttonloading" @click="confirmUpdateNum">确 定</el-button>
        </div>
      </div>
    </el-dialog>
    <!-- 单个修改库存 -->
    <el-dialog title="选择库存" :visible.sync="updatestockVisbel" width="800" :close-on-click-modal="false">
      <div class="p-lr-20">
        <div style="color:red;text-align: right;" class="m-bottom-5">未找到可用库存?
          <el-link type="primary" :underline="false" @click="openOneInputStock()">现在录入</el-link></div>
        <el-table
          v-loading="stockListloading"
          :header-cell-style="{background:'rgb(247,248,252)'}"
          :data="stockList"
          tooltip-effect="dark"
          show-header
          border
        >
          <af-table-column
            label="序号"
            width="45"
            align="center"
          >
            <template slot-scope="scope">
              <span>{{ scope.$index+1 }}</span>
            </template>
          </af-table-column>
          <af-table-column
            prop="INDATE"
            label="入库时间"
            width="90"
          >
            <template slot-scope="scope">
              <span>{{ (scope.row.INDATE && scope.row.INDATE.length > 0) ? $minCommon.formatDate(new Date(scope.row.INDATE),'yyyy-MM-dd') : '' }}</span>
            </template>
          </af-table-column>
          <af-table-column
            prop="LotNum"
            label="批号"
            show-overflow-tooltip
          />
          <af-table-column
            prop="LotNo"
            label="批次号"
            show-overflow-tooltip
          />
          <af-table-column
            prop="ExpiredDate"
            label="效期"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              {{ $minCommon.formatDate1(scope.row.ExpiredDate,'yyyy-MM-dd') }}
            </template>
          </af-table-column>
          <af-table-column
            prop="StockNum"
            label="库存量"
            show-overflow-tooltip
          />
          <af-table-column
            prop="LFIMG"
            label="冻结"
            show-overflow-tooltip
          />
          <af-table-column
            prop="StockCode"
            label="所属仓库"
            show-overflow-tooltip
          />
          <af-table-column
            prop="ProductDate"
            label="生产日期"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              {{ $minCommon.formatDate1(scope.row.ProductDate,'yyyy-MM-dd') }}
            </template>
          </af-table-column>
          <af-table-column
            prop=""
            label="操作"
            show-overflow-tooltip
            width="50"
            fixed="right"
            align="center"
          >
            <template slot-scope="scope">
              <el-link v-if="scope.row.StockNum > 0" type="primary" round @click="confirmUseStock(scope.row)">使用</el-link>
              <span v-if="scope.row.StockNum < 1" style="color:red">无可用</span>
            </template>
          </af-table-column>
        </el-table>
      </div>
    </el-dialog>
    <!-- 单个修改合约 -->
    <el-dialog title="重设合约" :visible.sync="updatecontractVisbel" width="600px" :close-on-click-modal="false">
      <div class="p-lr-20">
        <el-table
          v-loading="contractListloading"
          :header-cell-style="{background:'rgb(247,248,252)'}"
          :data="contractList"
          tooltip-effect="dark"
          show-header
          border
        >
          <af-table-column
            label="序号"
            width="60"
          >
            <template slot-scope="scope">
              <span>{{ scope.$index+1 }}</span>
            </template>
          </af-table-column>
          <af-table-column
            prop="SAPContractID"
            label="合约编号"
          />
          <af-table-column
            prop="ContractName"
            label="合约名称"
            show-overflow-tooltip
          />
          <af-table-column
            prop="InvCode"
            label="物料编号"
            show-overflow-tooltip
          />
          <af-table-column
            prop="LatestAmount"
            label="合约价"
            show-overflow-tooltip
          />
          <af-table-column
            prop=""
            label="操作"
            show-overflow-tooltip
            width="50"
            align="center"
          >
            <template slot-scope="scope">
              <el-link type="primary" plain @click="confirmupdatecontract(scope.row)">使用</el-link>
            </template>
          </af-table-column>
        </el-table>
      </div>
    </el-dialog>
    <!-- 新增产品 -->
    <el-dialog title="添加商品" :visible.sync="addMaterialVisbel" width="80%" :close-on-click-modal="false">
      <div class="p-lr-20">
        <div class="searchView  flex a-center m-bottom-20 j-between">
          <div class="flex" style="flex:1">
            <el-input v-model="listaddmaterialParas.keyWords" size="medium  " placeholder="请输入关键字" class="rule-input-edit">
              <template slot="prepend">
                <div class="cursor" @click="AdvancedsearchmaterialVisibel = true">
                  <i class="el-icon-s-operation" />高级搜索</div></template>
              <div slot="append" @click="getAddMaterialMaterlaiList()">搜  索</div>
            </el-input>
            <el-input v-model="addMaterialReason" size="medium  " placeholder="请填写原因" class="rule-input-edit m-left-10">
              <template slot="prepend">
                <div slot="append" class="cursor">
                  原因</div></template>
            </el-input>
            <div class="out p-left-10  flex a-center">
              <el-button size="medium" @click="confirmAddMaterial"><i class="el-icon-circle-plus-outline m-right-10" />添加选中</el-button>
            </div>
          </div>
        </div>
      </div>
      <div class="p-lr-20">
        <el-table
          v-loading="addMaterialloading"
          :header-cell-style="{background:'rgb(247,248,252)'}"
          :data="addMaterialList"
          tooltip-effect="dark"
          show-header
          border
          @selection-change="addmaterhandleSelectionChange"
        >
          <af-table-column
            fixed="left"
            type="selection"
            :selectable="addmaterilselectable"
            width="40"
          />
          <af-table-column
            prop="InvCode"
            label="物料编号"
          />
          <af-table-column
            prop="MaterialName"
            label="物料名称"
            show-overflow-tooltip
          />
          <af-table-column
            prop="MaterialBrandName"
            label="品牌"
            show-overflow-tooltip
          />
          <af-table-column
            prop="MaterialTypeName"
            label="物料类型"
            show-overflow-tooltip
          />
          <af-table-column
            prop="MaterialSpefication"
            label="规格/型号"
            show-overflow-tooltip
          />
          <af-table-column
            prop="StockUnitN"
            label="单位"
            show-overflow-tooltip
          />
          <af-table-column
            prop="LatestAmount"
            label="合约价格"
            show-overflow-tooltip
          />
          <af-table-column
            prop="addnum"
            label="数量"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <el-input v-model="scope.row.addnum" />
            </template>
          </af-table-column>
          <af-table-column
            prop="ContractName"
            label="所属合约"
            show-overflow-tooltip
          />
          <af-table-column
            prop=""
            label="操作"
            show-overflow-tooltip
            fixed="right"
            width="50"
            align="center"
          >
            <template slot-scope="scope">
              <el-link v-if="!scope.row.ischecked" type="primary" :underline="false" @click="confirmAddoneMaterial(scope.row)">添加</el-link>
            </template>
          </af-table-column>
        </el-table>
      </div>
      <div class="pagination-container p-right-20">
        <el-pagination
          :total="addMateriallistSize"
          :current-page="listaddmaterialParas.PageIndex"
          :page-size="listaddmaterialParas.PageSize"
          :background="true"
          layout="prev, pager, next, jumper"
          @current-change="addMateriallistCurrentChange"
        />
      </div>
      <!-- <el-divider></el-divider>
            <div style="text-align: center;">
                <el-button @click="addMaterialVisbel = false">取 消</el-button>
                <el-button type="primary" @click="confirmAddMaterial">确 定</el-button>
            </div> -->
    </el-dialog>
    <!-- 物料高级搜索 -->
    <el-dialog class="DepartmentBox MaterialAdvanced" title="物料高级搜索" :visible.sync="AdvancedsearchmaterialVisibel" width="800px" :close-on-click-modal="false">
      <el-form label-position="right" label-width="100px" :inline="true" :model="listaddmaterialParas" size="mini" class="advanser">
        <el-form-item label="时间区间">
          <el-date-picker
            v-model="listaddmaterialParas.CertificateDeadLineBeginDate"
            type="date"
            value-format="yyyy-MM-dd"
            class="time"
          />
          <span class="jiange">-</span>
          <el-date-picker
            v-model="listaddmaterialParas.CertificateDeadLineEndDate"
            type="date"
            value-format="yyyy-MM-dd"
            class="time"
          />
        </el-form-item>
        <el-form-item label="物料编号">
          <el-input
            v-model="listaddmaterialParas.invCode"
            size="mini"
            placeholder=""
          />
        </el-form-item>
        <el-form-item label="品牌">
          <el-input
            v-model="listaddmaterialParas.brandName"
            size="mini"
            placeholder=""
          />
        </el-form-item>
        <el-form-item label="物料名称">
          <el-input
            v-model="listaddmaterialParas.invName"
            size="mini"
            placeholder=""
          />
        </el-form-item>
        <el-form-item label="物料类型">
          <el-input
            v-model="listaddmaterialParas.materialTypeName"
            size="mini"
            placeholder=""
          />
        </el-form-item>
        <el-form-item label="物料简称">
          <el-input
            v-model="listaddmaterialParas.nickName"
            size="mini"
            placeholder=""
          />
        </el-form-item>
        <el-form-item label="供应商">
          <el-select v-model="listaddmaterialParas.sellcompanyid" placeholder="" filterable>
            <el-option v-for="(item, index) in SellcompanyList" :key="index" :label="item.companyName" :value="item.companyID" />
          </el-select>
        </el-form-item>
        <el-form-item label="注册证号">
          <el-input
            v-model="listaddmaterialParas.zcz"
            size="mini"
            placeholder=""
          />
        </el-form-item>
        <el-form-item label="UDI码">
          <el-input
            v-model="listaddmaterialParas.udi"
            size="mini"
            placeholder=""
          />
        </el-form-item>
        <el-form-item label="原厂编号">
          <el-input
            v-model="listaddmaterialParas.ycbh"
            size="mini"
            placeholder=""
          />
        </el-form-item>
        <el-form-item label="生产厂家">
          <el-input
            v-model="listaddmaterialParas.sccj"
            size="mini"
            placeholder=""
          />
        </el-form-item>
      </el-form>
      <div class="caozuo p-tb-20 t-line m-top-10">
        <el-button @click="AdvancedsearchmaterialVisibel = false">取 消</el-button>
        <el-button type="primary" @click="AdvancedsearchmaterialVisibel= false">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 编辑行订单备注 -->
    <el-dialog title="编辑行订单备注" :visible.sync="addOrderdetailRemarkVisibel" width="40%" :close-on-click-modal="false">
      <el-form label-position="right" label-width="20%" :model="addMaterialDetailRemarkRow" size="mini" class="demo-form-inline">
        <el-input
          v-model="addMaterialDetailRemarkRow.Remark"
          type="textarea"
          :rows="3"
          placeholder="请输入订单物料备注"
        />
      </el-form>
      <div class="center m-top-20">
        <el-button @click="addOrderdetailRemarkVisibel = false">取 消</el-button>
        <el-button type="primary" @click="SaveOrderDetailsPartRemark">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 批量删除物料添加备注 -->
    <el-dialog title="批量删除物料添加备注" :visible.sync="deleteMaterilaReasonvisible" width="30%" :close-on-click-modal="false">
      <el-form label-position="right" label-width="20%" size="mini" class="demo-form-inline">
        <el-input
          v-model="deleteMaterilaReason"
          type="textarea"
          :rows="3"
          placeholder="请输入订单物料备注"
        />
      </el-form>
      <div class="center m-top-20">
        <el-button @click="deleteMaterilaReasonvisible = false">取 消</el-button>
        <el-button type="primary" @click="validdeltematerial">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 手动入库 -->
    <el-dialog title="手动入库" :visible.sync="inputStockVisbel" width="80%" :close-on-click-modal="false">
      <div class="p-lr-20">
        <label>
          入库仓位：
          <el-select v-model="inputStockData.Location" class="m-bottom-10" placeholder="请选择入库仓位" size="mini" :clearable="true">
            <el-option v-for="(item,index) in locatList" :key="index" :label="item.StockCode" :value="item.StockCode" />
          </el-select>
        </label>
        <el-table
          :header-cell-style="{background:'rgb(247,248,252)'}"
          :data="inputStockData.Detals"
          tooltip-effect="dark"
          show-header
          border
        >
          <af-table-column
            prop="InvCode"
            label="物料编号"
          />
          <af-table-column
            prop="MaterialName"
            label="物料名称"
            show-overflow-tooltip
          />
          <af-table-column
            prop="BrandName"
            label="品牌"
            show-overflow-tooltip
          />
          <af-table-column
            prop="Specification"
            label="规格/型号"
            show-overflow-tooltip
          />
          <af-table-column
            prop="StockUnitN"
            label="单位"
            show-overflow-tooltip
          />
          <af-table-column
            prop="StockNum"
            label="入库数量"
            show-overflow-tooltip
          />
          <af-table-column
            prop="LotNum"
            label="批号/序列号"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <el-input v-model.trim ="scope.row.LotNum" />
            </template>
          </af-table-column>
          <af-table-column
            prop="ExpiredDate"
            label="效期"
            width="165"
          >
            <template slot-scope="scope">
              <el-date-picker
                v-model="scope.row.ExpiredDate"
                type="date"
                style="width:140px"
                value-format="yyyy-MM-dd"
              />
            </template>
          </af-table-column>
          <af-table-column
            prop="ProductDate"
            label="生产日期"
            width="165"
          >
            <template slot-scope="scope">
              <el-date-picker
                v-model="scope.row.ProductDate"
                type="date"
                style="width:140px"
                value-format="yyyy-MM-dd"
              />
            </template>
          </af-table-column>
        </el-table>
      </div>
      <el-divider />
      <div class="caozuo t-right p-right-20">
        <el-button @click="inputStockVisbel = false">取 消</el-button>
        <el-button type="primary" @click="confirminputStock">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import orderdetailMaterialList from '@/minxin/orderMananger/orderdetailMaterialList.js'
export default orderdetailMaterialList
</script>
<style scoped lang='scss'>
@import '@/style/index.scss';
@import '@/style/search.scss';
@import '@/style/main.scss';
@import '@/style/order.scss';
</style>
<style>
.materiallist .el-input__inner {
    border-radius: 20px 0px 0px 20px;
}
.el-card__header {
  background-color: #f1f9ff;
}
</style>
