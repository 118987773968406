import PageHeader from '@/components/PageHeader.vue'
import $orderReq from '@/request/orderApi.js'
import $comjs from '@/minxin/commonJs.js'
const orderdetailMaterialList = {
  name: 'OrderdetailMaterialList',
  components: {
    PageHeader
  },
  props: {
    OrderID: {
      type: String,
      default: ''
    },
    Ishandler: {
      type: String,
      default: '-1'
    }
  },
  computed: {
    a(a) {
      return (a) => {
        return this.$minCommon.formatDate(new Date(a), 'yyyy/MM/dd')
      }
    }
  },
  data() {
    return {
      buttonloading: false,
      KeyWords: '',
      orderInfo: {
        OrderID: '',
        OrderStatusString: '部分处理',
        WriteTime: '2021-03-06'
      },
      flexnone: 'false',
      flexleft: 'left',
      flexright: 'right',
      optionLogList: [
        {
          IDX: 1,
          OrderID: '4324',
          InvCode: 'fds',
          InvName: 'fds',
          // gg: 'fdsa',
          // brandname: 'fsdaf',
          OperTypeSting: 'fsdf', // 操作类型
          OldValue: 100,
          NewValue: 100,
          NickName: 'fda',
          WriteTime: '2021-03-25',
          Remark: 'fdsaf '
        }
      ],
      logListVisbel: false, // 显示
      logListloading: false, // 加载
      // 物料清单   begin ==========================begin
      materialList: [
        {
          IDX: 12,
          InvCode: '100001',
          ManuPartNum: '原厂编号',
          MaterialName: '物料名称',
          Specification: '规格',
          StandardName: '品牌',
          LotNo: '批号', // 批号
          ExpiredDate: '有效期截至', // 有效期截至
          ContractName: '合约',
          NumToPR: 312, // 转采购数量
          ContactPrice: 4234, // 价格
          ODP_ProductDate: '2021-03-02', // 生产日期
          StockUnitN: 'fdsfsa', // 单位
          Location: 'fdsa', // 仓位
          gml: 1, // 近几月购买量
          MaterialStorageConditionName: 'fdsaf', // 储存条件
          StockNumV2: 100, // 总库存量
          Num: 10, // 订单数量
          NumLeft: 10, // 未发数量
          PartNum: 0, // 发货数量
          NumSend: 0, // 已发数量
          optioncol: null, // 操作列
          spancolnum: 0, // 合并行数
          isberowspaned: false, // 是否被合并
          Remark: 'fdsaf' // 行备注
        }
      ],
      materialListall: [], // 所有物料
      materialListDataloading: false, // 加载
      materialCheckedList: [], // 物料选择对象
      materialCheckedarry: [], // 物料选择明细id数组
      materialCheckedPartarry: [], // 物料选择拆分明细id数组
      // 价格计算
      zj: 0, // 总计
      ys: 0, // 应收
      ss: 0, // 实收
      // 物料清单   end ==========================end
      // 批量修改原数量 begin ==========================begin
      updateNumVisbel: false,
      orderUpdataNumList: [
        // {
        //   orderdetailsId: 1,
        //   invCode: 'fdsa',
        //   invname: 'fdaf',
        //   oldNum: 12,
        //   yjnum: 11, // 已交货数量
        //   newNum: 13 // 新数量
        // },
        // {
        //   orderdetailsId: 2,
        //   invCode: 'fdsfdsafda',
        //   invname: 'fdsa',
        //   oldNum: 12,
        //   yjnum: 11, // 已交货数量
        //   newNum: 13 // 新数量
        // }
      ],
      updateNumReason: '', // 修改理由
      // 批量修改原数量 end ==========================end
      // 修改库存begin ==========================begin
      updatestockVisbel: false,
      stockList: [
        {
          StockID: 1,
          WriteTime: '2021-03-06', // 入库时间,
          LotNo: 'fdfds', // 批号,
          LotNum: 'fdfsad', // 批次号,
          ExpiredDate: '2021-03-06', // 有效期截至,
          StockNum: 2, // 库存量,
          FrozenNum: 10, // 冻结
          StockCode: 'fdsafs', // 所属仓库
          ProductDate: '2021-03-03' // 生产日期
        }
      ],
      stockListloading: false,
      // 修改库存end ==========================end
      // 修改合约begin ==========================begin
      updatecontractVisbel: false,
      contractList: [
        {
          IDX: 123,
          SAPContractID: '43432', // 合约编号,
          ContractName: 'fdfds', // 合约名称,
          InvCode: 'fdfsad', // 物料编号,
          LatestAmount: '2021-03-06' // 合约价
        }
      ],
      contractListloading: false,
      // 修改合约end ==========================end
      // 添加商品 begin ==========================begin==========================
      // 物料明细添加物料
      addMaterialReason: '', // 新增物料 原因
      addMaterialVisbel: false,
      addMaterialloading: false,
      addMaterialList: [
        {
          IDX: 1,
          InvCode: '1123123',
          ManuPartNum: 'dsfsa',
          MaterialName: '范德萨发散的防守打法撒发送',
          MaterialSpefication: 'fdsaffdsf',
          StockUnitN: '盒、box',
          MaterialTypeName: 'fdsa',
          MaterialBrandName: 'fdsa',
          operater: 'fsafs',
          LatestAmount: null, // 含税价格
          addnum: null, // 数量
          ContractName: 'dfsafd' // 所属合约
        }
      ],
      checkedAddMaterial: [], // 选中的添加物料数据
      // 物料高级搜索
      listaddmaterialParas: {
        KeyWords: '',
        SellCompanyID: -1,
        invCode: '', // 物料编号
        CertificateDeadLineBeginDate: '',
        CertificateDeadLineEndDate: '',
        brandName: '', // 品牌
        invName: '', // 物料名称
        materialTypeName: '', // 物料类型
        nickName: '', // 物料简称
        OrderID: '',
        PageIndex: 1,
        PageSize: 10
      }, // 物料搜索参数
      AdvancedsearchmaterialVisibel: false,
      addMateriallistSize: null,
      SellcompanyList: [],
      // 添加商品 end ==========================end==========================
      // 处理单行数据
      materialCurrentRow: {
        index: -1,
        row: null
      },
      _dealrowindex: -1,
      _dealrownextindex: -1,
      // 行物料处理  ==========================begin==========================
      addMaterialDetailRemarkRow: {},
      addOrderdetailRemarkVisibel: false,
      // 行物料处理  ==========================end==========================
      isshowall: false, // 隐藏 2 的明细（点击显示全部 则显示
      showalltext: '显示全部',
      orderuploadFlags: [
        { text: '全部', value: 1 },
        { text: '未完全处理', value: 2 }
      ], // 物料明细 显示uploadflags
      deleteMaterilaReasonvisible: false,
      deleteMaterilaReason: '', // 删除物料明细原因
      xslvalue: 'AvgBuyCount1Mon', // 近几月销售量
      deleteaMaterialIdx: 0, // 要删除物料行id
      deletePartMateialitem: [], // 点完保存后清空该字段
      hiderows: [], // 隐藏的行数据
      // 所有数据
      materialListcopyforhide: [],
      // 显示隐藏生成交货单/转采购
      disableoption: true,
      // ================================================= 录入库存 begin  =================================================
      locatList: [],
      inputStockData: {
        Detals: [
          // {
          //   InvCode: '', // 物料编号
          //   MaterialName: '', // 物料名称
          //   BrandName: '', // 品牌
          //   Specification: '', // 规格
          //   StockUnitN: '', // 单位
          //   StockNum: '', // 入库数量  去拆分行的数量
          //   LotNum: '', // 批号
          //   ExpiredDate: '', // 效期
          //   ProductDate: '' // 生产日期
          // }
        ],
        Location: '' // 入库仓位
      },
      inputStockVisbel: false,
      // ================================================= 录入库存 end  =================================================
      //  ==============================================================导入库存 begin =========================================
      ImportStockVisbel: false,
      ImportStockLoading: false,
      ImportStockDataList: [
        {
          InvCode: '物料编号',
          LotNum: '批号',
          ExpiredDate: '2021-09-09',
          ProductDate: '2021-09-09',
          Num: '数量'
        }
      ],
      //  ==============================================================导入库存 end =========================================
      //  ==============================================================拆分 begin =========================================
      SplitDetailsVisbel: false,
      SplitDetailsLoading: false,
      SplitDetailsList: [
        {
          InvCode: '111',
          MaterialName: '111',
          BrandName: '111',
          Specification: '111',
          LotNum: '111',
          LotNo: '111',
          ExpiredDate: '111',
          StockCode: '111',
          MaterialStorageConditionName: '111',
          PartNum: 111,
          MaterialStockSellID: 0,
          PartID: 0
        }
      ],
      splitOldNum: 0,
      splitNum: 0,
      currentSplitDetailsRow: null
      //  ==============================================================拆分 end =========================================
    }
  }, // data ends
  mounted() {
    // 获取订单表头信息
    this.getOrderMaterialList()
    this.setDefaultFilter(1, 2) // 设置第一列  值为1
  },
  methods: {
    // 删除拆分行数据
    confirmDeletePartDetails(_row) {
      // 校验数据是否可被删
      let count = 0
      this.materialList.forEach(ele => {
        if (ele.UploadFlag === -1 && ele.IDX === _row.IDX) {
          count++
        }
      })
      if (count <= 1) {
        this.$message.error('当前物料只剩一个拆分行不允许被删拆')
        return false
      }
      const _this = this
      _this.$confirm('确认删除拆分行吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        _this.DeletePartDetails(_this, _row)
      }).catch(() => {
      })
    },
    DeletePartDetails(_this, _row) {
      _this.materialListDataloading = true
      $orderReq.DeleteOrderDetailsPartByIDX({ OrderID: _this.OrderID, PartID: _row.PartID, OrderGuid: localStorage.getItem('OrderGuid') }).then((res) => {
        _this.materialListDataloading = false
        if (res.RetCode !== '0') return this.$message.error('错误：' + res.RetMsg)
        _this.getOrderMaterialList()
        _this.$message.success('删除成功')
        // 存入新的guid
        localStorage.setItem('OrderGuid', res.OrderGuid)
      })
    },
    //  ==============================================================拆分 begin =========================================
    // 确认保存
    confirmSaveSplitDetails() {
      // 校验是否有 未填写数量 拆分总和不等于原数量
      if (this.splitOldNum !== this.splitNum) {
        this.$message.error('拆分行数量总和与原数量不相等')
        return false
      }
      let isgoing = true
      this.SplitDetailsList.forEach(ele => {
        if (ele.PartNum <= 0) {
          isgoing = false
          return false
        }
      })
      if (!isgoing) {
        this.$message.error('拆分行数量为 0')
        return false
      }
      if (this.SplitDetailsList.length === 1) {
        this.$message.error('当前未作任何拆分动作')
        return false
      }
      const _this = this
      _this.$confirm('确认保存拆分行吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 处理数据
        const _data = []
        // 需保存的数据
        _this.SplitDetailsList.forEach(ele => {
          _data.push(
            {
              OrderDetailsID: ele.IDX,
              PartID: ele.PartID,
              StockID: (!ele.MaterialStockSellID || ele.MaterialStockSellID < 1) ? 0 : ele.MaterialStockSellID,
              DelFlag: 0,
              Num: ele.PartNum
            }
          )
        })
        // 调用删除接口
        _this.saveMaterialDetails(_data)
      }).catch(() => {
      })
    },
    // 打开拆分框
    openSplitDetails(_row) {
      this.currentSplitDetailsRow = _row
      this.SplitDetailsList = []
      this.SplitDetailsList.push(_row)
      this.splitOldNum = _row.PartNum
      this.splitNum = _row.PartNum
      this.SplitDetailsVisbel = true
    },
    // 计算拆分总和
    sumSplitDetails() {
      let _splitNum = 0
      this.SplitDetailsList.forEach(ele => {
        _splitNum = _splitNum + parseFloat(ele.PartNum)
      })
      this.splitNum = _splitNum
    },
    // 新增拆分行
    SplitAddDetails() {
      const _this = this
      let isgoing = true
      // splice(1,0,4)  1:要插入的索引 0 不删除元素 4 插入的元素
      _this.SplitDetailsList.forEach((ele) => {
        if (ele.PartNum <= 0) {
          isgoing = false
          _this.$message.warning('请先处理未填写数量的拆分行再进行操作')
          return false
        }
      })
      if (isgoing) {
        const _copyRow = {}
        // 复制当前行
        Object.assign(_copyRow, this.currentSplitDetailsRow)
        _copyRow.LotNum = null
        _copyRow.LotNo = null
        _copyRow.MaterialStockSellID = 0
        _copyRow.PartID = 0
        _copyRow.ExpiredDate = null
        _copyRow.PartNum = 0
        console.log(_copyRow)
        _this.SplitDetailsList.push(_copyRow)
      }
    },
    // 校验拆分填写的数量
    checkSplitDetailsNum(_row) {
      const _this = this
      if ($comjs.checkedNum(_row.PartNum) < 0) {
        _this.$message.warning('请输入数字')
        _row.PartNum = 0
        return false
      } else {
        if (_row.PartNum <= 0) {
          _this.$message.warning('请输入大于0 的数字')
          _row.PartNum = 0
          return false
        }
      }
      this.sumSplitDetails()
    },
    // 删除拆分行
    removeSplitDeatils(_row, _index) {
      if (_row.PartID > 0) {
        this.$message.error('不能删除原行')
        return false
      }
      this.SplitDetailsList.splice(_index, 1)
      this.sumSplitDetails()
    },
    //  ==============================================================拆分 end =========================================
    //  ==============================================================导入库存 begin =========================================
    // 下载可导入拆分明细
    ExportPartStockData() {
      const _this = this
      _this.$parent.fullscreenLoading = true
      $orderReq.OrderMateialStock_ExportData({ OrderID: _this.OrderID }).then(res => {
        _this.$parent.fullscreenLoading = false
        if (res.RetCode !== '0') return this.$message.error('错误：' + res.RetMsg)
        _this.$minCommon.downloadFile(res.Message)
      })
    },
    // 确认导入库存
    confirmImportStock() {
      const _this = this
      if (!_this.ImportStockDataList || _this.ImportStockDataList.length <= 0) {
        _this.$message.error('当前无数据可导入')
        return
      }
      this.$confirm('确认提交发货清单?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'info'
      }).then(() => {
        _this.ImportStock()
      }).catch(() => {
      })
    },
    // 调用导入库存发货接口
    ImportStock() {
      const _this = this
      _this.materialListDataloading = true
      console.log(_this.ImportStockDataList)
      $orderReq.OrderMateialStock_ImportData({ OrderID: _this.OrderID, details: _this.ImportStockDataList, OrderGuid: localStorage.getItem('OrderGuid') }).then((res) => {
        _this.materialListDataloading = false
        if (res.RetCode !== '0') return this.$message.error('错误：' + res.RetMsg)
        _this.getOrderMaterialList()
        _this.$message.success('数据录入成功')
        // 存入新的guid
        localStorage.setItem('OrderGuid', res.OrderGuid)
        _this.ImportStockVisbel = false
      })
    },
    // 打开文件上传框
    openImportStock() {
      // 数据校验 循环 物料明细 列表 materialList
      let isging = true
      let message = ''
      this.materialList.forEach(ele => {
        if (ele.UploadFlag === -1 && (ele.MaterialStockSellID && ele.MaterialStockSellID > 0)) {
          isging = false
          message = '物料：' + ele.InvCode + ' 有库存未处理 ，请先清空或者生成交货单。'
          return isging
        }
      })
      if (!isging) {
        this.$message.error(message)
        return false
      }
      this.ImportStockDataList = []
      this.ImportStockVisbel = true
    },
    ImportStockOpenFile() {
      this.$refs.filElem.dispatchEvent(new MouseEvent('click'))
    },
    // 移除明细
    removeImportStock(_index) {
      this.ImportStockDataList.splice(_index, 1)
    },
    // 上传文件
    handleFileChange(e) {
      const file = e.target.files[0]
      var reader = new FileReader()
      //   const url = ''
      reader.readAsDataURL(file)
      const this_ = this
      // this.form.FileExtName = '.' + file.type.split('/')[1]
      const FileExtName = file.name.split('.')[file.name.split('.').length - 1]
      reader.onload = function() {
        // encodeURIComponent(e.target.result)
        console.log(this.result)
        this_.OrderMateialStock_ImportUpload(this.result, FileExtName)
      }
    },
    // 调用导入接口
    OrderMateialStock_ImportUpload(FileData, FileExtName) {
      // 处理表格数据
      const obj = {
        FileData: FileData,
        FileType: FileExtName
      }
      $orderReq.OrderMateialStock_ImportUpload(obj).then(res => {
        // 将文件清空
        this.$refs.filElem.value = null
        if (res.RetCode !== '0') return this.$message.error('错误：' + res.RetMsg)
        this.$message.success('导入成功')
        this.ImportStockDataList = res.Message
      })
    },
    //  ==============================================================导入库存 end =========================================
    //  ==============================================================库存录入 begin =========================================
    // 打开录入库存框
    openInputStock() {
      const _this = this
      if (!_this.materialCheckedList || _this.materialCheckedList.length < 1) {
        _this.$message.warning('请选择你要操作的物料')
        return false
      }
      _this.getLocation()
      // 处理修改框数据
      _this.inputStockData.Location = null
      _this.inputStockData.Detals = []
      const _arr = []
      _this.materialCheckedList.forEach((ele) => {
        if (_arr.indexOf(ele.IDX) < 0) {
          _arr.push(ele.IDX)
          _this.inputStockData.Detals.push(
            {
              PartID: ele.PartID,
              MateialRefCompanyID: ele.MaterialRefCompanyID,
              InvCode: ele.InvCode,
              MaterialName: ele.MaterialName,
              BrandName: ele.BrandName,
              Specification: ele.Specification,
              StockUnitN: ele.StockUnit || ele.StockUnitN,
              StockNum: ele.PartNum,
              LotNum: null,
              ExpiredDate: null,
              ProductDate: null
            }
          )
        }
      })
      this.inputStockVisbel = true
    },
    // 单个录入库存
    openOneInputStock() {
      const _this = this
      const row = _this.materialCurrentRow.row
      _this.getLocation()
      _this.inputStockData.Location = null
      _this.inputStockData.Detals = []
      _this.inputStockData.Detals.push(
        {
          PartID: row.PartID,
          MateialRefCompanyID: row.MaterialRefCompanyID,
          InvCode: row.InvCode,
          MaterialName: row.MaterialName,
          BrandName: row.BrandName,
          Specification: row.Specification,
          StockUnitN: row.StockUnit || row.StockUnitN,
          StockNum: row.PartNum,
          LotNum: null,
          ExpiredDate: null,
          ProductDate: null
        })
      _this.inputStockVisbel = true
    },
    // 确认录入库存
    confirminputStock() {
      // 校验数据是否都填写  （批号、效期 不能为空
      let isgoing = true
      this.inputStockData.Detals.forEach((ele) => {
        if ((!ele.LotNum || ele.LotNum.length < 1) || !ele.ExpiredDate || ele.ExpiredDate.length < 1) {
          this.$message.error('物料：' + ele.InvCode + ' 未填写 批号/序列号或效期字段')
          isgoing = false
          return false
        }
      })
      if (isgoing) {
        // 弹出确认框
        this.$confirm('确认手工入库吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'info'
        }).then(() => {
          this.inputStock()
        }).catch(() => {
        })
      }
    },
    // 录入库存
    inputStock() {
      // 处理数据
      // 请求接口
      const _this = this
      _this.$parent.fullscreenLoading = true
      $orderReq.InputStockByOrder(_this.inputStockData).then((res) => {
        _this.$parent.fullscreenLoading = false
        if (res.RetCode === '0') {
          _this.$message.success('录入成功')
          _this.inputStockVisbel = false
          _this.updatestockVisbel = false
          _this.getOrderMaterialList()
        } else {
          _this.$message.error('手动录入库存失败：' + res.RetMsg)
        }
      })
    },
    // 获取仓位接口
    getLocation() {
      const _this = this
      _this.$api.GetStockCodeList({ KeyWords: '', PageIndex: 1, PageSize: -1 }).then((res) => {
        console.log(res)
        if (res.RetCode === '0') {
          _this.locatList = res.Message
        } else {
          console.log('获取仓位列表出错： ' + res.RetMsg)
        }
      })
    },
    //  ==============================================================库存录入 end =========================================
    // 保存订单明细处理
    confirmSave() {
      const _this = this
      // 校验数据
      // 1: 每个物料的拆分行所填写的数量必须要 = 未发数量
      let isgong = true
      const _arr = []
      let allnum = 0 // 所填写总数相加
      // 已发数量总和 PartNum
      let allPartNum = 0
      let totalnum = 0 // 原数量
      // let leftnum = 0 // 物料未发数量
      _this.materialList.forEach((ele) => {
        if (_arr.indexOf(ele.IDX) < 0) {
          allnum = 0
          totalnum = ele.Num
          allPartNum = 0
          // leftnum = ele.NumLeft
          _arr.push(ele.IDX)
          _this.materialList.forEach((item) => {
            if (item.IDX === ele.IDX) {
              if (item.UploadFlag === -1) {
                allnum = allnum + parseFloat(item.CurrentSendNum)
              }
            }
          })
          _this.hiderows.forEach(hide => {
            if (hide.IDX === ele.IDX) {
              allPartNum = allPartNum + parseFloat(hide.PartNum)
            }
          })
          // 循环结束
          if (totalnum !== allnum + allPartNum) {
            isgong = false
            _this.$message.warning('物料 ' + ele.InvCode + ' 所填写的数量不对应')
            return false
          }
        }
      })
      if (isgong) {
        _this.$confirm('确认保存所有操作吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          // 处理数据
          const _data = []
          // 需保存的数据
          _this.materialList.forEach(ele => {
            if (ele.UploadFlag === -1) {
              _data.push(
                {
                  OrderDetailsID: ele.IDX,
                  PartID: ele.PartID,
                  StockID: (!ele.MaterialStockSellID || ele.MaterialStockSellID < 1) ? 0 : ele.MaterialStockSellID,
                  DelFlag: ele.DelFlag,
                  Num: ele.CurrentSendNum
                }
              )
            }
          })
          // 要删除的数据
          _this.deletePartMateialitem.forEach(ele => {
            _data.push(
              {
                OrderDetailsID: ele.IDX,
                PartID: ele.PartID,
                StockID: (!ele.MaterialStockSellID || ele.MaterialStockSellID < 1) ? 0 : ele.MaterialStockSellID,
                DelFlag: ele.DelFlag
              }
            )
          })
          // 调用删除接口
          if (_data.length <= 0) {
            _this.$message.warning('当前没有任何行可保存')
            return false
          }
          _this.saveMaterialDetails(_data)
        }).catch(() => {
        })
      }
    },
    saveMaterialDetails(_data) {
      console.log(_data)
      const _this = this
      _this.materialListDataloading = true
      $orderReq.SaveOrderDetails({ OrderID: _this.OrderID, OrderDetailsContent: JSON.stringify(_data), OrderGuid: localStorage.getItem('OrderGuid') }).then((res) => {
        _this.materialListDataloading = false
        _this.SplitDetailsVisbel = false
        if (res.RetCode === '0') {
          localStorage.setItem('OrderGuid', res.OrderGuid)
          _this.getOrderMaterialList()
          _this.$message.success('保存成功')
        } else {
          _this.$message.error('保存物料明细出错：' + res.RetMsg)
        }
      })
      this.deletePartMateialitem = []
    },
    // 删除拆分明细
    deletePart(_row, _index) {
      const _this = this
      let count = 0
      _this.materialList.forEach(ele => {
        if (ele.IDX === _row.IDX) {
          count++
        }
      })
      if (count < 2) {
        _this.$message.error('当前物料已没有更多拆分行，不能进行删拆')
        return false
      }
      if (_row.PartID > 0) {
        _row.DelFlag = 1
        _this.deletePartMateialitem.push(_row)
      }
      _this.materialList.splice(_index, 1)
      console.log(_this.materialList)
      _this.dealcolspan(_this.materialList, _this)
    },
    // 拆分订单明细
    splitPartMaterial(_row, _index) {
      const _this = this
      let isgoing = true
      // splice(1,0,4)  1:要插入的索引 0 不删除元素 4 插入的元素
      _this.materialList.forEach((ele) => {
        if (_row.IDX === ele.IDX && ele.UploadFlag === -1) {
          if (ele.CurrentSendNum <= 0) {
            isgoing = false
            _this.$message.warning('请先处理未填写数量的拆分行再进行操作')
            return false
          }
        }
      })
      if (isgoing) {
        const _copyRow = {}
        // 复制当前行
        Object.assign(_copyRow, _row)
        _copyRow.LotNum = null
        _copyRow.LotNo = null
        _copyRow.MaterialStockSellID = 0
        _copyRow.CurrentSendNum = 0
        _copyRow.PartID = 0
        _copyRow.StockNumV2 = 0
        _copyRow.ExpiredDate = null
        _copyRow.ProductDate = null
        _copyRow.PartNum = 0
        _this.materialList.splice(_index + 1, 0, _copyRow)
        // _this.$set(_this.materialList[_index + 1], _index + 1, _copyRow)
        _this.dealcolspan(_this.materialList, _this)
      }
    },
    // 处理行合并
    dealrowspan({ row, column, rowIndex, columnIndex }) {
      // const _this = this
      console.log()
      // 定义合并列的索引
      // const spancloarr = [1, 2, 10, 11, 12, 15, 17, 18, 19, 20, 22]
      const spancloarr = [2, 3, 11, 12, 13, 16, 18, 19, 20, 21, 23]
      if (spancloarr.indexOf(columnIndex) > -1) {
        if (row.isberowspaned) {
          return {
            rowspan: 0,
            colspan: 0
          }
        } else {
          if (row.spancolnum > 1) {
            return {
              rowspan: row.spancolnum,
              colspan: 1
            }
          }
        }
      }
    },
    // 校验可填的交货单数量
    checkedNum(_row) {
      const _this = this
      if ($comjs.checkedNum(_row.CurrentSendNum) < 0) {
        _this.$message.warning('请输入数字')
        _row.CurrentSendNum = 0
        return false
      } else {
        if (_row.CurrentSendNum <= 0) {
          _this.$message.warning('请输入大于0 的数字')
          _row.CurrentSendNum = 0
          return false
        }
        if (_row.LotNum && _row.LotNum.length > 0) {
          // 校验输入的数据是否大于库存
          if (_row.StockNumV2 < _row.NumLeft) {
            if (_row.StockNumV2 < _row.CurrentSendNum) {
              _row.CurrentSendNum = _row.StockNumV2
              _this.$message.warning('请填写小于当前库存的数量')
              return false
            }
          } else {
            if (_row.NumLeft < _row.CurrentSendNum) {
              _row.CurrentSendNum = 0
              _this.$message.warning('请填写小于当前未发数据的数量')
              return false
            }
          }
        }
      }
    },
    // 筛选近几月销售量
    chagnexsl(val) {
      this.xslvalue = val
    },
    // 搜索物料
    searchMaterial() {
      const _this = this
      _this.materialListDataloading = true
      if (!this.KeyWords || this.KeyWords.length < 1) {
        _this.materialList = _this.materialListall
        _this.materialListDataloading = false
        return false
      }
      _this.materialList = []
      _this.materialListall.forEach((ele) => {
        if (ele.InvCode.indexOf(this.KeyWords) > -1 || ele.MaterialName.indexOf(this.KeyWords) > -1 || (ele.StandardNam && ele.StandardName.indexOf(this.KeyWords) > -1)) {
          _this.materialList.push(ele)
        }
      })
      _this.materialListDataloading = false
    },
    // 添加物料选中
    addmaterhandleSelectionChange(val) {
      this.checkedAddMaterial = val
    },
    // 确认为订单添加物料
    confirmAddMaterial() {
      const _this = this
      if (!_this.checkedAddMaterial || _this.checkedAddMaterial < 1) {
        _this.$message.warning('请选择至少一条物料')
        return false
      }
      let isgoing = true
      _this.checkedAddMaterial.forEach((ele) => {
        if (!ele.addnum || ele.addnum < 1) {
          isgoing = false
          _this.$message.info('请填写选中物料的数量')
          return false
        }
      })
      if (isgoing) {
        _this.$confirm('确认添加选中的物料吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          _this.AddMaterial(null)
        }).catch(() => {
        })
      }
    },
    // 确认为订单添加物料
    confirmAddoneMaterial(_row) {
      const _this = this
      if (!_row.addnum || _row.addnum < 1) {
        _this.$message.info('请填写要添加的数量')
        return false
      }
      _this.$confirm('确认添加选中的物料吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        _this.AddMaterial(_row)
      }).catch(() => {
      })
    },
    // 添加选中的物料
    AddMaterial(_row) {
      const _this = this
      // 处理参数
      // SaveAddOrderMaterial
      _this.materialListDataloading = true
      const _DataJson = []
      if (!_row) {
        _this.checkedAddMaterial.forEach((ele) => {
          _DataJson.push({
            IDX: ele.IDX,
            Num: ele.addnum
          })
        })
      } else {
        _DataJson.push(
          {
            IDX: _row.IDX,
            Num: _row.addnum
          }
        )
      }
      $orderReq.SaveAddOrderMaterial({
        OrderID: _this.OrderID,
        Remark: _this.addMaterialReason,
        DataJson: JSON.stringify(_DataJson),
        OrderGuid: localStorage.getItem('OrderGuid')
      }).then((res) => {
        _this.materialListDataloading = false
        console.log(res.Message)
        if (res.RetCode === '0') {
          _this.getOrderMaterialList()
          _this.$parent.$refs.orderHeader.getOrderHeader()
          _this.$message.success('操作成功')
          localStorage.setItem('OrderGuid', res.OrderGuid)
          _this.addMaterialVisbel = false
        } else {
          _this.$message.error('添加物料出错：' + res.RetMsg)
        }
      })
      this.updateNumVisbel = false
    },
    // 批量修改原数量confirmUpdateNum
    openUpdateNum() {
      const _this = this
      if (!_this.materialCheckedList || _this.materialCheckedList.length < 1) {
        _this.$message.warning('请选择你要操作的物料')
        return false
      }
      // 处理修改框数据
      _this.orderInfo = _this.$parent.$refs.orderHeader.$data.orderHeadInfo
      _this.orderUpdataNumList = []
      const _arr = []
      _this.materialCheckedList.forEach((ele) => {
        if (_arr.indexOf(ele.IDX) < 0) {
          _arr.push(ele.IDX)
          _this.orderUpdataNumList.push(
            {
              IDX: ele.IDX,
              InvCode: ele.InvCode,
              MaterialName: ele.MaterialName,
              Num: ele.Num,
              NumSend: ele.NumSend,
              newNum: 0,
              PartID: ele.PartID
            }
          )
        }
      })
      _this.updateNumReason = null
      // _this.orderUpdataNumList = _this.materialCheckedList
      _this.updateNumVisbel = true
    },
    // 单个修改原数量
    updateonenum(row) {
      const _this = this
      _this.orderInfo = _this.$parent.$refs.orderHeader.$data.orderHeadInfo
      _this.orderUpdataNumList = []
      _this.orderUpdataNumList.push(
        {
          IDX: row.IDX,
          InvCode: row.InvCode,
          MaterialName: row.MaterialName,
          Num: row.Num,
          NumSend: row.NumSend,
          newNum: 0,
          PartID: row.PartID
        }
      )
      _this.updateNumReason = null
      _this.updateNumVisbel = true
    },
    confirmUpdateNum() {
      const _this = this
      // 判断是否填写 备注
      if (!_this.updateNumReason || _this.updateNumReason.length < 1) {
        _this.$message.warning('请输入修改备注')
        return false
      }
      let isgoing = true
      // 校验输入的新数量不能小于已发数量
      _this.orderUpdataNumList.forEach((ele) => {
        if (ele.newNum < ele.NumSend) {
          _this.$message.warning('物料 ' + ele.InvCode + ' 的新数量不能小于已发数量')
          isgoing = false
          return false
        }
      })
      if (isgoing) {
        _this.$confirm('确认修改订单所填写的原数量?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          _this.UpdateNum()
        }).catch(() => {
        })
      }
    },
    UpdateNum() {
      const _this = this
      _this.buttonloading = true
      _this.materialListDataloading = true
      const _DataJson = []
      _this.orderUpdataNumList.forEach((ele) => {
        _DataJson.push({
          IDX: ele.PartID,
          NewNum: ele.newNum
        })
      })
      console.log(JSON.stringify(_DataJson))
      $orderReq.UpdateOrderDetailsNumBatch({
        OrderID: _this.OrderID,
        Remark: _this.updateNumReason,
        DataJson: JSON.stringify(_DataJson),
        OrderGuid: localStorage.getItem('OrderGuid')
      }).then((res) => {
        _this.buttonloading = false
        _this.materialListDataloading = false
        if (res.RetCode === '0') {
          _this.getOrderMaterialList()
          _this.$parent.$refs.orderHeader.getOrderHeader()
          _this.$message.success('操作成功')
          localStorage.setItem('OrderGuid', res.OrderGuid)
        } else {
          _this.$message.error('修改物料明细原数量出错：' + res.RetMsg)
        }
      })
      this.updateNumVisbel = false
    },
    // 验证删除物料明细
    confirmDeleteMaterial() {
      const _this = this
      if (!_this.materialCheckedarry || _this.materialCheckedarry.length < 1) {
        _this.$message.warning('请选择你要删除的物料')
        return false
      }
      let isgong = true
      _this.materialList.forEach(ele => {
        if (ele.PartID < 1) {
          isgong = false
          return false
        }
      })
      if (!isgong) {
        _this.$confirm('当前存在未保存的拆分数据，批量删除后会刷新页面，未保存的数据会清空，确认批量删除吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          _this.deleteaMaterialIdx = 0
          // 弹出备注框
          _this.deleteMaterilaReason = ''
          _this.deleteMaterilaReasonvisible = true
        }).catch(() => {
        })
      } else {
        _this.deleteaMaterialIdx = 0
        // 弹出备注框
        _this.deleteMaterilaReason = ''
        _this.deleteMaterilaReasonvisible = true
      }
    },
    validdeltematerial() {
      const _this = this
      _this.$confirm('确认删除选中的物料明细?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        _this.deleteMaterilaReasonvisible = false
        _this.DeleteMaterial()
      }).catch(() => {
      })
    },
    // 删除物料
    deleteMaterial(_idx) {
      const _this = this
      let isgong = true
      _this.materialList.forEach(ele => {
        if (ele.PartID < 1) {
          isgong = false
          return false
        }
      })
      if (!isgong) {
        _this.$confirm('当前存在未保存的拆分数据，清删除物料后会刷新页面，未保存的数据会清空，确认删除吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          // 弹出备注框
          _this.deleteaMaterialIdx = _idx
          _this.deleteMaterilaReason = ''
          _this.deleteMaterilaReasonvisible = true
        }).catch(() => {
        })
      } else {
        // 弹出备注框
        _this.deleteaMaterialIdx = _idx
        _this.deleteMaterilaReason = ''
        _this.deleteMaterilaReasonvisible = true
      }
    },
    // 删除物料明细
    DeleteMaterial() {
      // 调用删除物料明细接口
      const _this = this
      _this.materialListDataloading = true
      let _IdxList = ''
      if (!_this.deleteaMaterialIdx || _this.deleteaMaterialIdx < 1) {
        _IdxList = _this.materialCheckedarry.join(',')
      } else {
        _IdxList = _this.deleteaMaterialIdx
      }
      $orderReq.DeleteOrderDetails({ OrderID: _this.OrderID, IdxList: _IdxList, Reason: _this.deleteMaterilaReason, OrderGuid: localStorage.getItem('OrderGuid') }).then((res) => {
        _this.materialListDataloading = false
        console.log(res.Message)
        if (res.RetCode === '0') {
          _this.getOrderMaterialList()
          _this.$parent.$refs.orderHeader.getOrderHeader()
          _this.$message.success('操作成功')
          localStorage.setItem('OrderGuid', res.OrderGuid)
        } else {
          _this.$message.error('删除物料明细出错：' + res.RetMsg)
        }
      })
    },
    select(row) {
      console.log(row)
    },
    // 选中的物料明细
    materialListhandleSelectionChange(val) {
      this.materialCheckedList = val
      this.materialCheckedarry = []
      this.materialCheckedPartarry = []
      // materialCheckedarry
      if (val && val.length > 0) {
        val.forEach((ele) => {
          this.materialCheckedarry.push(ele.IDX)
          this.materialCheckedPartarry.push(ele.PartID)
        })
      }
    },
    // 确认分配批号
    confirmClearOrderLotNo() {
      const _this = this
      // 校验是否有数据没有保存
      let isgong = true
      _this.materialList.forEach(ele => {
        if (ele.PartID < 1) {
          isgong = false
          return false
        }
      })
      if (!isgong) {
        _this.$confirm('当前存在未保存的拆分数据，清空批号后会刷新页面，未保存的数据会清空，确认清空批号吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          _this.ClearOrderLotNo()
        }).catch(() => {
        })
      } else {
        _this.ClearOrderLotNo()
      }
    },
    // 一键清空批号
    ClearOrderLotNo() {
      const _this = this
      _this.buttonloading = true
      _this.materialListDataloading = true
      // 处理批量选择
      let _partIDs = ''
      if (_this.materialCheckedPartarry && _this.materialCheckedPartarry.length > 0) {
        _partIDs = _this.materialCheckedPartarry.join(',')
      }
      $orderReq.ClearOrderLotNo({ OrderID: _this.OrderID, OrderGuid: localStorage.getItem('OrderGuid'), partIDs: _partIDs }).then((res) => {
        _this.materialListDataloading = false
        _this.buttonloading = false
        console.log(res.Message)
        if (res.RetCode === '0') {
          _this.getOrderMaterialList()
          _this.$message.success('操作成功')
          localStorage.setItem('OrderGuid', res.OrderGuid)
        } else {
          _this.$message.error('一键清空批号出错：' + res.RetMsg)
        }
      })
    },
    // 分配批号
    confirmSaveDistributeStock() {
      const _this = this
      // 校验是否有数据没有保存
      let isgong = true
      _this.materialList.forEach(ele => {
        if (ele.PartID < 1) {
          isgong = false
          return false
        }
      })
      if (!isgong) {
        _this.$confirm('当前存在未保存的拆分数据，自动分配批号后会刷新页面，未保存的数据会清空，确认分配批号吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          _this.SaveDistributeStock()
        }).catch(() => {
        })
      } else {
        _this.SaveDistributeStock()
      }
    },
    // 分配批号
    SaveDistributeStock() {
      const _this = this
      _this.materialListDataloading = true
      _this.buttonloading = true
      $orderReq.SaveDistributeStock({ OrderID: _this.OrderID, OrderGuid: localStorage.getItem('OrderGuid') }).then((res) => {
        _this.materialListDataloading = false
        _this.buttonloading = false
        console.log(res.Message)
        if (res.RetCode === '0') {
          _this.getOrderMaterialList()
          _this.$message.success('操作成功')
          localStorage.setItem('OrderGuid', res.OrderGuid)
        } else {
          _this.$message.error('分配批号出错：' + res.RetMsg)
        }
      })
    },
    // 显示全部物料
    showall() {
      const _this = this
      _this.materialListDataloading = true
      _this.isshowall = !_this.isshowall
      // -1 可操作  2 隐藏 （点击显示全部 则显示
      if (_this.isshowall) {
        _this.showalltext = '隐藏已处理'
        _this.materialList = []
        _this.materialList = Object.assign(_this.materialList, _this.materialListcopyforhide)
        // 把隐藏的数据拼到对应的物料中
        // if (_this.hiderows && _this.hiderows.length > 0) {
        //   if (_this.materialList.length < 1) {
        //     _this.materialList = _this.hiderows
        //   } else {
        //     let isone = true
        //     _this.hiderows.forEach(ele => {
        //       isone = true
        //       for (let i = 0; i < _this.materialList.length; i++) {
        //         if (_this.materialList[i].IDX === ele.IDX) {
        //           _this.materialList.splice(i, 0, ele)
        //           isone = false
        //           return false
        //         }
        //       }
        //       if (isone) {
        //         _this.materialList.splice(_this.materialList.length - 1, 0, ele)
        //       }
        //     })
        //   }
        // }
      } else {
        _this.showalltext = '显示全部'
        // 把隐藏的数据都移除
        _this.dealhidedata(_this.materialList, _this)
      }
      // 调用处理数据的方法
      _this.dealcolspan(_this.materialList, _this)
      _this.materialListDataloading = false
    },
    // 默认选中未处理完的筛选条件
    setDefaultFilter(col, val) {
      const column = this.$refs.materialListref.columns[col]
      column.filteredValue = [val]
      this.$refs.materialListref.store.commit('filterChange', {
        column,
        values: column.filteredValue,
        silent: true
      })
    },
    // 明细是否可操作
    orderuploadFlagFilter(value, row) {
      if (value === 1) {
        return true
      } else if (value === 2) {
        return row.UploadFlag !== 2
      } else {
        return true
      }
    },
    checkboxInit(row, index) {
      if (row.UploadFlag !== -1) {
        return 0// 不可勾选
      } else {
        return 1// 可勾选
      }
    },
    // 获取订单物料明细
    getOrderMaterialList() {
      const _this = this
      _this.materialListDataloading = true
      $orderReq.GetOrderDetailsList({ OrderID: _this.OrderID }).then((res) => {
        _this.materialListDataloading = false
        console.log(res.Message)
        if (res.RetCode === '0') {
          _this.materialList = res.Message
          _this.materialListcopyforhide = Object.assign(_this.materialListcopyforhide, _this.materialList)
          _this.dealnumsend(_this.materialList, _this)
          _this.dealSum(res.Message, _this)
          if (_this.Ishandler === '999') {
            _this.dealhidedata(res.Message, _this)
          }
          _this.materialListall = res.Message
          _this.dealcolspan(_this.materialListall, _this)
          _this.dealnumsend(_this.materialListall, _this)
        } else {
          _this.$message.error('获取订单物料明细出错：' + res.RetMsg)
        }
      })
    },
    // 处理隐藏数据
    dealhidedata(_data, _this) {
      // 处理隐藏行
      _this.hiderows = []
      let _disableoption = true
      for (let i = 0; i < _data.length; i++) {
        if (_data[i].UploadFlag === 2) {
          _this.hiderows.push(_data[i]) // 插入隐藏的行 ，且把行移除
          _data.splice(i, 1)
          i = i - 1
        } else {
          if (_disableoption !== false) {
            _disableoption = false
          }
        }
      }
      _this.disableoption = _disableoption
      _this.dealcolspan(_data, _this)
    },
    // 处理发货数量
    dealnumsend(_data, _this) {
      const arr = []
      const arr_num = []
      const orderDetailsIDX = []
      _data.forEach((ele) => {
        // 处理数量
        if (ele.PartNum > 0) {
          ele.CurrentSendNum = ele.PartNum
        } else {
          ele.CurrentSendNum = 0
        }
        // 处理合约
        if (ele.UploadFlag === 2) {
          if (arr.indexOf(ele.IDX) < 0) {
            arr.push(ele.IDX)
          }
        }
        if (ele.UploadFlag === -1) {
          orderDetailsIDX.push(ele.IDX)
          if (arr_num.indexOf(ele.IDX) < 0) {
            arr_num.push(ele.IDX)
          }
        }
      })
      _data.forEach((ele) => {
        // 处理明细条数
        const arry = orderDetailsIDX.filter(ee => {
          return ee === ele.IDX
        })
        console.log(arry)
        if (arry.length === 1) {
          this.$set(ele, 'isDeleteRow', false)
        } else {
          this.$set(ele, 'isDeleteRow', true)
        }
        // 处理是否可用
        if (arr.indexOf(ele.IDX) > -1) {
          this.$set(ele, 'isdisable', true)
          // ele.isdisable = true
        }
        if (arr_num.indexOf(ele.IDX) > -1) {
          this.$set(ele, 'isdisable_num', false)
          // ele.isdisable_num = false
        } else {
          this.$set(ele, 'isdisable_num', true)
          // ele.isdisable_num = true
        }
      })
    },
    // 处理列合并
    dealcolspan(_data, _this) {
      // 定义分组idx 数组
      const _idxarr = []
      _data.forEach((ele) => {
        if (_idxarr.indexOf(ele.IDX) < 0) {
          ele.spancolnum = 0
          _idxarr.push(ele.IDX)
          _data.forEach((item) => {
            if (item.IDX === ele.IDX) {
              ele.spancolnum = ele.spancolnum + 1
            }
          })
          ele.isberowspaned = false
        } else {
          ele.isberowspaned = true
        }
      })
      console.log(_data)
    },
    // 处理金额统计
    dealSum(_data, _this) {
      let zj = 0 // 总计
      let ys = 0 // 应收
      let ss = 0 // 实收
      const _arr = []
      _data.forEach((ele, index) => {
        if (_arr.indexOf(ele.IDX) < 0) {
          _arr.push(ele.IDX)
          zj = zj + (ele.ContactPrice * ele.Num)
        }
      })
      ys = zj
      ss = zj
      _this.zj = zj
      _this.ys = ys
      _this.ss = ss
    },
    // 打开订单行物料备注修改框
    openaddOrderdetailRemarkVisibel(_row) {
      this.addMaterialDetailRemarkRow = _row
      this.addOrderdetailRemarkVisibel = true
    },
    // 确认添加备注
    confirmSaveOrderDetailsPartRemark() {
      const _this = this
      if (!_this.addMaterialDetailRemarkRow.Remark || _this.addMaterialDetailRemarkRow.length < 1) {
        _this.$message.warning('请填写备注信息')
        return false
      }
      _this.$confirm('确认添加备注信息吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        _this.SaveOrderDetailsPartRemark()
      }).catch(() => {
      })
    },
    // 保存拆分行备注
    SaveOrderDetailsPartRemark() {
      const _this = this
      _this.materialListDataloading = true
      $orderReq.SaveOrderDetailsPartRemark({ Remark: _this.addMaterialDetailRemarkRow.Remark, OrderDetailsPartID: _this.addMaterialDetailRemarkRow.PartID }).then((res) => {
        _this.materialListDataloading = false
        if (res.RetCode === '0') {
          _this.getOrderMaterialList()
          _this.$message.error('操作成功')
          _this.addOrderdetailRemarkVisibel = false
        } else {
          _this.$message.error('添加备注出错：' + res.RetMsg)
        }
      })
    },
    // 打开创建交货单
    openCretateDelivery() {
      this.createDeliveryVisbel = true
    },
    // 新增商品 begin =================================begin ======================================
    // 打开物料选择框
    confirmopenaddmateri() {
      const _this = this
      // 校验是否有数据没有保存
      let isgong = true
      _this.materialList.forEach(ele => {
        if (ele.PartID < 1) {
          isgong = false
          return false
        }
      })
      if (!isgong) {
        _this.$confirm('当前存在未保存的拆分数据，选中物料添加后会刷新页面，未保存的数据会清空，确认要新增吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          _this.openAddMateiral()
        }).catch(() => {
        })
      } else {
        _this.openAddMateiral()
      }
    },
    openAddMateiral() {
      // 获取物=物料列表
      this.checkedAddMaterial = []
      this.getAddMaterialMaterlaiList()
      this.addMaterialVisbel = true
    },
    addMateriallistCurrentChange(val) {
      this.listaddmaterialParas.PageIndex = val
      this.getAddMaterialMaterlaiList()
    },
    // 获取添加物料时，物料的列表
    getAddMaterialMaterlaiList() {
      const _this = this
      _this.addMaterialloading = true
      // _this.listaddmaterialParas.SellCompanyID = _this.contractInfo.SellCompanyID
      _this.listaddmaterialParas.OrderID = _this.OrderID
      $orderReq.GetAddOrderMaterialList(_this.listaddmaterialParas).then(res => {
        _this.addMaterialloading = false
        if (res.RetCode === '0') {
          _this.addMaterialList = res.Message
          _this.addMateriallistSize = res.Total
          _this.dealaddMaterial(_this)
        }
      })
    },
    // 处理可添加的物料
    dealaddMaterial(_this) {
      _this.addMaterialList.forEach((ele) => {
        ele.ischecked = false
        _this.materialList.forEach((item) => {
          if (item.MaterialRefCompanyID === ele.IDX) {
            ele.ischecked = true
            return false
          }
        })
      })
    },
    addmaterilselectable(row, index) {
      if (row.ischecked) {
        return 0 // 不可勾选
      } else {
        return 1 // 可勾选
      }
    },
    // 新增商品 end =================================end ======================================
    // 确认修改合约
    confirmupdatecontract(_row) {
      const _this = this
      _this.$confirm('确认修改该物料行的合约吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        _this.updatecontract(_row)
      }).catch(() => {
      })
    },
    // 重设合约
    updatecontract(_row) {
      const _this = this
      _this.contractListloading = true
      $orderReq.SaveOrderDetailsContractUpdate({ OrderID: _this.OrderID, OrderDetailsID: _this.materialCurrentRow.row.IDX, ContractDetailsID: _row.IDX, OrderGuid: localStorage.getItem('OrderGuid') }).then(res => {
        _this.contractListloading = false
        if (res.RetCode === '0') {
          _this.getOrderMaterialList()
          _this.$message.success('操作成功')
          localStorage.setItem('OrderGuid', res.OrderGuid)
          this.updatecontractVisbel = false
        } else {
          _this.$message.error('操作失败：' + res.RetMsg)
        }
      })
    },
    // 打开修改合约
    openupdatecontract(_row) {
      this.materialCurrentRow.row = _row
      this.updatecontractVisbel = true
      this.getContractList(_row)
    },
    // 获取合约列表
    getContractList(_row) {
      const _this = this
      _this.contractListloading = true
      _this.contractList = []
      $orderReq.GetOrderDetailsContractList({ OrderID: _this.OrderID, InvCode: _row.InvCode }).then(res => {
        _this.contractListloading = false
        if (res.RetCode === '0') {
          _this.contractList = res.Message
        }
      })
    },
    // 确认修改库存
    confirmUseStock(_row) {
      const _this = this
      _this.$confirm('确认选择库存吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        _this.UseStock(_row)
      }).catch(() => {
      })
    },
    UseStock(_row) {
      const _this = this
      _this.stockListloading = true
      $orderReq.SaveOrderDetailsPartSelectStock({ OrderID: _this.OrderID, StockID: _row.StockID, OrderDetailsPartID: _this.materialCurrentRow.row.PartID }).then(res => {
        _this.stockListloading = false
        if (res.RetCode === '0') {
          _this.getOrderMaterialList()
          _this.$message.success('操作成功')
          _this.updatestockVisbel = false
        } else {
          _this.$message.error('选择库存失败：' + res.RetMsg)
        }
      })
    },
    dealPartInfoAfterStock(_this, _row) {
      _this.materialCurrentRow.row.ExpiredDate = _row.ExpiredDate
      _this.materialCurrentRow.row.StockCode = _row.StockCode
      _this.materialCurrentRow.row.LotNum = _row.LotNum
      _this.materialCurrentRow.row.LotNo = _row.LotNo
      _this.materialCurrentRow.row.MaterialStockSellID = _row.StockID
      _this.materialCurrentRow.row.StockNumV2 = _row.StockNum
      if (_this.materialCurrentRow.row.CurrentSendNum > _row.StockNum) {
        _this.materialCurrentRow.row.CurrentSendNum = _row.StockNum
      }
      _this.$message.success('操作成功')
      _this.stockListloading = false
      _this.updatestockVisbel = false
    },
    // confirmUseStock(_row) {
    //   // 处理库存信息 以及拆分
    //   const _this = this
    //   _this.materialCurrentRow.row.LotNum = _row.LotNum
    //   if (_row.StockNum >= _this.materialCurrentRow.row.NumLeft) {
    //     _this.materialCurrentRow.row.PartNum = _this.materialCurrentRow.row.NumLeft
    //   } else {
    //     _this.materialCurrentRow.row.PartNum = _row.StockNum
    //     // var aa =[1,2,3]
    //     // splice(1,0,4)  1:要插入的索引 0 不删除元素 4 插入的元素
    //     // 插入一行，并将数据进行合并拆分 _this.materialCurrentRow.row
    //     const _copyRow = {}
    //     // 复制当前行
    //     Object.assign(_copyRow, _this.materialCurrentRow.row)
    //     console.log(_copyRow)
    //     _copyRow.lotnum = null
    //     _copyRow.sendnum = 0
    //     // 处理合并
    //     _this.materialCurrentRow.row.isrowspan = true // 合并
    //     _copyRow.isberowspaned = true // 被合并
    //     _this.materialList.splice(_this.materialCurrentRow.index + 1, 0, _copyRow)
    //     // 对当前行以及新复制的行进行行合并
    //   }
    //   // _this._dealrowindex = _this.materialCurrentRow.index
    //   // _this._dealrownextindex = _this.materialCurrentRow.index + 1
    //   _this.updatestockVisbel = false
    // },
    // 打开库存框
    openupdatestock(_index, _materialRow) {
      if (!_materialRow.PartID || _materialRow.PartID <= 0) {
        this.$message.error('当前行数据未保存，无法选择库存！')
        return false
      }
      this.materialCurrentRow.index = _index
      this.materialCurrentRow.row = _materialRow
      this.getStockBymateralrefid()
      this.updatestockVisbel = true
      // 获取库存
    },
    getStockBymateralrefid() {
      const _this = this
      _this.stockListloading = true
      _this.stockList = []
      $orderReq.GetMaterialStockList({ MaterialRefCompanyID: _this.materialCurrentRow.row.MaterialRefCompanyID }).then(res => {
        _this.stockListloading = false
        if (res.RetCode === '0') {
          _this.stockList = res.Message
        } else {
          _this.updatestockVisbel = false
          _this.$message.error('出现错误：' + res.RetMsg)
        }
      })
    },
    // 处理行合并
    dealrowspandetails({ row, column, rowIndex, columnIndex }) {
      // 定义合并列的索引
      const spancloarr = [1, 2, 3, 4, 5, 6, 10, 11, 13, 14]
      if (spancloarr.indexOf(columnIndex) > -1) {
        if (row.isberowspaned) {
          return {
            rowspan: 0,
            colspan: 0
          }
        } else {
          if (row.spancolnum > 1) {
            return {
              rowspan: row.spancolnum,
              colspan: 1
            }
          }
        }
      }
    },
    // 添加/取消我的订单
    addFavorate() {
      this.orderHeadInfo.isFavorate = !this.orderHeadInfo.isFavorate
    },
    // 操作记录
    openoptionLog() {
      const _this = this
      // GetOperationRecordList
      _this.logListloading = true
      $orderReq.GetOperationRecordList({
        OrderID: _this.OrderID,
        pageIndex: 1,
        pageSize: -1
      }).then(res => {
        _this.logListloading = false
        if (res.RetCode === '0') {
          _this.optionLogList = res.Message
        } else {
          _this.$message.error('获取操作日志出错：' + res.RetMsg)
        }
      })
      _this.logListVisbel = true
    }
  }
  // filters: {
  //   FormDate: (value, _this) => {
  //     console.log(_this)
  //     if (value && value.length > 0) {
  //       return _this.$minCommon.formatDate(new Date(value), 'yyyy-MM-dd')
  //     } else {
  //       return ''
  //     }
  //   }
  // }
}
export default orderdetailMaterialList
